import React from 'react';
import paletas from './../../consts/paletas'

export default ({agenda }) => {
    return <div
        style={{ fontSize: '10px' }}
        className={`rounded p-1 w-12 font-mono font-semibold text-black bg-${paletas.turnos[agenda.agenda_turnoid].color}-500 shadow hover:bg-${paletas.turnos[agenda.agenda_turnoid].color}-400`}>
        #{agenda.agenda_nro}
        {/* {agenda.agenda_tecnicoid && <div><i className='fa fa-user-cog mr-1'></i> {agenda.agenda_tecnicoid}</div>} */}
    </div>
};
