import React, {useState} from 'react';
import nivelRoles from '../../consts/nivelRoles'

const FormSelect = ({roles,includeBlank=false, title ,_onChange, items, item_key='key', item_value='value', item_label='title' }) => {

    return (
        <select className="w-full h-10 mx-1  my-2" onChange={_onChange}>
            {title && <option value="">{title}</option>}
            { includeBlank && <option>Seleccione una opcion</option>}
            {items
                .filter(item => {
                    return roles && item.level && nivelRoles[item.level]
                        .some(r => roles.indexOf(r) >= 0)
                })
                .map(item => (
                        <option key={item[item_key]} value={item.id}>
                            {item[item_label]}
                        </option>
                    ))
            }
        </select>
    )
}

export default FormSelect


