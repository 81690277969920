import { useEffect, useState } from 'react'
import useFetch from './useFetch'

const useRole = (user, empresa) => {
  const [mode, setMode] = useState([])
  const [roles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${empresa}/prod/cuo/roles`)

  useEffect(() => {
    if (user && user.memberof && roles) {
      let memberof = Object.values(user.memberof)
        .filter(group => typeof group !== 'number')
        .map(group => group.split(',')[0].replace('CN=', ''))

      memberof.map(grupo => {
        roles.map(role => {
          role.grupo === grupo && setMode(mode => [...mode, (role.rol)])
        })
      })
    }
  }, [roles])

  return [...new Set(mode)]
}

export default useRole
