import React from 'react';

const FloatingWindowTabsHeader = ({ tabs, activeTab, setActiveTab }) => (
    <div className="flex items-center h-10 items-center capitalize text-center">
        <i className={`fa fa-search fa-fw ml-3 text-${process.env.REACT_APP_COLOR}-500` } />
        {tabs.map((tab, i) =>
            <div
                key={i}
                onClick={() => setActiveTab(i)}
                className={`${i === activeTab ? 'opacity-100 font-bold ' : ''} px-3   opacity-50
                flex items-center  inline-block  cursor-pointer  hover:opacity-100  capitalize`}
                >
                {tab}
            </div>)}
    </div>
)

export default FloatingWindowTabsHeader