export default {
    0: {
        color: 'indigo',
        width: 'w-2',
        icon: 'play',
        label: 'Obtener Orden',
        legend: 'Comience sus operaciones haciendo clic en el siguiente boton',
        legendOperator: 'Libre'
    },
    1: {
        color: 'green',
        width: 'w-1/4',
        icon: 'thumbs-up',
        label: 'Aceptar',
        legend: 'Acepte la agenda para obtener la ubicación.',
        legendOperator: 'Por Aceptar la agenda'
    },
    2: {
        color: 'yellow',
        width: 'w-2/4',
        icon: 'door-open',
        label: 'Comenzar',
        legend: 'Una vez en el domicilio presione el boton de Comenzar para visualizar las ordenes',
        legendOperator: 'Por comenzar'
    },
    3: {
        color: 'purple',
        width: 'w-3/4',
        icon: 'share',
        label: 'Aprovisionar',
        legend: 'Presione Aprovisionar para enviarle al CRM las indicaciones',
        legendOperator: 'Trabajando'
    },
    4: {
        color: 'red',
        width: 'w-full',
        icon: 'hand-shake',
        label: 'Cerrar & Siguiente',
        legend: 'Cierre la agenda y aguarde la proxima asignada',
        legendOperator: 'Cerrando y firmando'
    },
    5: {
        color: 'teal',
        width: 'w-4',
        icon: 'sync',
        label: 'Buscando...',
        legend: 'Buscando la siguiente agenda',
        legendOperator: 'Buscando la siguiente agenda'
    }
}
