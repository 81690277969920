import { useEffect, useState } from 'react';

const useLocation = () => {
    useEffect(() => {
        console.log('mounted')
        return () => {
          console.log('unmounted')
        };
    }, [])
    return {lat:-53.7935161, lng:-67.6941226}
};

export default useLocation