import React, { useState } from 'react';

import Padding from '../Layout/Padding';
import RankingDiario from './RankingDiario'
import RankingMensual from './RankingMensual'

const RankingIndex = () => {
    let [tipo,setTipo] = useState(2)
    return <Padding>
            <div className='py-3 grid grid-cols-4  gap-2 '>
                <button onClick={()=>setTipo(0)} ><i className='fa fa-calendar-day mr-1' />Diario</button>
                <button onClick={()=>setTipo(1)} ><i className='fa fa-calendar-week mr-1' />Semanal</button>
                <button onClick={()=>setTipo(2)} ><i className='fa fa-calendar-alt mr-1' />Mensual</button>
                <button onClick={()=>setTipo(3)} ><i className='fa fa-calendar mr-1' />Anual</button>
            </div>
            <div>
                {tipo ==0 && <RankingDiario />}
                {tipo ==2 && <RankingMensual />}
            </div>
        </Padding>
}

export default RankingIndex