import React, { useEffect, useState } from 'react'

import EntityBadge from '../../components/Badges/EntityBadge'
import Padding from '../../components/Layout/Padding'
import moment from 'moment'
import { useHistory } from 'react-router'
import PbxAgentes from './PbxAgentes'
const PbxScreen = (params) => {
    const history = useHistory()
	const [data, setData] = useState()
	useEffect(() => {
		fetchData()
		const PbxColaInterval = setInterval(() => {fetchData()}, 20000)
		return () => clearInterval(PbxColaInterval)
	}, [])

	const AGENTES = {
		900: 'Jorge Sebastian Cuellar',
		901: 'Cirulli Ayelen Damaris',
		902: 'Silvana Gisela Gambini',
		903: 'Cristian Mansilla',
		904: 'Leandro Ezequiel Martinovic',
		905: 'Natalia Romina Ibanez',
		906: 'Walter Oscar Bazan',
		907: 'Pablo Ricardo Clemens',
		908: 'Matias Adrian Monaco',
		909: 'Maria Florencia Sanchez',
		910: 'Cristian Veron',
		911: 'Sebastian Morel',
		912: 'Jorge Puglia',
		930: 'Diego Paz',
		931: 'Patricio Acuña',
		1000: 'Indicom',
		1206: 'Gaston Zaja',
	}

	const COLAS = {
		601: 'TVF Internet',
		602: 'TVF Cable',
		603: 'TVF Comercial',
		604: 'TVF Ventas',
		611: 'UV Internet',
		612: 'UV Cable',
		613: 'UV Comercial',
		614: 'UV Ventas',
		640: 'SALIENTES',
		650: 'PHONO-TEC TVF',
		651: 'PHONO-TEC UV',
		655: 'CORPO TVF',
		656: 'CORPO UV',
		6601: 'TVF Internet Indicom',
	}

	const fetchData = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/pbx/realtime`, {
				headers: { 'authorization': process.env.REACT_APP_BRIDGE_API_KEY }
			})
			const responseJSON = await response.json()
			setData(responseJSON)
		} catch (e) { console.log(e) }
	}
	const handleOpenCola = (cola) => {
		history.push(`/pbx/cola/${cola}`)
	}
	return <Padding>
		<h1>Central de llamadas</h1>
		<PbxAgentes />

		{data && <table className='w-full'>
			<thead>
				<tr>
					<th>Cola</th>
					<th></th>
					<th></th>
					<th className='w-20 hidden md:table-cell'>% de Atencion</th>
					<th className='w-20 hidden md:table-cell'>AHT</th>
					<th className='w-20 hidden md:table-cell'>Espera en Cola</th>
					<th className='w-20 hidden lg:table-cell'> Max. wait time</th>
				</tr>
			</thead>
			<tbody>
				{Object.entries(data.summary.queue)
					.filter(([agent])=> !['6601','611589333521','611606333037'].includes(agent))
					.map(([agent, values]) =>
						<tr className='cursor-pointer' onClick={()=>{ handleOpenCola(COLAS[agent]) }}>
							<td >
							<span className='hidden md:inline'>
									<EntityBadge  name={COLAS[agent]} detail={agent} type='agente' />
								</span>
								<span className='inline md:hidden text-xs'>
									<strong>{COLAS[agent]}</strong><br/>{agent}
								</span>
							</td>
							<td>
								<div className='flex items-stretch justify-center text-center flex-col lg:flex-row px-2 '>
									<div className={`flex rounded  flex-col flex-1 items-center justify-between ${values['Waiting'] >= 5 && 'bg-red-600 text-white '}`}>
										<span className={`text-xl font-bold ${values['Waiting'] >= 5 ? ' text-white ' : 'text-blue-500'}`}>
											<i className={`fa fa-user-clock ${values['Waiting'] >= 5 ? ' text-white ' : 'text-blue-500'} opacity-50 mr-1`} />
											{values['Waiting']}
											</span>
										<span className='text-xxs opacity-75 hidden lg:inline'>En Espera</span>
									</div>
									<div className='flex flex-col flex-1 items-center justify-between'>
										<span className='text-xl font-bold text-green-500'><i className='fa fa-phone text-green-500 opacity-50 mr-1' />{values['Answered']}</span>
										<span className='text-xxs opacity-75 hidden lg:inline'>Contestadas</span>
									</div>
									<div className='flex flex-col flex-1 items-center justify-between'>
										<span className='text-xl font-bold text-red-500'><i className='fa fa-phone-slash text-red-500 opacity-50 mr-1' />{values['Abandoned']}</span>
										<span className='text-xxs opacity-75 hidden lg:inline'>No Contestadas</span>
									</div>
								</div>
							</td>
							<td>
								{Array.from({ length: Number(values['Agents']) - (Number(values['Paused'])  + Number(values['Busy'])) }).fill(null).map((a, index) => <i className={`fa fa-user  text-green-500 mr-2 `} key={index} />)}
								{Array.from({ length: values['Paused'] }).fill(null).map((a, index) => <i className={`fa fa-user  text-yellow-500 mr-2 `} key={index} />)}
								{/* {Array.from({ length: values['Waiting'] }).fill(null).map((a, index) => <i className={`fa fa-user  text-blue-500 mr-2 `} key={index} />)} */}
								{Array.from({ length: values['Busy'] }).fill(null).map((a, index) => <i className={`fa fa-user  text-red-500 mr-2 `} key={index} />)}
								<div className='hidden lg:flex  flex-row opacity-50' style={{ fontSize: '.6rem' }}>
									<span className='mr-2'><strong>{values['Agents']}</strong> Agentes</span>
									<span className='mr-2'>Ocupados: <strong>{values['Busy']}</strong></span>
									<span className='mr-2'>En Pausa: <strong>{values['Paused']}</strong></span>
									{/* <span>En Espera: <strong>{values['Waiting']}</strong></span> */}
								</div>
							</td>
							<td className='hidden md:table-cell'>
								<div className={`overflow-hidden h-2 my-1 text-xs flex rounded-full bg-${values['Abandon Rate'] > 70 ? 'red' : values['Abandon Rate']< 50 ? 'green' : 'yellow'}-200`}>
									<div style={{ width: 100-(values['Abandon Rate'] * 100 / 100) + '%' }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${values['Abandon Rate'] > 70 ? 'red' : values['Abandon Rate']< 50 ? 'green' : 'yellow'}-500`}></div>
								</div>

								<p className='text-sm text-center opacity-50'>{100-Math.floor(values['Abandon Rate'])}%</p>
							</td>
							<td className='hidden md:table-cell'>
								<ProgressBar total={500} value={values['Avg Duration']} />
								<p className='text-sm text-center opacity-50'><i className='fa fa-clock opacity-75 text-xs mr-1' />{moment("2015-01-01").startOf('day').seconds(values['Avg Duration']).format('mm:ss')}</p>
							</td>
							<td className='hidden md:table-cell'>
								<ProgressBar total={30} value={values['Avg Wait']} warning={20} />
								<p className='text-sm text-center opacity-50'><i className='fa fa-clock opacity-75 text-xs mr-1' />{moment("2015-01-01").startOf('day').seconds(values['Avg Wait']).format('mm:ss')}</p>
							</td>
							<td className='hidden lg:table-cell'>
								<ProgressBar total={100} value={values['Max. wait time']} />
								<p className='text-sm text-center opacity-50'><i className='fa fa-clock opacity-75 text-xs mr-1' />{values['Max. wait time']}s</p>
							</td>


						</tr>
					)
				}
			</tbody>
		</table>}
	</Padding>
}

const ProgressBar = ({ total, value, warning = total, min, color = 'gray' }) => {
	return <div className={`overflow-hidden h-2 my-1 text-xs flex rounded-full bg-${value > warning ? 'red' : color}-200`}>
		<div style={{ width: (value * 100 / total) + '%' }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${value > warning ? 'red' : color}-500`}></div>
	</div>
}

export default PbxScreen