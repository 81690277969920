import React, { useState } from 'react'
import useFetchLogs from '../../hooks/useFetchLogs'
import LogListItem from './LogListItem'
import Padding from '../Layout/Padding'
import useExportLogs from '../../hooks/useExportLogs'

const TYPES = {
    'DELETE': { color: 'pink', icon: 'trash' },
    'STORE': { color: 'teal', icon: 'plus' },
    'UPDATE': { color: 'orange', icon: 'edit' },
    'ERROR': { color: 'red', icon: 'bug' },
    'IMPORT': { color: 'purple', icon: 'download' },
}

const LogsIndex = () => {
    const {downloadData} = useExportLogs()

    const [tipo, setTipo] = useState('')
    const [query, setQuery] = useState('')
    const { listLogs, getListLogs, setDate, date } = useFetchLogs()
    const handleTipo = e => setTipo(e.target.value)

    const handleChangeQuery = e => {
        setQuery(e.target.value)
    }

    const handleClickExport = () => {
        downloadData(date,query,'laravel','Logs')
    }

    return (
        <Padding>
            <div className='flex items-center justify-between'>
                <h1>Logs</h1>
                <input onChange={handleChangeQuery} placeholder='Buscar ...'/>
                {date && <button onClick={handleClickExport}><i className='fa fa-file-csv'/> Exportar</button>}
            </div>
            <div className='grid grid-cols-2 gap-3'>
                <input type='date' value={date} onChange={e => setDate(e.target.value)} />
                {/* <button className='w-1/3 mx-2' disabled={!date} onClick={() => getListLogs()}><i className='fa fa-search' /></button> */}
                <select onChange={handleTipo} >
                    <option value=''>TODOS</option>
                    { Object.keys(TYPES).map( type => <option value={type}>{type}</option> )}
                  
                </select>
            </div>
            <table className='mt-4 w-full text-xs'>
                <tbody>

                    {listLogs && listLogs.filter(log => log.includes(tipo) &&  log.includes(query)).reverse().map((log,index) => <LogListItem key={index} log={log} TYPES={TYPES} />)}
                </tbody>
            </table>
        </Padding>
    )
}


export default LogsIndex
