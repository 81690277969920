import React, { useContext, useEffect, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Icons from '../Global/IconList'
import Padding from '../Layout/Padding'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import {useHistory} from 'react-router-dom'

const EnlacesConfiguracion = (props) => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/enlaces`)
    
    const [ deleteItem ] = useDelete('enlaces', '/configuracion/enlaces')
    const [openForm, setOpenForm] = useState(false)
    const [modeUpdate, setModeUpdate] = useState(false)
    const [updateId, setUpdateId] = useState()
    const [form, setForm] = useState()
    
    const sector = ['Operaciones' , 'Comercial', 'Ventas', 'Tecnica', 'Recursos Humanos', 'CRM', 'IT'];


    const handleNueva = () => {
        setModeUpdate(false)
        setOpenForm(!openForm)
        setForm({ ...form, icon:'address-book', sector:'Operaciones', mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0], _method:'post'})
    }
    const handleChangeSector = (e) => setForm({ ...form, sector: e.target.value })
    const handleChangeTitulo = (e) => setForm({ ...form, titulo: e.target.value })
    const handleChangeDescripcion = (e) => setForm({ ...form, descripcion: e.target.value })
    const handleChangeLink = (e) => setForm({ ...form, link: e.target.value })
    const handleChangeCategoria = (e) => setForm({ ...form, categoria: e.target.value })
    const handleChangeIcon = (icon) => setForm({ ...form, icon: icon})
    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/enlaces`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleUpdate = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/enlaces/${updateId}`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleClickEditar = (id,titulo,link,descripcion,sector,categoria,icon) => {
        setOpenForm(!openForm) 
        setModeUpdate(true)
        setUpdateId(id)
        setForm({...form, _method:'put', titulo:titulo, link:link, descripcion:descripcion, sector:sector, categoria:categoria, icon: icon, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0],})
    }

    const handleClickVolver = () => {
        history.goBack()
    }
    
    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Enlaces</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        {openForm &&
                            <tr>
                                <td className='py-3'><select onChange={handleChangeSector} defaultValue={modeUpdate === true ? form.sector : ''}>{sector.map((s,index) => <option key={index} value={s}>{s}</option>)}</select></td>
                                <td className='py-3'><input onChange={handleChangeTitulo} defaultValue={modeUpdate === true ? form.titulo : ''} placeholder='Titulo' /></td>
                                <td className='py-3'> <input onChange={handleChangeDescripcion} defaultValue={modeUpdate === true ? form.descripcion : ''} placeholder='Descripcion' /> </td>
                                <td className='py-3'> <input onChange={handleChangeLink} defaultValue={modeUpdate === true ? form.link : ''} placeholder='Url' /> </td>
                                <td className='py-3'> <input type='number' onChange={handleChangeCategoria} defaultValue={modeUpdate === true ? form.categoria : ''} placeholder='Categoria' /> </td>
                                
                                <td className='text-right'> <button onClick={modeUpdate === true ? handleUpdate : handleStore}><i className={`fa fa-${modeUpdate === true ? 'save' : 'paper-plane'} mr-2 `} /> {modeUpdate === true ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        {openForm &&
                            <tr>
                                <td classname='py-3 flex flex-wrap items-center inline-block m-1'>
                                    <details>
                                        <summary className='cursor-pointer'>Seleccione un icono <i className='fa fa-arrow-down'/></summary>
                                        {Icons
                                            .map( (icon,index) => <i key={index} onClick={() => handleChangeIcon(icon)} className={`fa cursor-pointer w-10 text-center fa-${icon} p-1 border border-transparent rounded m-1 text-gray-400 hover:text-green-500 
                                            ${form && icon == form.icon && 'bg-gray-100 border border-gray-200 text-green-500'}`}></i>) } 
                                    </details>
                                </td>
                            </tr>
                        }
                        
                        {data.map(item => <tr key={item.id}>
                            <td><i className={item.icon && `fa fa-${item.icon}`} /></td>
                            <td className='text-lg text-gray-600'>{item.titulo && item.titulo}</td>
                            <td className='text-sm text-blue-600'>{item.link && item.link.slice(0,15)}</td>
                            <td className='text-xs text-gray-600'>{item.descripcion && item.descripcion}</td>
                            <td className='text-right text-xs text-gray-600'>{item.sector && `sector: ${item.sector}`}</td>
                            <td className='text-right text-xs text-gray-600'>{item.categoria && `categoria: ${item.categoria}`}</td>
                            <td className='text-right'>
                                <button onClick={() => handleClickEditar(item.id,item.titulo,item.link,item.descripcion,item.sector,item.categoria,item.icon)} > <i className='fa fa-edit'/> Editar</button>
                                <button onClick={() => deleteItem(refreshData, item.titulo ,item.id)}> <i className='fa fa-trash'/> Eliminar</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default EnlacesConfiguracion
