import React, { useState, useEffect } from 'react';

const useHasStorage = () => {
    let [checkIfHasStorage, setCheckIfHasStorage] = useState(false)
    let [movil, setMovil] = useState(null)
    let [tecnico1, setTecnico1] = useState(null)
    let [tecnico2, setTecnico2] = useState(null)

    useEffect(() => {
        setMovil(parseInt(localStorage.getItem('movil')))
        setTecnico1(parseInt(localStorage.getItem('tecnico1')))
        setTecnico2(parseInt(localStorage.getItem('tecnico2')))
        if (movil && tecnico1 && tecnico2) {
            setCheckIfHasStorage(true)
        } else setCheckIfHasStorage(false)
    }, [movil, tecnico1, tecnico2])

    return { checkIfHasStorage, movil, tecnico1, tecnico2 }
};

export default useHasStorage