import React from 'react'
import paletas from '../../consts/paletas'

export default function TurnoBadge({turno}) {
    return (
        <div className={`flex flex-col items-center justify-center text-${paletas.turnos[turno].color}-500`}>
            <i className={`fa fa-${paletas.turnos[turno].icon} text-sm`} />
            <span className='text-xxs'>{paletas.turnos[turno].label}</span>
        </div>
    )
}
