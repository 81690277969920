import React from 'react';

import stepsConsts from './../../consts/steps'

const AgendaProgress = ({step, legend=false}) => {
    return <div className="flex-1">
                <div className="h-4  rounded-full m-1 flex-1 flex items-center px-1">
                    <div className={`h-2 transition-all duration-500 ease-in-out bg-${stepsConsts[step].color}-500 rounded-full ${stepsConsts[step].width}`} ></div>
                </div>
                {legend && <div className={`text-xxs ml-2 text-${stepsConsts[step].color}-600`}>{ stepsConsts[step].legendOperator }</div> }
            </div>
};

export default AgendaProgress
