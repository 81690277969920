import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment'
import MainMap from '../MainMap'
import ConfigContext from '../../contexts/ConfigContext';
import FloatingWindow from '../Global/FloatingWindow';
import paletas from '../../consts/paletas';
import OrdenesMarker from '../Markers/OrdenMarker';
import OrdenesFilters from '../Ordenes/OrdenesFilters';
import ClienteMarker from '../Markers/ClienteMarker';
import ClienteDetails from '../Clientes/ClienteDetails';
import NodoMarker from '../Markers/NodoMarker';
import MovilMarker from '../Markers/MovilMarker';
import BasicMarker from '../Markers/BasicMarker';
import CircleMarker from '../Markers/CircleMarker';


const MapScreen = () => {
    let context = useContext(ConfigContext)

    let [filteredOrdenes, setFilteredOrdenes] = useState([])
    let [date, setDate] = useState()
    let [ordenes, setOrdenes] = useState([])
    let [tipos, setTipos] = useState([])
    let [estados, setEstados] = useState([])
    let [clienteNro, setClienteNro] = useState('')
    let [cliente, setCliente] = useState(null)

    useEffect(() => {
        let filtered = ordenes.filter(orden => tipos.includes(orden.ordentpo) && estados.includes(orden.ordensts) )
        setFilteredOrdenes(filtered)
    }, [ordenes, tipos, estados])

    useEffect(() => {
        let filtered = ordenes.filter(orden => tipos.includes(orden.ordentpo) && estados.includes(orden.ordensts) )
        setFilteredOrdenes(filtered)
    }, [ordenes, tipos, estados])

    
    useEffect(() => { date && fetchMarkersByDate() }, [date])

    const fetchCliente = async () => {
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/apicuo/clientes/${clienteNro}`,
                                    {headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
        const responseJSON = await response.json()
        setCliente(responseJSON[0])
    }

     const fetchMarkersByDate = async () => {
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/ordenes/fecha/${date}`,
                                    {headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
        const responseJSON = await response.json()
        setOrdenes(responseJSON.ordenes)
    }
    return (
        <div>
            <MainMap 
                heatmap={false} 
                searchDirection={true} 
                right={cliente && <ClienteDetails cliente={cliente} />}
                recenter={cliente && { lat: cliente.lat, lng: cliente.lng }}

                bottom={ <OrdenesFilters setTipos={setTipos} setEstados={setEstados} /> } 
                left={<>
                <FloatingWindow  icon='search' title='Ordenes por fecha' >
                     <input type="date" value={date} onChange={e => setDate(e.target.value)} /> 
                    </FloatingWindow>
                    <FloatingWindow  icon='search' title='Cliente'> 
                    <input  type="text" defaultValue={clienteNro} 
                        placeholder='Buscar...'
                        onChange={e => setClienteNro(e.target.value)} 
                        onKeyPress={e => { (e.which == 13 || e.keyCode == 13 || e.key === 'Enter')  && fetchCliente()  }} 
                    /> 
                    </FloatingWindow>
                </>
                    }
            >
            {cliente  && <ClienteMarker lat={cliente.lat} lng={cliente.lng} text={cliente.nombre} size="6" />}

                {/* <ClienteMarker lat={-53.7701740} lng={-67.7114210} size="6"/>
                <OrdenesMarker lat={-53.7758740} lng={-67.7116210} orden={{nombre:'pablo'}} tipo={'E'} status={'I'} />
                <NodoMarker lat={-53.7798740} lng={-67.7333210} nodo={{ status:'OK'}} />
                <MovilMarker lat={-53.7798740} lng={-67.7233210} movil={{nombre:'Test'}}/>
                <BasicMarker lat={-53.7758740} lng={-67.7016210} />
                <CircleMarker lat={-53.7758740} lng={-67.6916210} /> */}

            { filteredOrdenes.map((orden,index) => <OrdenesMarker key={index} index={index} lat={orden.lng} lng={orden.lat} orden={orden} tipo={orden.ordentpo} status={orden.ordensts} />) }
            </MainMap>
        </div>
    )
};

export default MapScreen
