import React from 'react';
import Padding from '../Layout/Padding'
import { Dl, Dd, Dt } from './../Typo/Dl'
import moment from 'moment';
import paletas from './../../consts/paletas'

const NextAgendaDetails = ({nextAgenda,color}) => 
                                    <Padding>
                                        <Dl >
                                            <Dd title="Agenda"/>    <Dt data={nextAgenda.agendanro} color={color}/>
                                            <Dd title="Cliente"/>   <Dt data={nextAgenda.datos.agenda_clientenro} color={color}/>
                                            <Dd title="TurnoId"/>     <Dt data={nextAgenda.datos.agenda_turnoid} color={'white'}/>
                                            <Dd title="Turno"/>     <Dt data={paletas.turnos[nextAgenda.datos.agenda_turnoid].label} color={color}/>
                                            <Dd title="Fecha"/>     <Dt data={moment(nextAgenda.datos.agenda_fecha,'YYYY-MM-DD HH:mm').format('ll')} color={color}/>
                                            <Dd title="Zona"/>      <Dt data={nextAgenda.datos.agenda_zonaid+ ' ' + nextAgenda.datos.agenda_zonatpo} color={color}/>
                                            <Dd title="Domicilio"/> <Dt data={nextAgenda.datos.agenda_zonaid+ ' ' + nextAgenda.datos.agenda_zonatpo} color={color}/>
                                        </Dl>
                                    </Padding>

export default NextAgendaDetails
