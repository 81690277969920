import React from 'react';
const Btn = ({block, disabled,_handleBtnClick, icon,size, label}) => {
    return <button disabled={disabled}
        onClick={_handleBtnClick}
        className={`
        ${block && 'w-full'}
        ${disabled && 'opacity-50'} 
         text-${size}`}>
            {icon && !disabled && <i className={`fa fa-${icon}  fa-fw ${label ? 'mr-3' : ''}`}></i>}
        {label}
    </button>
}

export default Btn