import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../contexts/ConfigContext';
import FloatingWindow from '../Global/FloatingWindow';
import MainMap from '../MainMap'
import OrdenesDetails from './OrdenesDetails';
import OrdenesFilters from './OrdenesFilters';
import OrdenesMarker from './../Markers/OrdenMarker'
import OrdenesStats from './OrdenesStats'
import axios from 'axios'
import moment from 'moment'

// import { distanceTo } from 'geolocation-utils'

const OrdenesMap = () => {
    let context = useContext(ConfigContext)
    let [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    let [ordenes, setOrdenes] = useState([])
    let [activeOrden, setActiveOrden] = useState(null)
    let [filteredOrdenes, setFilteredOrdenes] = useState([])
    let [tipos, setTipos] = useState([])
    let [estados, setEstados] = useState([])

    let [statsLayers, setStatsLayers] = useState(true)

    useEffect(() => { 
        fetchMarkersByDate()
        let intervalFetchOrdenes = setInterval(()=>fetchMarkersByDate(),process.env.REACT_APP_INTERVAL_ORDENES)
        return () =>{ clearInterval(intervalFetchOrdenes) }
     }, [date,context.empresa])

    useEffect(() => {
        let filtered = ordenes.filter(orden => tipos.includes(orden.ordentpo) && estados.includes(orden.ordensts) )
        setFilteredOrdenes(filtered)
    }, [ordenes, tipos, estados])

  
    const onChildClick = (key, childProps) => setActiveOrden(childProps.orden)

    const fetchMarkersByDate = () => {
        axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/ordenes/fecha/${date}`,{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then(response => setOrdenes(response.data.ordenes)).catch(e => console.log(e))
    }

    return <MainMap
                points={filteredOrdenes}
                onChildClick={onChildClick}
                bottom={
                    <>
                        {statsLayers && <OrdenesStats ordenes={ordenes} filteredOrdenes={filteredOrdenes} />}
                        <OrdenesFilters setTipos={setTipos} setEstados={setEstados} />
                    </>
                    } 
                right={activeOrden && <OrdenesDetails orden={activeOrden} /> }
                left={ <FloatingWindow padding icon='search' title='Ordenes por fecha' >
                        <input type="date" value={date} onChange={e => setDate(e.target.value)} /> 
                        </FloatingWindow> } >
                { filteredOrdenes.map((orden,index) =>
                    <OrdenesMarker activa={ activeOrden && activeOrden.ordennro === orden.ordennro} key={index} index={index} lat={orden.lng} lng={orden.lat} orden={orden} tipo={orden.ordentpo} status={orden.ordensts} />)
                }
            </MainMap>
};

export default OrdenesMap