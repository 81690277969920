import  { useEffect, useContext , useState} from 'react'
import axios from 'axios'
import ConfigContext from '../contexts/ConfigContext'

const useFetchMonitoreo = (monitoreoLayer) => {
    let context = useContext(ConfigContext)
    const [fetchedData, setFetchedData] = useState(null)

    const fetchMonitoreo = () => {
        let api = context.empresa === 'tvf' ? 'REACT_APP_NODOS_API_TVF' : context.sucursal === 'uv' ? 'REACT_APP_NODOS_API_UV' : 'REACT_APP_NODOS_API_TOL'
        // axios.get(`${process.env.REACT_APP_CORS_PROXY}/${process.env[api]}nodosjsonfull.php?full=true`)
        console.log(`${process.env.REACT_APP_CORS_PROXY}/${process.env[api]}alertas.php?t=nodos`)
        axios.get(`${process.env.REACT_APP_CORS_PROXY}/${process.env[api]}alertas.php?t=nodos`)
            .then(response => { setFetchedData(response.data) })
            .catch(e => console.log(e))
    }

    useEffect(()=>{
        const fetchInterval = setInterval(() => { fetchMonitoreo() }, process.env.REACT_APP_INTERVAL_NODOS)
        return () => clearInterval(fetchInterval)    
    },[monitoreoLayer])

    useEffect(() => { fetchMonitoreo() }, [context.empresa, context.sucursal])

    return fetchedData
}

export default useFetchMonitoreo