import React, {useContext} from 'react'
import Cargando from '../Global/Cargando'
import { useHistory } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import Padding from '../Layout/Padding'
import IdBadge from '../Badges/IdBadge'
import MovilBadge from '../Badges/MovilBadge'
import EntityBadge from '../Badges/EntityBadge'
import ActionButtons from '../Global/ActionButtons'
import dayjs from 'dayjs'
import SigaButton from '../Global/SigaButton'
import ConfigContext from '../../contexts/ConfigContext'

const MovilesIndex = () => {
    const history = useHistory()
    const context = useContext(ConfigContext)
    const [moviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles`)

    const handleGoTo = (id,nombre,tecnico,tecnicoid) => history.push(`/moviles/${id}`, {state: {nombre:nombre,tecnico:tecnico,tecnicoid:tecnicoid}})
    const [csvData,setCSVDATA] = React.useState([])
    
    React.useEffect(() => {
         moviles && moviles
            .sort((a, b) => a.nombre > b.nombre ? 1 : -1)
            .map((movil,index) => setCSVDATA(csvData => {
                return [...csvData,{ID: movil.movil_id, MOVIL: movil.nombre, TECNICO: movil.tecnico}]
            }))
     },[moviles])

    return <Padding>
        <div className='flex items-center justify-between'>
            <h1>Móviles</h1>
            <div className='gap-2 flex'>

                { csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`moviles-${dayjs().format('DD-MM-YYYY')}`}/>}
            </div>
        </div>
        <h2>Centros de stock</h2>

        {moviles ? <table className="w-full ">
            <thead>
                <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Movil</th>
                    <th>Tecnico Asignado</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {moviles.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((movil,index) =>
                    <tr key={index}>
                        <td className='w-12'><IdBadge id={movil.movil_id} /></td>
						<td className='w-10'><SigaButton tipo='movil' nro={movil.movil_id} /></td>
                        <td ><EntityBadge type='movil' name={movil.nombre}/></td>

                        <td className='capitalize'>{movil.tecnico && <EntityBadge type='tecnico' name={movil.tecnico.toLowerCase()} size='xs'/>}</td>
                        <td className='text-right text-xs'>
                            <button onClick={() => handleGoTo(movil.movil_id,movil.nombre,movil.tecnico,movil.tecnicoid)}>Ver<i className='fa fa-angle-right ml-2' /></button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table> : <Cargando/>}
    </Padding>
}


export default MovilesIndex