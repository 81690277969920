import React from 'react';
const UserBadge = ({ name, detail, size='sm'}) => {
    return <div className='flex items-center justify-start'>
        <figure className='bg-gray-500 opacity-50 rounded-full w-8 h-8 ml-1 mr-3 flex items-center justify-center'>
            <i className='fa fa-user  text-white' />
        </figure>
        <div className={`flex flex-col text-${size}`}>
            <span className='text-base font-semibold capitalize'>{name.toLowerCase()}</span>
            <span className='opacity-50'>{detail}</span>
        </div>

    </div>
}
export default UserBadge