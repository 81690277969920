import React from 'react';

const MovilBadge = ({max, fetchedMoviles, movilAsignado }) => {
    return fetchedMoviles ? 
        <div className={`truncate inline-block p-2 ${max ? 'text-lg' : 'text-xs'}  text-yellow-400 font-semibold capitalize bg-gray-700 rounded-lg`}>
            <i className="fa fa-fw mr-1 fa-truck"></i>
            { movilAsignado && fetchedMoviles.find(movil => parseInt(movil.movil_id) === parseInt(movilAsignado)).nombre.toLowerCase()}
        </div> : 'Sin moviles'
}

export default MovilBadge