import React, { useContext, useState } from 'react'

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext'
import EntityBadge from '../Badges/EntityBadge'
import IdBadge from '../Badges/IdBadge'
import { Link } from 'react-router-dom'
import Padding from '../Layout/Padding'
import moment from 'moment'
import paletas from '../../consts/paletas'
import ActionButtons from '../Global/ActionButtons'
import SigaButton from '../Global/SigaButton'
import useFetch from '../../hooks/useFetch'

const OrdenesList = () => {
	const context = useContext(ConfigContext)
	const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'))
	const [filterEstado, setEstado] = useState('')
	const [filterTipo, setTipo] = useState('')

	const [ordenes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/ordenes/fecha/${fecha}`)

	const [csvData,setCSVDATA] = useState([])
    
    React.useEffect(() => {
         ordenes && ordenes.ordenes
		 	.filter(orden => orden.ordensts.includes(filterEstado) && orden.ordentpo.includes(filterTipo))
            .map((orden,index) => setCSVDATA(csvData => {
                return [...csvData,{NUMERO: orden.ordennro, TPO: orden.ordentpo, 'CLIENTE NOMBRE': orden.clientenombre, 'CLIENTE NUMERO': orden.clientenro, OBS: orden.ordenobs, STS: orden.ordensts }]
            }))
	 },[ordenes])

	return (
		<Padding>

			<div className='flex items-center justify-between '>
				<h1>Órdenes</h1>
				<input type='date' value={fecha} onChange={e => setFecha(e.target.value)} />
				{/* <button onClick={handleAgrupar}>{agrupar ? 'Desagrupar' : 'Agrupar'} <i className={`fa ml-1 fa-${agrupar ? 'object-ungroup' : 'object-group'}`} /></button> */}
				<select onChange={e => setTipo(e.target.value)} defaultValue={filterTipo}>
					<option value=''>Tipos</option>
					{Object.entries(paletas.tipos).map(([k, v]) => <option key={k} value={k}>{v.title}</option>)}
				</select>
				<select onChange={e => setEstado(e.target.value)} defaultValue={filterEstado}>
					<option value=''>Estados</option>
					{Object.entries(paletas.estados).map(([k, v]) => <option key={k} value={k}>{v.title}</option>)}
				</select>
			{ csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`orden-${moment().format('DD-MM-YYYY')}`}/>}
			</div>
			{ordenes ?
				<div>
					<table className='text-sm w-full' >
						<tbody>
							{ordenes.ordenes
								.filter(orden => orden.ordensts.includes(filterEstado) && orden.ordentpo.includes(filterTipo))
								.map((orden, index) =>
									<tr key={orden + '_' + index}>
										<td>
											<Link to={`/show/ordenes/${orden.ordennro}`}><IdBadge id={orden.ordennro} /></Link>
										</td>
										<td><SigaButton tipo='orden' nro={orden.ordennro} /></td>
										<td>
											<span className={` text-${paletas.tipos[orden.ordentpo].color}-400 flex flex-col items-center justify-center`}>
												<i className={` fa fa-fw fa-${paletas.tipos[orden.ordentpo].icon} mb-1`} />
												<span className='text-xxs'>{paletas.tipos[orden.ordentpo].title} </span>
											</span>
										</td>
										<td>
											<Link to={`/clientes/show/${orden.clientenro}`}>
                                            	<EntityBadge type='cliente' name={orden.clientenombre} detail={orden.clientenro} />
                                        	</Link>
										</td>
										<td className='w-1/3 opacity-75 text-xs'>{orden.ordenobs}</td>
										<td className='py-1  items-center'>
											<span className={`text-xxs inline-flex items-center mr-1 badge rounded-full bg-${paletas.estados[orden.ordensts].color}-400`}>
												<i className={`fa fa-fw fa-${paletas.estados[orden.ordensts].icon} mr-1`} />
												{paletas.estados[orden.ordensts].title}
											</span>
										</td>
										<td className='text-right'>
											<Link to={`/show/ordenes/${orden.ordennro}`}>
												<button><i className={`fa fa-fw fa-angle-right`} /></button>
											</Link>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				: <Cargando />}
		</Padding>
	)
}


export default OrdenesList
