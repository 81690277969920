import React, { useState, useEffect } from 'react';
import DesktopAppIndex from "../DesktopAppIndex";
import LoginForm from '../fragments/LoginForm';

const LoginScreen = () => {
	let [logged, setLogged] = useState(false)
	let [user, setUser] = useState()
	let [empresa, setEmpresa] = useState()
	let [sucursal, setSucursal] = useState()

	useEffect(() => {
		setEmpresa(localStorage.getItem('empresa'))
		setSucursal(localStorage.getItem('sucursal'))
		setUser(JSON.parse(localStorage.getItem('user')))
	}, [])

	if (logged && localStorage.getItem('user')) {
		return <DesktopAppIndex empresa={empresa} setEmpresa={setEmpresa} user={user} sucursal={sucursal} setSucursal={setSucursal} />
	} else {
		return <LoginForm setLogged={setLogged} setUser={setUser} setEmpresa={setEmpresa} setSucursal={setSucursal} />
	}

}




export default LoginScreen;
