import React, { useContext } from 'react';

import Cargando from '../Global/Cargando';
import ConfigContext from '../../contexts/ConfigContext';
const DashboardTarifas = () => {
    const [tarifas, setTarifas] = React.useState()
    const context = useContext(ConfigContext)

    const fetchTarifas = async () => {
        const headers = {'Authorization':process.env.REACT_APP_BRIDGE_API_KEY}

        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/tarifas`,{headers: headers})
        const responseJSON = await response.json()
        setTarifas(responseJSON)


    }

    const icons = {
        'television': 'tv',
        'internet-normal': 'wifi',
        'internet-gpon': 'wifi',
        'telefonia-m100': 'phone',
        'telefonia-m200': 'phone',
    }
    React.useEffect(() => { fetchTarifas() }, [context.empresa])

    return <div className='dashboard-card'>
        <h4 className='pb-2  opacity-50'><i className='fa fa-dollar-sign fa-fw text-teal-500 mr-2 ' />Tarifas</h4>
        {tarifas ?
            Object.entries(tarifas).map(([servicio, tarifas], i) => {
                return <details open={!i} key={i} className='pb-3'>
                    <summary className='text-xl font-semibold cursor-pointer hover:shadow-outline  rounded p-1'>
                        <i className={`fa fa-fw fa-${icons[servicio]} mr-3 text-teal-400 `} />
                        {/* <i className='fa fa-fw fa-angle-down mr-1 text-teal-500'/> */}
                        {servicio}
                    </summary>
                    <ul>
                        {tarifas.map((tarifa,index) =>
                            <li key={index} className={`${tarifa.vigente == 1 ? 'opacity-75' : 'opacity-25'} text-sm ml-8 py-1 grid grid-cols-3 gap-1 items-center`}>
                                <strong>{tarifa.tipo}</strong>
                                <span className='w-full text-right'>Importe <strong>${tarifa.importe}</strong></span>
                                <span className='w-full text-right'>Instalación <strong>${tarifa.instalacion}</strong></span>
                            </li>
                        )}
                    </ul>
                </details>
            }) : <Cargando />
        }
    </div>
}
export default DashboardTarifas