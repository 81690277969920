import { useEffect, useState } from "react";
import moment from "moment";

const useFetchPrefactibilidadLogs = (refresh, setRefresh, context) => {

    const [data, setData] = useState()
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/prefactibilidad/logs`, 
            { 
                method: 'POST',
                body: JSON.stringify({ query: "" }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': process.env.REACT_APP_BRIDGE_API_KEY
                }
            })
            const responseJSON = await response.json()
            const dataResponse = responseJSON.reverse()
            setData(dataResponse)
        } catch (e) {
            console.log('No hay registros')
            setData([])
        }
        setRefresh(false)
    }

    useEffect(() => {
        fetchData()
    }, [context.empresa, refresh])

    return { data, fetchData, setDate, date }
}

export default useFetchPrefactibilidadLogs
