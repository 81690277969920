import Cargando from "../Global/Cargando";
import React from "react";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
const DashboardTarifas = () => {
  const [climas, setClimas] = React.useState();

  const fetchClimas = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BRIDGE_API}/tvf/prod/datacenter/climas`,
      { headers: { Authorization: process.env.REACT_APP_BRIDGE_API_KEY } }
    );
    const responseJSON = await response.json();
    setClimas(responseJSON);
  };

  React.useEffect(() => {
    fetchClimas();
  }, []);

  const CIUDADES = {
    1: "Rio Grande",
    2: "Ushuaia",
  };
  return (
    <div className='dashboard-card'>
      <h4 className="pb-2  opacity-50">
        <i className="fa fa-cloud-sun-rain fa-fw text-teal-500 mr-2 " />
        Climas
      </h4>
      {climas ? (
        climas.filter(clima=>clima.ciudad_id != 6).map((clima,index) => {
          return (
            <div key={index} className=" pb-3">
              <div key={clima.id} className="flex items-center justify-between">
                <img src={clima.icono} alt={clima.icono} className="w-12  mr-5" />
                <div className="flex-1">
                    <p  className='text-lg'>
                        <strong>{clima.now}º </strong> 
                        <span className="capitalize text-teal-500"> {CIUDADES[clima.ciudad_id]} </span>
                    </p>
                  <p className="opacity-50  text-xs">
                    {clima.texto} <br />
                    Actual : | Humedad :{" "}
                    <strong>{clima.humedad}% </strong> | Viento :{" "}
                    <strong>{clima.viento}km/h </strong>
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Cargando />
      )}
    </div>
  );
};
export default DashboardTarifas;
