import React, {useContext} from 'react'

import ConfigContext from '../../contexts/ConfigContext';

export default ({agendas}) => {
    let context = useContext(ConfigContext)
    const turnos = [
        { label: 'Mañana' , color : 'yellow' , icon : 'sun', progress: 3 },
        { label: 'Tarde' , color : 'teal' , icon : 'cloudy', progress: 10 },
        { label: 'Vespertino' , color : 'blue' , icon : 'moon', progress: 3 }
    ]
    return <div className=' py-5 text-xs w-2/3 flex justify-center mx-auto'>
                {turnos.map(turno => 
                    <div className='flex-1 mx-2'>
                        <label className={`text-${turno.color}-500 ml-4`}>{turno.label}</label>
                        {/* <Progress height={3} dark={ context.theme == 'dark' } progress={turno.progress} total={Object.entries(agendas).length} color={turno.color} icon={turno.icon} /> */}
                    </div>
                )} 
            </div>
}
