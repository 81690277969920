import React from 'react';
import FloatingWindow from '../Global/FloatingWindow';
import Btn from '../Forms/Btn';
const SearchWindow = () => {

    const search = () => {
        console.log('ghola')
    }
    return <FloatingWindow pos='mt-48 p-4'>

        <input icon='search' placeholder='Buscar...' onChange={search} value={'test'} />
        <Btn icon='search' _handleBtnClick={search} label='Buscar' />
    </FloatingWindow>
}
export default SearchWindow