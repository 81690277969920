import React, { useRef, useState, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import useFetch from '../../hooks/useFetch'
import Padding from '../Layout/Padding'
import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext'

const EnlacesIndex = (props) => {

    const context = useContext(ConfigContext)
    const [enlaces] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/enlaces`)
    const iframeRef = useRef(null)
    const handleLink = async (url) => {
        await setOpenFrame(true)
        iframeRef.current.src = url
    }
    const close = () => {
        setOpenFrame(false)
    }
    const [openFrame, setOpenFrame] = useState(false)
    const screenWidth = window.innerWidth
    const iframeAnimatedProps = useSpring({ right: openFrame ? '0px' : -screenWidth + 'px' })

    return (
        <Padding>
            <div className='flex items-center justify-between'>
                <h1>Enlaces</h1>
                {openFrame && <button onClick={close} className=''><i className='fa fa-arrow-left fa-fw mr-1 ' /> Volver al indice</button>}
            </div>
            {openFrame ? <div className='overflow-hidden w-full min-h-screen'>
                <div className="relative  w-full min-h-screen">
                    <animated.iframe
                        style={{ ...iframeAnimatedProps, width: '100%' }}
                        ref={iframeRef} name='internalWindow' title='internalWindow' target="_parent" className='mb-5  min-h-screen absolute top-0 z-40 bg-gray-100 rounded shadow border ' />
                </div>
            </div>
                :
                <div className='grid grid-cols-4 gap-5'>

                    {enlaces && [...new Set(Object.values(enlaces).map(enlace => enlace.sector))].map(sector =>
                        <div className=''>
                            <label>{sector}</label>
                            <div className='ml-2 pr-3'>
                                {enlaces.filter(enlace => enlace.sector == sector).map((enlace, index) => (
                                    <div key={index} className='group cursor-pointer flex w-full items-center justify-between py-1 px-2 opacity-75 hover:opacity-100  my-2 border-transparent border-b-2 hover:border-teal-500' onClick={() => handleLink(enlace.link)}>
                                        <i className={`fa fa-fw mr-2 fa-${enlace.icon} opacity-50 text-xs`} />
                                        <span className='flex-1'>{enlace.titulo}</span>
                                        <i className={`fa  fa-external-link-alt  text-xs group-hover:text-teal-500 text-white`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>}


        </Padding>
    )
}

export default EnlacesIndex