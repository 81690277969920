export default {
    1: ['consultas','operador','tecnico','dev','gerencia'],
    2: ['consultas','tecnico','dev','gerencia'],
    3: ['consultas','operador','dev','gerencia'],
    4: ['operador','tecnico','dev','gerencia'],
    5: ['operador','dev','gerencia'],
    6: ['tecnico','dev','gerencia'],
    7: ['consultas','dev','gerencia'],
    8: ['dev','gerencia'],
    9: ['gerencia'],
    10: ['directorio'],
}