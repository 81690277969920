export default {
    server: 'http://maps-api-server.test/',
    appName:'geo',
    appIcon : 'map-pin',
    tvf : {
        lat : -53.7821091,
        lng : -67.7055095,
        zoom : 13,
        color : 'purple',
        empresa : 'tvf',
        ciudad : 'Río Grande',
        ciudad_abbr: 'rg',
        dbname: 'tvf',
        layers: [
            {'tvf_nodos':{ label : 'Nodos', icon:'broadcast-tower'}}, 
            {'tvf_barrios':{ label : 'Barrios', icon:'building'}}, 
            {'tvf_gpon':{ label : 'Zonas Gpon', icon:'network-wired'}},  
            {'tvf_digitales':{ label : 'Barrios Digitales', icon:'wifi'}}, 
            {'tvf_operativas':{ label : 'Zonas Operativas', icon:'object-ungroup'}},
            {'tvf_trabajando':{ label : 'Trabajando', icon:'tools'}}
        ]
    }, 
    uv : {
        lat : -54.8068581,
        lng : -68.3378226,
        zoom : 13,
        color : 'yellow',
        empresa : 'uv',
        ciudad : 'Ushuaia',
        ciudad_abbr: 'ush',
        dbname: 'ush',
        layers: [
            {'uv_nodos':{ label : 'Nodos', icon:'broadcast-tower'}},  
            {'uv_barrios':{ label : 'Barrios', icon:'building'}}, 
            {'uv_cobertura_fixed':{ label : 'Zonas Gpon', icon:'network-wired'}},   
            {'uv_operativas':{ label : 'Zonas Operativas', icon:'object-ungroup'}}, 
            {'uv_trabajando':{ label : 'Trabajando', icon:'tools'}}
        ]

    },
    tol : {
        lat : -54.5077852,
        lng : -67.2271056,
        zoom : 13,
        color : 'blue',
        empresa : 'tol',
        ciudad : 'Tolhuin',
        ciudad_abbr: 'tol',
        dbname: 'tol',
        layers: [
            {'tol_nodos':{ label : 'Nodos', icon:'broadcast-tower'}},  
            {'tol_barrios':{ label : 'Barrios', icon:'building'}}, 
            {'tol_cobertura_fixed':{ label : 'Zonas Gpon', icon:'network-wired'}},   
            {'tol_operativas':{ label : 'Zonas Operativas', icon:'object-ungroup'}}, 
            {'tol_trabajando':{ label : 'Trabajando', icon:'tools'}}
        ]

    },
}