import React, { useContext } from "react";
import ConfigContext from "../../contexts/ConfigContext";
import SidebarLogo from "./SidebarLogo";
import SidebarThemeSelector from "./SidebarThemeSelector";

const SidebarSwitcher = () => {
  let { empresa, setEmpresa, setSucursal, sucursal ,roles } = useContext(ConfigContext);

  return (
    <div>
      <div className="flex flex-col md:flex-row mb-3 mt-3 md:mt-0 gap-2  w-full items-center justify-between">
        <SidebarLogo />
        <SidebarThemeSelector />
        {!roles.includes('guest') ? <select 
          value={empresa} 
          onChange={(e) => {
            setEmpresa(e.target.value)
            setSucursal(e.target.value)
          }}
        >
          {["tvf", "uv"].map((em) => <option key={em} value={em}> {em}</option>)}
        </select> : null}
      </div>
      {empresa === 'uv' 
        ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5}}>
            <p style={{fontWeight: 'bold', marginRight: 5}}>Sucursal:</p>
            <select value={sucursal} onChange={(e) => setSucursal(e.target.value)}>
              {["uv", "tol"].map((suc) => <option key={suc} value={suc}> {suc}</option>)}
            </select>
          </div> 
        : null
      }
    </div>
  );
};

export default SidebarSwitcher;
