import React, { useState } from 'react';

import EntityBadge from '../Badges/EntityBadge';
import Padding from '../Layout/Padding';
import dayjs from 'dayjs'


const ClientesSupercanal = () => {
    // const context = useContext(ConfigContext);
    const [query, setQuery] = useState({name: '', user: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
    const [selected, setSelected] = useState(false)
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)

    const handleQuery = e => setQuery({...query, [e.target.name]: e.target.value})

    const searchClientes = async () => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/uv/prod/cuo/app/street/search`,
        // const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/uv/prod/cuo/app/clientes/search`,
            {
                method: 'POST',
                body: JSON.stringify({ query: query }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': process.env.REACT_APP_BRIDGE_API_KEY
                }
            })
        if(response.status === 200){
            const responseJSON = await response.json()
            setResults(responseJSON)
            setLoading(false)
        }else{
            setLoading(false)
        }
    }

    const ESTADOTPO = {
        X: "VER CONDICION",
        C: "NO APTO",
        K: "NO APTO",
        I: "NO APTO",
        P: "NO APTO",
        B: "NO APTO",
        M: "NO APTO",
        E: "NO APTO",
        A: "APTO"
    }
 
    return (
        <Padding>
            <h2>Pre-Factibilidad SION</h2>
            <div className='flex flex-col'>
                <label>Ingrese Direccion</label>
                <input disabled={selected} className='my-2 text-xl' value={query.name} onChange={handleQuery} name='name' placeholder='Buscar calle' />
            </div>

            {selected ? <div className='flex flex-col'>
                <label>Ingrese Numero</label>
                <input className='my-2 text-xl' onChange={handleQuery} type='number' name='number' placeholder='Buscar nro puerta' />
            </div> : null}
            <div>
                <button onClick={() => searchClientes()}> Buscar </button>
                <button onClick={() => {
                    setQuery({name: '', user: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
                    setSelected(false)
                    setResults([])
                }}> Reset </button>
            </div>
            {loading ? <p>Buscando...</p> : null}
            <table className='w-full text-sm'>
                <thead>
                    <tr>
                        <td>Dirección</td>
                        <td className='text-center'>Pre-Venta</td>
                        <td className='text-center'>Ultima factura</td>
                    </tr>
                </thead>
                <tbody>
                    {results.length > 0 ? results.sort((a, b) => {
                            if (a.habilitado > b.habilitado) {
                                return -1;
                            }
                            if (a.habilitado < b.habilitado) {
                                return 1;
                            }
                            return 0;
                        }).map((result,index) => result.geocalle 
                    ? (
                        <TableClient 
                            result={result} 
                            index={index} 
                        />
                    )
                    : (
                        <TableStreet 
                            result={result} 
                            index={index} 
                            query={query} 
                            setQuery={setQuery} 
                            setSelected={setSelected} 
                            setResults={setResults}
                        />
                    )) : <tr></tr>}
                </tbody>
            </table>
        </Padding>
    )
};

function TableStreet({result, index, query, setQuery, setSelected, setResults}) {
    return <tr key={index}>
        <td 
            onClick={() => {
                setQuery({...query, name: result.name})
                setSelected(true)
                setResults([])
            }}
            className='p-1 capitalize cursor-pointer'
        >
            <EntityBadge type='cliente'  detail={`${result.name}`} />
        </td>
    </tr>
}

function TableClient({result, index}) {

    return <tr key={index}>
        <td className='p-1 capitalize'><EntityBadge type='cliente'  detail={`${result.geocalle}`} /></td>
        <td className={`text-center p-1 opacity-50 font-bold ${result.habilitado === 'NO APTO' ? 'text-red-700' : 'text-green-700'}`}>{result.habilitado}</td>
        {result.habilitado === 'NO APTO' 
            ? <td className=' text-center p-1 opacity-50'>{dayjs(result.utima_factura).format('DD/MM/YYYY')}</td>
            : result.ultima_factura.includes('DIRECCÍON')
                ? <td className=' text-red-700 text-center p-1 opacity-50'>{result.ultima_factura}</td>
                : null
        }
    </tr>

}


export default ClientesSupercanal