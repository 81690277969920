import GoogleMapReact from 'google-map-react'
import React, { useContext, useEffect, useState } from 'react'
import FloatingWindow from '../../components/Global/FloatingWindow'
import CONSTS from '../../consts/consts'
import MAPSTYLES from '../../styles/mapstyles'
import useFetch from '../../hooks/useFetch'
import ConfigContext from '../../contexts/ConfigContext'

const SERVICIOS_COLORES = {
    D: 'b5f4a5',
    B: '9ae6b4',
    F: 'ffe484',
    I: '93ddfd',
    N: 'd9a9ff',
    C: 'ff8383',
}

export default function ServiciosMap() {
    let context = useContext(ConfigContext)
     const mapOptions = {
        styles: MAPSTYLES[context.theme],
        backgroundColor: 'none',
        zoomControl: true,
        fullscreenControl: true,
        streetViewControl: true
    };
    const [sts, setSts] = useState('')

    const [clientes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/utils/geojson/servicios/${sts}`)
    const [filtered, setFiltered] = useState()


    let [googleMaps, setGoogleMaps] = useState(null)
    let [gmap, setGMap] = useState(null)
    const initMap = (map, maps) => { setGMap(map); setGoogleMaps(maps) }

    let [center, setCenter] = useState({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng })


    useEffect(() => setCenter({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng }), [context.empresa])

    const handleOnGoogleApiLoaded = ({ map, maps }) => initMap(map, maps)

    const [servicios, setServicios] = useState([])

    const handleAddServicio = s => {
        if (servicios.indexOf(s) !== -1) {
            servicios.splice(servicios.indexOf(s), 1)
            return setServicios([...servicios])
        }
        return setServicios([...servicios, s])
    }
    useEffect(() => {
        if (clientes && servicios) { 
            console.log('servicios' , servicios)
            clientes.features.filter( feature => {
                console.log('letras ' , feature.properties.prd.split(''))
                return feature.properties.prd.split('').map( letra =>  servicios.join('').includes(letra)) 
            })
            setFiltered(clientes.features.filter( feature =>  feature.properties.prd.split('').map( letra =>  servicios.join('').includes(letra)) ) )
        } 
    }, [clientes, servicios])

    useEffect(() => {

        if (filtered) {
            gmap.data.addGeoJson({
                "type": "FeatureCollection",
                "features": filtered
            });
            
            gmap.data.setStyle((f) => {
                return {
                    icon: {
                        url: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${f.getProperty('prd')}|fff|000000`
                    }
                };
            });
        }

    }, [filtered, gmap])

    const handleSetSts = (e) => {
        gmap.data.forEach(function (feature) { gmap.data.remove(feature); });
        setSts(e.target.value)
    }

    return (
        <main style={{ height: '100vh' }} className="overflow-hidden w-full relative h-full md:min-h-screen">
            <div className=' w-46 mt-3 top-0 absolute   z-50 flex flex-col gap-3 ml-3'>
                <FloatingWindow title='Servicios' icon='coins'>
                    <div className='space-y-2 flex flex-col'>

                        <div className='flex space-x-2 items-center justify-between'>
                            <label>Estado</label>
                            <select onChange={handleSetSts}>
                                <option value=''>Todos</option>
                                <option value='C'>CONECTADO</option>
                                <option value='K'>CORTE DE SEÑAL</option>
                                <option value='X'>DESCONECTADO</option>
                                <option value='E'>BASICO</option>
                                <option value='B'>BAJA PENDIENTE</option>
                                <option value='M'>BAJA MOROSO</option>
                                <option value='I'>INGRESADO</option>
                            </select>
                        </div>
                    </div>
                    <label>Servicios</label>
                    <div className='flex space-x-2 items-center justify-between my-1'>
                        <button  onClick={() => handleAddServicio('B')} className={`text-xs ${servicios.includes('B')&&'active'} border-2 border-transparent`}>Basico</button>
                        <button  onClick={() => handleAddServicio('T')} className={`text-xs ${servicios.includes('T')&&'active'} border-2 border-transparent`}>Telefono</button>
                        <button  onClick={() => handleAddServicio('I')} className={`text-xs ${servicios.includes('I')&&'active'} border-2 border-transparent`}>Internet</button>
                        <button  onClick={() => handleAddServicio('D')} className={`text-xs ${servicios.includes('D')&&'active'} border-2 border-transparent`}>Dibox</button>
                        <button  onClick={() => handleAddServicio('F')} className={`text-xs ${servicios.includes('F')&&'active'} border-2 border-transparent`}>F</button>
                    </div>
                    {servicios.sort().flat() }

                </FloatingWindow>
            </div>
            <GoogleMapReact
                options={mapOptions}
                center={center}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleOnGoogleApiLoaded}
                bootstrapURLKeys={{ key: context.gmapApiKey, libraries: ['places', 'visualization'], }}
                zoom={CONSTS[context.empresa].zoom} >
            </GoogleMapReact>
        </main>
    )
}
