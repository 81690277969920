import React, { useContext } from 'react';
import Cargando from '../Global/Cargando';
import useFetchMonitoreo from '../../hooks/useFetchMonitoreo';
const DashboardMonitoreo = () => {

    const tipos = {
        0: 'green-500',
        1: 'yellow-500',
        2: 'red-500',
        3: 'gray-500',
        4: 'black',
        5: 'pink-500',
        6: '',
        7: ''
    }
    const icons = {
        0: 'bullseye',
        1: 'exclamation',
        2: 'exclamation-triangle',
        3: 'exclamation-circle',
        4: 'thumbs-down',
        5: 'briefcase',
        6: 'exclamation',
        7: 'exclamation'
    }

    const estados = {
        0: '',
        1: 'ALERT',
        2: 'CRITICAL',
        3: 'UNKOWN',
        4: 'DOWN',
        5: 'MAINTENANCE',
        6: 'STATUS CODE 6',
        7: 'STATUS CODE 7'
    }

    const monitoreo = useFetchMonitoreo(true)

    return  <div className='dashboard-card'>
    <h4 className='pb-2  opacity-50'><i className='fa fa-bullseye fa-fw text-teal-500 mr-2 '/>Monitoreo de Nodos</h4>
    {monitoreo ? 
        Object.entries(monitoreo).filter( ([n,d])=> d.status > 0 ).map(([nodo, datos],index) => 
                        <details key={index} className={`text-sm  mb-1`}>
                            <summary  className={`flex font-bold animate-pulse cursor-pointer items-center justify-between  rounded hover:shadow-outline md:px-1 px-0 text-${ tipos[datos.status]} mb-1`}>
                                <span>
                                    <i className={`hidden lg:inline-block fa fa-${icons[datos.status]} fa-fw mr-1`}/>
                                    {nodo} 
                                </span>
                                { estados[datos.status] != '' && <span className={`flex flex-1 mx-2 h-1 opacity-25 bg-${ tipos[datos.status]}`}></span>}
                                <span className='font-semibold uppercase tracking-wider '>
                                    { estados[datos.status]}
                                </span>
                            </summary>
                                { Object.entries( datos.servicios ).map( ([servicio,info],indexServicio)=>
                                    <details key={indexServicio} className={`text-xs my-1 ${info.status>0 && `font-bold text-${ tipos[datos.status]}`} `} >
                                        <summary className='ml-5 cursor-pointer hover:opacity-100 opacity-75' ><i className='fa fa-angle-right opacity-50'/> {servicio}</summary>
                                        { Object.entries( info ).map( ([ k, v],indexInfo)=>
                                              <div key={indexInfo} className='text-xxs ml-8 flex my-1'>
                                                <span className={`truncate w-1/4 opacity-75 uppercase text-${ tipos[datos.status]}`} > {k} </span>
                                                <span className='ml-1 w-3/4'>{v}</span>
                                              </div>
                                        )}
                                    </details>
                                 )}

                        </details>
                    )
        
         : <Cargando />
    }
</div>
}
export default DashboardMonitoreo