import { useEffect, useState } from 'react'

import axios from 'axios'

const useCheckMovilAvaiable = (movilId) => {
	let [pausedStatus, setPausedStatus] = useState(false)

	useEffect(() => {
		if (movilId) {
			_fetchMovilAvaiable();
			setInterval(() => {
				_fetchMovilAvaiable()
			}, process.env.REACT_APP_INTERVAL_PAUSED || 15000)
		}
	}, [movilId]);

	const _fetchMovilAvaiable = () => {
		axios
			.get(`${process.env.REACT_APP_CUO_API}units/${movilId}/state`,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
			.then(response => response.data == 1 ? setPausedStatus(false) : setPausedStatus(true))
			.catch(e => console.log('pausedStatus? ', e))
	}

	return pausedStatus
}

export default useCheckMovilAvaiable