import React from 'react'
import LogoSvg from '../components/Global/LogoSvg'

const OopsScreen = (props) => {

    return (
        <div className=' h-screen justify-center flex items-center flex-col text-center'>
            <LogoSvg width={200} height={143}/>
            <h1> 😬  Oops! </h1>
            <p className='opacity-50'>Lo sentimos, esta pagina no existe o usted no tiene acceso a ella.</p>
        </div>
    )
}

export default OopsScreen