import React, { useContext, useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router";
import dayjs from "dayjs";

import ClienteMarker from "../Markers/ClienteMarker";
import ConfigContext from "../../contexts/ConfigContext";
import SigaButton from "../Global/SigaButton";
import MainMap from "./../MainMap";
import Cargando from "./../Global/Cargando";
import useFetch from "../../hooks/useFetch";
import ActionButtons from "../Global/ActionButtons";
import PALETA from "../../consts/paletas";

export default ({ match }) => {
    const context = useContext(ConfigContext)
    let { goBack, push } = useHistory()

    const [dataDetails, setDataDetails] = useState()
    const [documentStatus, setDocumentStatus] = useState()
    const [ordersStatus, setOrdersStatus] = useState()
    const [csvData, setCsvData] = useState([])
    const [empresaLocal, setEmpresaLocal] = useState(match.params.empresa)

    const ARRAY_CRM = ["crmfchini", "crmusring", "motivo_1", "motivo_2", "motivo_3", "motivo_4", "crmobs"];
    const FIELDNAME = {
        crmfchini: "Fecha de inicio",
        crmusring: "Usuario que ingreso",
        motivo_1: "Motivo 1",
        motivo_2: "Motivo 2",
        motivo_3: "Motivo 3",
        motivo_4: "Motivo 4",
        crmobs: "Observación",
    };

    useEffect(() => {
        if(match.params.empresa){
            context.setEmpresa(match.params.empresa)
            setEmpresaLocal(match.params.empresa)
        }
    },[match])

    const [data,fetchData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${empresaLocal}/prod/cuo/clientes/nro/${match.params.nro}`);
    const [belongs,fetchBelongs] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${empresaLocal}/prod/cuo/utils/belongs/id/${match.params.nro}`);

    const handleCRM = async() =>{
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${empresaLocal}/prod/cuo/clientes/${match.params.nro}`,{
            method: 'GET',
            headers : { 
                'Authorization': process.env.REACT_APP_BRIDGE_API_KEY, 
                'Content-type':'application/json' 
            }
        })
        const responseJSON = await response.json()
        const statusDcocument = responseJSON.documentos.slice(0, 4).every(doc => doc.facturasts === "P")
        const statusOrders = responseJSON.ordensrv.slice(0, 5).every(ord => ord.ordensts === "C" || ord.ordensts === "F")
        setDocumentStatus(statusDcocument);
        setOrdersStatus(statusOrders)
        setDataDetails(responseJSON)
        
    };
    
    useEffect(() => {
        fetchData()
        fetchBelongs()
        handleCRM()
    }, []);

    useEffect(() => {
       data && belongs && data.map((dat)=> setCsvData(csvData =>{
            return [...csvData,{ClienteNro: dat.clientenro, Nombre: dat.nombre, Barrio: dat.barrio, Geodiv1: dat.geodiv1, Geoviv2: dat.geodiv2, Ubicación: dat.ubicación, Status: dat.status, EmailPPL: dat.emailppl, EmailSec: dat.emailsec, Telefono: dat.telefono, TitularDebitoAutomatico: dat.titular, CalleNombre: dat.callenombre, CalleGeoNombre: dat.callegeonombre, LNG: dat.lng, LAT: dat.lat, Zona: belongs.perteneceA.zonas, Nodo: belongs.perteneceA.nodos, ZonaOperativa: belongs.perteneceA.operativas}]
       }))
    }, [data]);

    return (
        <div>
            {data && belongs ?
                <div className='flex justify-between items-start bg-white'>
                    <div className='w-1/2 p-5'>
                        <div className='flex items-center justify-between mb-5 '>
                            <button onClick={()=> goBack(-1)} >Volver</button>
                            <SigaButton tipo='cliente' nro={match.params.nro} full/>
                            {data &&  <ActionButtons csvData={csvData} filename={`Cliente-Nº-${data[0].clientenro}-${dayjs().format('DD-MM-YYYY')}`}></ActionButtons>}
                        </div>

                        {belongs ?
                            <div className='mt-10 w-full flex flex-row justify-between'>
                                <div className='flex items-center flex-col text-center p-4 pb-0'>
                                    <i className='fa fa-fw mb-2 text-teal-400 fa-building' />
                                    <label>Barrio</label>
                                    <p className='font-bold text-lg'>{belongs.perteneceA.barrios ? belongs.perteneceA.barrios : "-"}</p>
                                </div>
                                <div className='flex items-center flex-col text-center p-4 pb-0'>
                                    <i className='fa fa-fw mb-2 text-teal-400 fa-network-wired' />
                                    <label>Zona</label>
                                    <p className='font-bold text-lg'>{belongs.perteneceA.zonas ? belongs.perteneceA.zonas : "-"}</p>
                                </div>
                                <div className='flex items-center flex-col text-center p-4 pb-0'>
                                    <i className='fa fa-fw mb-2 text-teal-400 fa-broadcast-tower' />
                                    <label>Nodo</label>
                                    <p className='font-bold text-lg'>{belongs.perteneceA.nodos ? belongs.perteneceA.nodos : "-"}</p>
                                </div>
                                <div className='flex items-center flex-col text-center p-4 pb-0'>
                                    <i className='fa fa-fw mb-2 text-teal-400 fa-object-ungroup' />
                                    <label>Zona Operativa</label>
                                    <p className='font-bold text-lg'>{belongs.perteneceA.operativas ? belongs.perteneceA.operativas : "-"}</p>
                                </div>
                                { belongs.perteneceA.supercanal && <div className='flex items-center my-1'>
                                    <div className='w-1/3 font-semibold text-xs text-teal-500'>Supercanal</div>
                                    <p className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.supercanal ? belongs.perteneceA.supercanal : "-" }</p>
                                </div> }
                                
                            </div> : <p className='flex flex-col items-center my-1'><small className='opacity-50'>Detectando pertenencias...</small></p>
                        }
                        <div className='flex w-full bg-teal-400 mt-5 mb-5' style={{height: "1px"}}/>

                        {/* INFO */}
                        <div className="flex flex-col">
                            <div className={`flex w-full border-2 p-3 rounded-md mb-6 ${data[0].status === "C" ? "border-teal-400" : "border-red-500"}`}>
                                <div className="flex flex-row- items-center w-2/3">
                                    <i class="fas fa-user text-teal-500 mr-2"/>
                                    <p className='text-gray-700 font-bold'>{data[0].nombre}</p>
                                </div>
                                <div className="flex flex-row items-center w-1/3 justify-end">
                                    {data[0].status === "C" ? <i class="fas fa-check-circle text-green-500 mr-1"/> : <i class="fas fa-times-circle text-red-500 mr-1"/>}
                                    <p className={`text-right font-bold uppercase ${data[0].status === "C" ? "text-green-400" : "text-red-500"}`}>{PALETA.estadotpo[data[0].status]}</p>
                                </div>
                            </div>
                            <InfoItem icon="fas fa-address-card text-red-500" label="Número de cliente" value={data[0].clientenro} />
                            <InfoItem icon="fas fa-map-signs text-green-500" label="Dirección" value={data[0].callenombre} />
                            <InfoItem icon="fas fa-map-marker-alt text-teal-500" label="Ubicación" value={data[0].ubicacion} />
                            <InfoItem icon="fas fa-envelope text-blue-500" label="Email" value={data[0].emailppl} />
                            <InfoItem icon="fas fa-phone text-yellow-500" label="Teléfono" value={data[0].telefono} />
                            <InfoItem icon="fas fa-credit-card text-gray-500" label="Titular débito automático" value={data[0].titular} />
                            <div className='flex w-full bg-teal-400 mb-2' style={{height: "1px"}}/>
                            <div>
                                {dataDetails && dataDetails.contratos.filter(fil => fil.contratosts === "C").length > 0 ? (<p className="text-gray-700">Contratado: </p> ): (<p>No tiene productos activos</p>)}
                                {dataDetails && dataDetails.contratos.filter(fil => fil.contratosts === "C").map((res, index)=>(
                                    <p className="text-sm font-bold uppercase text-teal-900">- {res.producto}</p>
                                ))}
                            </div>
                        </div>

                        {/* DOCUMENTOS */}
                        <details className={`text-xl text-teal-500 mt-6 border-2 p-2 rounded-md ${documentStatus ? "border-teal-400" : "border-red-500"}`}>
                            <summary className={`text-base font-bold mb-4 cursor-pointer ${documentStatus ? "text-teal-500" : "text-red-500"}`}>Documentos</summary>
                            {dataDetails && dataDetails.documentos[0] ?  
                                dataDetails.documentos.slice(0, 4).map((doc, index) =>(
                                    <Fragment key={index}>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Fecha de vencimiento: </p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{dayjs(doc.facturavto).format("DD/MM/YYYY")}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Fecha de cobro: </p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{doc.facturafcob.includes('0001') ? '-' : dayjs(doc.facturafcob).format("DD/MM/YYYY")}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Estado: </p>
                                            <p className={`text-sm font-bold ml-2 ${doc.facturasts === "P" ? "text-green-500" : "text-red-500"}`}>{PALETA.facturas[doc.facturasts]}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-4">
                                            <p className="text-sm text-gray-700">Importe:</p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{`$${doc.facturatotal}`}</p>
                                        </div>
                                        {index < 3 && <div className='flex w-full bg-gray-600 mt-5 mb-5' style={{height: "1px"}}/>}
                                    </Fragment>
                                )) : <p className="font-bold text-gray-500">No existen datos</p>
                            }
                        </details>

                        {/* ORDENES */}
                        <details className={`text-xl text-teal-500 mt-6 border-2 p-2 rounded-md ${ordersStatus ? "border-teal-400" : "border-red-500"}`}>
                            <summary className={`text-base font-bold mb-4 cursor-pointer ${ordersStatus ? "text-teal-500" : "text-red-500"}`}>Ordenes</summary>
                            {dataDetails && dataDetails.ordensrv[0] ?
                                dataDetails.ordensrv.slice(0, 5).map((ord, index)=>(
                                    <div key={index}>
                                        <div className="flex mb-1">
                                            <div className="w-1/2">
                                                <div className="flex flex-row items-center mb-1">
                                                    <p className="text-sm text-gray-700">Número de orden:</p>
                                                    <p className="text-sm text-teal-500 font-bold ml-2">{ord.ordennro}</p>
                                                </div>
                                                <div className="flex flex-row items-center mb-1">
                                                    <p className="text-sm text-gray-700">Generada por:</p>
                                                    <p className="text-sm text-teal-500 font-bold ml-2">{PALETA.generado[ord.ordengen]}</p>
                                                </div>
                                            </div>
                                            <div className="w-1/2 ml-2">
                                                <button style={{height: "40px", border: "2px solid #4fd1c5", color: "#38b2ac"}} className="w-full text-base font-bold" onClick={()=>push(`/show/ordenes/${ord.ordennro}`)}>Ver orden</button>
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Tipo de orden:</p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{PALETA.ordentpo[ord.ordentpo]}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Fecha de ingreso:</p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{dayjs(ord.ordenfing).format("DD/MM/YYYY")}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Estado de la orden:</p>
                                            <p className={`text-sm font-bold ml-2 ${(ord.ordensts === "C" || ord.ordensts === "F") ? "text-teal-500" : "text-red-500"}`}>{PALETA.ordensts[ord.ordensts]}</p>
                                        </div>
                                        <div className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">Descripción:</p>
                                            {ord.descripcion ? <p className="text-sm text-teal-500 font-bold ml-2">{ord.descripcion}</p> : <p className="font-bold text-gray-500 ml-2 text-sm">SIN DATOS</p>}
                                        </div>
                                        {index < 4 && <div className='flex w-full bg-gray-600 mt-5 mb-5' style={{height: "1px"}}/>}
                                    </div>
                                )) : <p className="font-bold text-gray-500">No existen datos</p>
                            }
                        </details>
                        
                        {/* CRM */}
                        <details className="text-xl text-teal-500 mt-6 border-2 border-teal-400 p-2 rounded-md">
                            <summary className="text-base font-bold text-teal-500 mb-4 cursor-pointer">Último CRM</summary>
                            {dataDetails && dataDetails.crms[0] ? 
                                Object.keys(dataDetails.crms[0])
                                    .filter(fil => ARRAY_CRM.includes(fil))
                                    .sort((a, b) => (a === "crmobs") ? 1 : (b === "crmobs") ? -1 : 0)
                                    .map((crm, index)=>(
                                        <div key={index} className="flex flex-row items-center mb-1">
                                            <p className="text-sm text-gray-700">{FIELDNAME[crm]}:</p>
                                            <p className="text-sm text-teal-500 font-bold ml-2">{crm === "crmfchini" ? dayjs(dataDetails.crms[0][crm]).format("DD/MM/YYYY") : dataDetails.crms[0][crm]}</p>
                                        </div>
                                )) : <p className="font-bold text-gray-500">No existen datos</p>
                            }
                        </details>

                        {/* ARTICULOS */}
                        <details className="text-xl text-teal-500 mt-6 border-2 border-teal-400 p-2 rounded-md">
                            <summary className="text-base font-bold text-teal-500 mb-4 cursor-pointer">Articulos</summary>
                            {dataDetails && dataDetails.articulos[0] ?  
                                dataDetails.articulos.map((art, index)=>(
                                    <div className="flex flex-col mb-4">
                                        <div key={index} className="flex flex-row items-center mb-1">
                                            <div className="text-sm text-gray-700">Nombre:</div>
                                            <div className="text-sm text-teal-500 font-bold ml-2">{art.articulonombre}</div>
                                        </div>
                                        <div key={index} className="flex flex-row items-center">
                                            <div className="text-sm text-gray-700">Serie:</div>
                                            <div className="text-sm text-teal-500 font-bold ml-2">{art.articuloserie}</div>
                                        </div>
                                    </div>
                                )) : <p className="font-bold text-gray-500">No existen datos</p>
                            }
                        </details>
                    </div>
                    <div className='flex-1 ml-3'>
                        {data &&
                            <MainMap
                                heatmap={false}
                                recenter={{ lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lng) }}
                                sizeOptionsMap={true}
                            >
                                <ClienteMarker
                                    icon="user"
                                    lat={parseFloat(data[0].lat)}
                                    lng={parseFloat(data[0].lng)}
                                    text={data[0].nombre}
                                    size="6" />
                            </MainMap>}
                    </div>
                </div>
            : <Cargando size="2xl"/>}
        </div>
    )
}

const InfoItem = ({ icon, label, value }) => {
    return (
      <div className="flex flex-row mb-4 items-center">
        <i className={icon} style={{ width: '26px' }} />
        <p className="mr-2 text-gray-700">{label}:</p>
        {value !== " " ? <p className="font-bold uppercase text-teal-500">{value}</p> : <p className="font-bold text-gray-500">SIN DATOS</p>}
      </div>
    );
};