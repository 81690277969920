import React from 'react';
import { NavLink } from 'react-router-dom'

const SidebarLink = (props) => {

    return <NavLink to={props.to} className={`py-1 px-2 w-full md:text-left font-medium flex items-center justify-start  rounded  hover:text-teal-500 hover:shadow-outline opacity-75 ${props.active && 'opacity-100 active'}`} >
        <i className={`fa text-teal-500 text-base fa-fw fa-${props.icon} mr-2`}></i>
        <span className='md:inline hidden'> {props.title || props.children}</span>
        
        {props.new && <i className='fa fa-circle text-teal-500 ml-auto' />}
        
    </NavLink>
}
export default SidebarLink