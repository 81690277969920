import React, { useState, useEffect } from 'react'
import FloatingWindow from '../Global/FloatingWindow'

const OrdenesStats = ({ ordenes, filteredOrdenes }) => {
    const tiposColores = { I: 'yellow', D: 'green', S: 'indigo', R: 'red', K: 'orange', U: 'purple', E: 'pink', }
    const estadosColores = { P: 'blue', A: 'pink', R: 'orange', S: 'gray', E: 'green', F: 'purple', C: 'red', K: 'teal', N: 'indigo', I: 'yellow', }
    let [tiposStats, setTiposStats] = useState({ I: 0, D: 0, S: 0, R: 0, K: 0, U: 0, E: 0 })
    let [estadosStats, setEstadosStats] = useState({ P: 0, A: 0, R: 0, S: 0, E: 0, F: 0, C: 0, K: 0, N: 0, I: 0 })

    useEffect(() => {
        let tmpTiposStats = {}
        let tmpEstadosStats = {}

        if (filteredOrdenes && tiposStats && estadosStats) {
            Object.keys(tiposStats).map(tipo => {
                tmpTiposStats = { ...tmpTiposStats, [tipo]: filteredOrdenes.filter((orden) => orden.ordentpo == tipo).length }
            })
            setTiposStats(tmpTiposStats)
            Object.keys(estadosStats).map(estado => {
                tmpEstadosStats = { ...tmpEstadosStats, [estado]: filteredOrdenes.filter((orden) => orden.ordensts == estado).length }
            })
            setEstadosStats(tmpEstadosStats)
        }

    }, [filteredOrdenes])


    return (
        <FloatingWindow title={`Total de ordenes en mapa ${Object.values(tiposStats).reduce((acc, act) => acc+act )}`}>
            <div className=" flex  items-bottom  overflow-hidden ">
                <Ruller />
                <Bars stats={estadosStats} colors={estadosColores}/>
                <Ruller />
                <Bars stats={tiposStats} colors={tiposColores}/>
            </div>
        </FloatingWindow>
    )
}
const Bars =({stats,colors}) =>{
    return <div className="flex  items-end  text-center ">
    {Object.entries(stats).map((tipo, index) =>
        <div key={'tipos-stat-' + index} className={` text-${colors[tipo[0]]}-400 flex flex-col`} title={tipo[1]}>
            <div style={{ height: `${(tipo[1])}px`, transition: '.2s' }}
                className={`rounded-sm mx-2  w-1 bg-${colors[tipo[0]]}-400 `}></div>
            <span style={{ fontSize: '14px' }}>{tipo[0]} </span>
            <span style={{ fontSize: '10px' }}>{tipo[1]}</span>
        </div>
    )}
</div>
}
const Ruller = () => {
    let rulerSize = []
    for (let i = 0; i < 21; i++) { rulerSize.push(i) }
    rulerSize.reverse()
    return <div className="   flex flex-col flex-wrap text-right justify-end items-end px-2" style={{ marginBottom: '30px' }}>
        {rulerSize.map((i) =>
            <div key={'ruler-' + i} className={` opacity-50 block `} style={{ height: `10px`, width: '10px', fontSize: '5px' }}> {(i * 10)} </div>)}
    </div>
}

export default OrdenesStats