import React from 'react'

export default ({ agendas, setFecha, setTurno, turno, fecha }) => {
    return <div className='flex items-center justify-between '>
        <h1>Agendas <small>{agendas && Object.entries(agendas).length + ' agendas'}</small></h1>
        <div>
            <select defaultValue={turno} className='text-sm mx-1 h-10 w-32 rounded p-2 ' onChange={(e) => setTurno(e.target.value)} >
                <option value="">Todos</option>
                <option value="1">Mañana</option>
                <option value="2">Tarde</option>
                <option value="3">Vespertino</option>
                <option value="4">Noche</option>
            </select>
            <input className='text-sm mx-1 rounded p-2 ' type='date' value={fecha} onChange={(e) => setFecha(e.target.value)} />
        </div>

    </div>
}
