import React, { useContext, useState } from 'react'
import useFetchPrefactibilidadLogs from '../../hooks/useFetchPrefactibilidadLogs'
import LogListItem from './LogListItem'
import ConfigContext from '../../contexts/ConfigContext'
import Padding from '../Layout/Padding'
import useExportLogs from '../../hooks/useExportLogs'

const TYPES = {
    'Inicio sesion': { color: 'green', icon: 'sign-in-alt' },
    'Cierre sesion': { color: 'red',    icon: 'sign-out-alt' },
}

const PrefactibilidadLogs = () => {
    // const {downloadData,downloadDataQuery} = useExportLogs()
    const context = useContext(ConfigContext)
    // const [tipo, setTipo] = useState('')
    const [ refresh, setRefresh ] = useState(false)
    const { data, fetchData, setDate, date } = useFetchPrefactibilidadLogs(refresh, setRefresh, context)
    // const [query, setQuery] = useState('')

    // const handleTipo = e => setTipo(e.target.value)

    // const handleChangeQuery = e => {
    //     setQuery(e.target.value)
    // }

    // const handleClickExport = () => {
    //     downloadData(date,query,'session','Sesiones')
    // }

    if(context.empresa == "tvf") return <div className='w-full h-screen flex flex-col items-center justify-center'>
        <p className=''>Solo disponible en Ushuaia</p>
        <button onClick={()=> context.setEmpresa('uv')}>Cambiar a Ushuaia</button>
    </div>

    return (
        <Padding>
            <div className='flex items-center justify-between'>
            <h1>Pre-Factibilidad Logs</h1>
                {/* <input onChange={handleChangeQuery} placeholder='Buscar ...'/> */}
                {/* {date && <button onClick={handleClickExport}><i className='fa fa-file-csv'/> Exportar</button>} */}
            </div>
            {/* <div className='grid grid-cols-2 gap-3'>
                <input type='date' value={date} onChange={e => setDate(e.target.value)} /> */}
                {/* <button className='w-1/3 mx-2' disabled={!date} onClick={() => getListLogs()}><i className='fa fa-search' /></button> */}
                {/* <select onChange={handleTipo} >
                    <option value=''>TODOS</option>
                    { Object.keys(TYPES).map( type => <option value={type}>{type}</option> )}
                  
                </select> */}
            {/* </div> */}
            {data && <button onClick={()=> setRefresh(true)}>{!refresh ? "Actualizar" : "Cargando..."}</button>}
            <table className='mt-4 w-full text-xs'>
                <tbody>
                    <tr>
                        <td>
                        <p style={{ color:'#38b2ac'}}>#</p>
                        </td>
                        <td className='w-1/6 text-lg'>
                            <p style={{ color:'#38b2ac'}}>Usuario</p>
                        </td>
                        <td className='w-1/4 text-lg'>
                            <p style={{ color:'#38b2ac'}}>Dirección</p>
                        </td>
                        <td className='w-1/2 text-lg'>
                            <p style={{ color:'#38b2ac'}}>Resultado</p>
                        </td>
                        <td className='w-1/4 text-lg'>
                            <p style={{ color:'#38b2ac'}}>Fecha de búsqueda</p>
                        </td>
                    </tr>
                    {data 
                        ? data.map((d,i) => (
                            <tr key={i}>
                                <td>{data.length - i}</td>
                                <td className='w-1/6'>{d.user}</td>
                                <td className='flex flex-col w-1/4' >
                                    <p className='w-40'>{d.query}</p>
                                    <small className='opacity-50'>{d.empresa}</small>
                                </td>
                                <td>{d.results.length > 0 && d.results.map((res,index) => (
                                    <div key={index} className='flex w-full flex-col'>
                                        <p>{res.geocalle}</p>
                                        <p className={res.habilitado === "APTO" ? "text-green-500 font-bold" : "text-red-500 font-bold"}>{res.habilitado}</p>
                                        <p>{res.ultima_factura}</p>
                                        {(d.results.length > 1 && index < d.results.length-1) && <div className="flex w-64 bg-gray-700 mt-1 mb-1" style={{ height: "1px", backgroundColor: "#38b2ac" }} />}
                                    </div>
                                ))}</td>
                                <td className='w-1/4'><span>{d.created_at}</span></td>
                            </tr>
                        ))
                        : null
                    }
                    </tbody>
                {/* <tbody>
                    {data && data.filter(log => log.includes(tipo) &&  log.includes(query)).reverse().map((log,index) => <LogListItem key={index} log={log} TYPES={TYPES} />)}
                </tbody> */}
            </table>
        </Padding>
    )
}


export default PrefactibilidadLogs