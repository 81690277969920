import React from 'react';
import moment from 'moment'

const Ts = ({ts,color}) => {
    // const momentEs = require('moment/locale/es')

    return <>
        <strong className=" block w-full "> <i className={`fa fa-clock opacity-75 mb-1 mr-1`}></i>
            {moment(ts).format('h:mm')}
        </strong>
        {moment(ts).fromNow()}
    </>
};

export default Ts