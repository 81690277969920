import { useContext, useEffect, useState } from 'react'

import ConfigContext from '../contexts/ConfigContext'
import moment from 'moment'

const useFetchSessions = (props) => {
    const context = useContext(ConfigContext)
    const [data, setData] = useState()
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/logs/session/${date}`, { method: 'GET', headers: {'Authorization': process.env.REACT_APP_BRIDGE_API_KEY}})
            const responseJSON = await response.json()
            setData(responseJSON)
            console.log(responseJSON)
        } catch (e) {
            console.log('No hay registros')
            setData([])
        }
    }

    useEffect(() => {
        date && fetchData()
    }, [date])

    return { data, fetchData, setDate, date }
}

export default useFetchSessions
