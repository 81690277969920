import React, { useContext, useState, useRef, useEffect } from 'react';

import ConfigContext from '../../contexts/ConfigContext';
import EntityBadge from '../../components/Badges/EntityBadge'
import Padding from '../../components/Layout/Padding'
import useFetch from '../../hooks/useFetch'
import moment from 'moment';

const AgendamientoIndex = ({ history }) => {

    const context = useContext(ConfigContext)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const [asignaciones, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendamiento/asignaciones/${date}`)
    const [tecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendamiento/moviles`)
    const [moviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles`)
    const [ordenes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/ordenes/fecha/${date}`)
    
    const selectMovil = React.useRef()
    const selectOrdenes = React.useRef()

    const [alreadyAssigned, setAlreadyAssigned] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [showFormAgendamiento, setShowFormAgendamiento] = useState(true)


    const [form, setForm] = useState({ movil_id: null, ordenes: null, assigned_at: null })
    useEffect(() => {
        date && setForm({ ...form, assigned_at: date })

    }, [date])
    const handleAsignarTecnicos = () => { history.push('/agendamiento/moviles') }
    const handleAsignarOrdenes = () => { setShowFormAgendamiento(!showFormAgendamiento) }
    const handleChangeMovil = (e) => setForm({ ...form, movil_id: e.target.value })
    const handleMultiple = (e) => {
        let el = e.target
        let selectedValues = []
        for (let i = 0, l = el.options.length; i < l; i++) {
            if (el.options[i].selected) {
                selectedValues.push(el.options[i].value)
            }
        }
        setForm({ ...form, ordenes: [...selectedValues] })
        console.log(selectOrdenes.current.selectedIndex)

    }
    const handleSetEditMode = () => setEditMode(!editMode)
    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/agendamiento/orden`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        refreshData()
        selectMovil.current.selectedIndex = -1
        selectOrdenes.current.selectedIndex = -1
    }
    const handleDelete = async (id) => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/agendamiento/asignacion/${id}`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'DELETE', headers })
        refreshData()
        setForm({ movil_id: null, ordenes: null })
    }
    const handleSetDate = e => {
        setDate(e.target.value)
    }

    React.useEffect(() => {
        asignaciones && setAlreadyAssigned(Object.values(asignaciones).flat(2).map(asignacion => asignacion.orden_nro))
    }, [asignaciones])

    return (
        <Padding>
            <h1>Agendamientos</h1>
            <div className='grid grid-cols-4 gap-4 mb-4'>
                <input type='date' onChange={handleSetDate} value={date} />
                <button onClick={handleSetEditMode} ><i className='fa fa-edit mr-2' /> {!editMode ? 'Editar' : 'Terminar'}</button>
                <button onClick={handleAsignarOrdenes}><i className='fa fa-clipboard mr-2' /> Asignar Órdenes</button>
                <button onClick={handleAsignarTecnicos}><i className='fa fa-truck mr-2' /> Asignar Técnicos</button>
            </div>

            {showFormAgendamiento &&
                <div className='grid grid-cols-3 gap-4'>
                    <select onChange={handleChangeMovil} size={moviles && (moviles.filter(movil => movil.nombre.includes('MOVIL')).length / 2)+1 } ref={selectMovil}>
                        {moviles && moviles
                            .filter(movil => movil.nombre.includes('MOVIL'))
                            .sort((a, b) => { if (a.nombre < b.nombre) { return -1 }; return 1 })
                            .map((movil, index) =>
                                <option key={index} value={movil.movil_id} className='w-1/2  p-1 rounded text-sm  truncate float-left font-medium'>{movil.nombre}</option>)}
                    </select>

                    <select onInput={(e) => handleMultiple(e, 'test')} multiple ref={selectOrdenes} className=''>
                        {ordenes &&
                            [...new Map(ordenes.ordenes.map(ord => [ord['ordennro'], ord])).values()]
                                .filter(orden => orden.ordensts == 'E')
                                .filter(orden => !alreadyAssigned.includes(orden.ordennro))
                                .sort((a, b) => { if (a.ordennro < b.ordennro) { return -1 }; return 1 })
                                .map((orden, index) => <option key={index} value={orden.ordennro} className='float-left m-1 rounded font-mono font-medium'>{orden.ordennro}</option>)}
                    </select>
                    <button onClick={handleStore} disabled={!form.movil_id || !form.ordenes}><i className='fa fa-paper-plane mr-2 ' /> Asignar</button>
                </div>
            }
            {asignaciones && <table className='w-full mt-4'>
                <tbody>
                    {Object.entries(asignaciones).map(([movil, ordenes]) => {
                        const m = moviles && moviles.find(m => m.movil_id == movil)
                        return <tr key={movil}>
                            <td className='py-1 w-40 align-middle'>{m && <EntityBadge type='movil' name={m.nombre} />}</td>
                            <td className='py-1  align-middle '>
                                <div className='flex flex-wrap'>
                                    {ordenes && ordenes.map((orden, index) => <AsignacionOrden key={index} orden={orden} editMode={editMode} handleDelete={handleDelete} />)}
                                </div>
                            </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>}

        </Padding>
    )
}

export default AgendamientoIndex

const AsignacionOrden = ({ orden, editMode, handleDelete }) => {
    const statuses = {
        'emitida': 'teal',
        'cancelada': 'red',
        'cerrada': 'gray'
    }

    return <span className={`p-1 ${orden.estado.toLowerCase() == 'cerrada' && 'opacity-50'} border-2 m-1 text-${statuses[orden.estado.toLowerCase()]}-500 border-${statuses[orden.estado.toLowerCase()]}-400 font-medium text-xs rounded flex flex-col items-center hover:border-${statuses[orden.estado.toLowerCase()]}-400 ${editMode && 'cursor-pointer'} `}>
        <span className='font-mono font-bold font-sm'>{orden.orden_nro}</span>
        <small>{orden.estado}</small>
        {editMode && <small onClick={() => handleDelete(orden.id)} className='hover:text-red-500 pt-1'><i className='fa fa-times  mr-1 ' /> Borrar</small>}
    </span>

}