import React, { useState, useContext } from 'react'
import Cargando from '../Global/Cargando'
import Search from '../Global/Search'
import EntityBadge from '../Badges/EntityBadge'
import { useHistory, Link } from 'react-router-dom'
import IdBadge from './../Badges/IdBadge'
import Padding from './../Layout/Padding'
import useFetch from '../../hooks/useFetch'
import moment from 'moment'
import paletas from '../../consts/paletas'
import ActionButtons from '../Global/ActionButtons'
import ConfigContext from '../../contexts/ConfigContext'

const MovilShow = (props) => {
    const context = useContext(ConfigContext)
    const [nombre,setNombre] = useState()
    const [tecnico,setTecnico] = useState()
    const [tecnicoid,setTecnicoid] = useState()

    const { id } = props.match.params

    React.useEffect(() => {
        if (props.location.state){
            setNombre(props.location.state.state.nombre)
            setTecnico(props.location.state.state.tecnico)
            setTecnicoid(props.location.state.state.tecnicoid)
        } 
    },[props.location.state])

    const history = useHistory()
    const [stocks] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/stock/${id}`)
    const [search, setSearch] = useState('')
    const [select, setSelect] = useState('')
    const [link, setLink] = useState()
    const [openOrden, setOpenOrden] = useState(false)
    const handleClickLink = (url) => {
        setLink(url)
        setOpenOrden(!openOrden)
    }
    const handleClickTecnico = () => history.push(`/tecnicos/${tecnicoid}`)
    const ITEMS = {
        'CISCO': { color: 'pink', icon: 'cog' },
        'MTA': { color: 'indigo', icon: 'tv' },
        'HD': { color: 'gray', icon: 'tv' },
        'DECO': { color: 'gray', icon: 'tv' },
        'DIBOX': { color: 'gray', icon: 'tv' },
        'MODEM': { color: 'teal', icon: 'digital-tachograph' },
        'ONT': { color: 'yellow', icon: 'wifi' },
        'SMARTCARD': { color: 'pink', icon: 'id-card' },
        'TELEFO': { color: 'orange', icon: 'phone' },
    }

    const [csvData,setCSVDATA] = useState([])
    
    React.useEffect(() => {
         stocks && stocks
            .filter(stock => { return select !== '' ? stock.articulonombre === select && (stock.serie.includes(search)) : stock && (stock.serie.includes(search)) })
            .map((stock,index) => setCSVDATA(csvData => {
                return [...csvData,{ID: stock.articuloid, NOMBRE: stock.articulonombre, MAC: stock.mac, SERIE: stock.serie, FCHING: moment(stock.articulofching).format('DD-MM-YYYY'), STS: stock.articulosts }]
            }))
     },[stocks,search,select])

    return (
        <Padding>
            <div className='flex items-center justify-between'>
                <button onClick={() => history.goBack()} ><i className='fa fa-arrow-left mr-2' />Volver</button>
                <h1>{nombre && nombre}</h1>
                <div className='flex items-center justify-end gap-2'>
                    <button onClick={() => handleClickLink('ordenes')}> <i className={`fa fa-${openOrden === true ? 'times-circle' : 'clipboard-list'}`}/> {openOrden === true ? 'Ocultar Ordenes' : 'Ver Ordenes'}</button>
                    { csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`movil-stocks-${moment().format('DD-MM-YYYY')}`}/>}
                </div>
            </div>
           
            <div className='flex items-center justify-between mb-2'>
                <div>
                    <h3>Movil Id #:</h3>
                    {tecnico && <h3>Tecnico:</h3>}
                    {tecnicoid && <h3>Tecnico Id #:</h3>}
                    <h3>Ver Tecnico: </h3>
                </div>
                <div>
                    <h3>{id && id}</h3>
                    {tecnico && <h3>{tecnico}</h3>}
                    {tecnicoid && <h3>{tecnicoid}</h3>}
                    {!tecnico && !tecnicoid ? <h3>No hay tecnico asignado</h3> : <button onClick={handleClickTecnico}>Ver Tecnico</button>}
                </div>
            </div>
            {openOrden && <MovilAgendaOrden movilId={id} link={link}/>}
            <div className='grid grid-cols-2 gap-3 mt-2 mb-2'>
                <Search setSearch={setSearch} placeholder='Buscar por numero de serie..' />
                <select onChange={e => setSelect(e.target.value)}>
                    <option value=''>TODOS</option>
                    {stocks && [...new Set(Object.values(stocks).map(stock => stock.articulonombre))].sort().map((stock, index) => <option key={index} value={stock}>{stock}</option>)}
                </select>
            </div>
            <div>
                {/* <h3>Dispositivos disponibles</h3> */}
                <div className='grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-1 mb-3'>
                    {stocks && [...new Set(Object.values(stocks).map(stock => stock.articulonombre))].sort()
                        .map((stock, index) => {
                            const active  = Object.entries(ITEMS).find(([item, value]) => stock.includes(item)) ? Object.entries(ITEMS).find(([item, value]) => stock.includes(item)) [1] : {color:'gray',icon:'cog'}
                            return <div key={index} onClick={() => setSelect(stock)} className={`px-2 py-1 ${stock == select && 'opacity-100 border-'+active.color+'-500'} hover:opacity-100 opacity-75 cursor-pointer flex  border-2 border-transparent  justify-start items-center rounded  text-${active.color}-600 hover:border-${active.color}-400`} title={stock}>
                                <i className={`fa  fa-${active.icon} fa-fw text-lg mr-2  `} />
                                <small className='text-xs flex-1 truncate '>{stock}</small>
                                <strong className='ml-1'>{stocks.filter(s=>s.articulonombre == stock).length}</strong>
                            </div>
                        })
                    }

                </div>
            </div>
            {stocks ? <table className="w-full">
                <thead>
                    <tr>
                        <th >ID</th>
                        <th >Nombre</th>
                        <th >Mac</th>
                        <th >Serie</th>
                        <th >Fching</th>
                        <th >STS</th>
                    </tr>
                </thead>
                <tbody>
                    {stocks
                        .filter(stock => { return select !== '' ? stock.articulonombre === select && (stock.serie.includes(search)) : stock && (stock.serie.includes(search)) })
                        .map((stock, index) =>
                            <tr key={index}>
                                <td ><IdBadge id={stock.articuloid} /></td>
                                <td >{stock.articulonombre}</td>
                                <td >{stock.mac}</td>
                                <td className='font-semibold font-mono'>{stock.serie}</td>
                                <td >{moment(stock.articulofching).fromNow()}</td>
                                <td >{stock.articulosts}</td>
                            </tr>
                        )}
                </tbody>
            </table> : <Cargando />}
        </Padding>
    )
}

const MovilAgendaOrden = (props) => {
    const context = useContext(ConfigContext)
    const {movilId,link} = props

    const [data] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles/${movilId}/${link}`)

    return (
        <>
            {/* {data && <h3>Ordenes</h3>} */}
            {data ?
                <table className='text-sm w-full' >
                    <tbody>
                        {data.map((orden, index) =>
                            <tr key={orden + '_' + index}>
                                <td>
                                    <Link to={`/show/ordenes/${orden.ordennro}`}><IdBadge id={orden.ordennro} /></Link>
                                </td>
                                <td>
                                    <span className={` text-${paletas.tipos[orden.ordentpo].color}-400 flex flex-col items-center justify-center`}>
                                        <i className={` fa fa-fw fa-${paletas.tipos[orden.ordentpo].icon} mb-1`} />
                                        <span className='text-xxs'>{paletas.tipos[orden.ordentpo].title} </span>
                                    </span>
                                </td>
                                {/* <td><EntityBadge type='cliente' name={orden.centrostknombre} detail={orden.clientenro} /></td> */}
                                <td className='w-1/3 opacity-75 text-xs'>{orden.ordenobs}</td>
                                <td className='py-1  items-center'>
                                    <span className={`text-xxs inline-flex items-center mr-1 badge rounded-full bg-${paletas.estados[orden.ordensts].color}-400`}>
                                        <i className={`fa fa-fw fa-${paletas.estados[orden.ordensts].icon} mr-1`} />
                                        {paletas.estados[orden.ordensts].title}
                                    </span>
                                </td>
                                <td className='text-right'>
                                    <Link to={`/show/ordenes/${orden.ordennro}`}>
                                        <button><i className={`fa fa-fw fa-angle-right`} /></button>
                                    </Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
			: link && <Cargando />}
            {data && data.length == 0 && <h3 className='justify-center flex'>No hay ordenes asignadas</h3>}
        </>
    )
}

export default MovilShow