import {useContext, useState} from 'react'

import Axios from 'axios'
import ConfigContext from '../../contexts/ConfigContext'
import {useHistory} from 'react-router-dom'

const useStoreUpdate = (name,path,modeUpdate) => {
    const context = useContext(ConfigContext)
    const history = useHistory()
    const [form, setForm] = useState()

    const dataSend = (id) => {
        if (modeUpdate === true){
            Axios.post(`${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/${name}/${id}`,form,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
            .then(() => {
                setForm({})
                history.push(`${path}`)
            })
        }else{
            Axios.post(`${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/${name}`,form,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
            .then(() => {
                setForm({})
                history.push(`${path}`)
            })
        }

    }

    return {form,setForm,dataSend}
}

export default useStoreUpdate