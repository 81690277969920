import React, { useContext, useEffect, useState } from 'react';

import CircleMarker from '../Markers/CircleMarker'
import ConfigContext from './../../contexts/ConfigContext';
import FloatingWindow from './../Global/FloatingWindow'
import MainMap from '../MainMap'
import NextAgendaDetails from './NextAgendaDetails'
import NextAgendaDistancias from './NextAgendaDistancias'
import NextAgendaFilter from './NextAgendaFilter'
import axios from 'axios';
import paletas from './../../consts/paletas'
import useEnv from './../../hooks/useEnv'

const NextAgenda = () => {
    let context = useContext(ConfigContext)
    const primaryColor = useEnv('COLOR')
    let [agendaNro, setAgendaNro] = useState()
    let [nextAgenda, setNextAgenda] = useState(null)
    let [loading, setLoading] = useState()
    let [directions, setDirections] = useState(null)
    let [filterTurno, setFilterTurno] = useState('')
    let [hoveredAgenda, setHoveredAgenda] = useState()
    let [agendas, setAgendas] = useState()

    useEffect(()=>{
        if(hoveredAgenda)   {
            console.log('hoveredAgenda', hoveredAgenda)
        }
    },[hoveredAgenda])

    const giveMeNext = () =>{
        setLoading(true);
        setNextAgenda(null)
        axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/utils/nextagenda/${agendaNro}`,{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then( response => { 
                setNextAgenda(response.data);
                setLoading(false);
             })
            .catch(e=>console.log(e))
    }
    useEffect(()=>{
       nextAgenda && getDirections()
    },[nextAgenda])

    useEffect(()=>getAgendas(),[])
    const getAgendas = () => {
        axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/agendas/simple/E`,{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then( response => { setAgendas(response.data); })
            .catch(e=>console.log(e))
    }

    const getDirections = () => {
        let data = {
            origin_lat : nextAgenda.in.datos.current_lat,
            origin_lng : nextAgenda.in.datos.current_lng,
            destination_lat : nextAgenda.out.datos.agenda_lat,
            destination_lng : nextAgenda.out.datos.agenda_lng,
        }
        axios.post(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/utils/directions`,data,{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then( response => setDirections(response.data))
    }

    

    return <div>
            <FloatingWindow pos="mt-16" icon='arrow-right' title='Buscar' padding>
                    <div className="grid grid-cols-3 gap-2 items-center">
                        <input  className='input-lg' value={agendaNro} onChange={(e) => setAgendaNro(e.target.value)} placeholder="agendanro" onKeyPress={(e) => { if (e.which == 13 || e.keyCode == 13 || e.key === 'Enter') { setAgendaNro(e.target.value);  giveMeNext() ;}}}  />
                        <button  onClick={ giveMeNext } className='input-lg'>Buscar próxima</button> 
                        {loading ?'Buscando...' : nextAgenda && <input className='input-lg' value={nextAgenda && nextAgenda.out.agendanro}   /> }
                    </div>
            </FloatingWindow>
        {nextAgenda &&  <FloatingWindow pos="bottom-0  ">
                            <NextAgendaDistancias setHoveredAgenda={setHoveredAgenda} nextAgenda={nextAgenda}/> 
                        </FloatingWindow>}
            <MainMap 
                filters={nextAgenda && agendas && <NextAgendaFilter setFilterTurno={setFilterTurno} agendas={agendas}/>}
                details={nextAgenda && <FloatingWindow pos="left-0 bottom-0 mb-24"><NextAgendaDetails color={primaryColor} nextAgenda={nextAgenda.in}/></FloatingWindow>}
                forms={nextAgenda   && <FloatingWindow pos="right-0 bottom-0 mb-24"> <NextAgendaDetails color='red' nextAgenda={nextAgenda.out}/></FloatingWindow>}
                 directions={directions} directionsColor={nextAgenda && paletas.turnos[nextAgenda.in.datos.agenda_turnoid].color_hex} recenter={nextAgenda && { lat: parseFloat(nextAgenda.in.datos.current_lat), lng: parseFloat(nextAgenda.in.datos.current_lng) }}>
                { nextAgenda && agendas && agendas.filter(agenda=> agenda.agenda_turnoid.includes(filterTurno)).map(agenda=> (agenda.agenda_lat != ' ') && 
                    <CircleMarker 
                        key={agenda.agenda_nro} 
                        size={  agenda.agenda_nro == hoveredAgenda ? 24 : paletas.turnos[agenda.agenda_turnoid].size } 
                        color={ agenda.agenda_nro == hoveredAgenda ? `${paletas.turnos[agenda.agenda_turnoid].color}-400 border-3` : paletas.turnos[agenda.agenda_turnoid].color } 
                        label={ agenda.agenda_nro == hoveredAgenda ? hoveredAgenda: ' ' } 
                        lat={agenda.agenda_lat} 
                        lng={agenda.agenda_lng}
                    /> 
                )}
                { nextAgenda && <CircleMarker dir='in' color={primaryColor} label="1" lat={nextAgenda.in.datos.current_lat} lng={nextAgenda.in.datos.current_lng} /> }
                { nextAgenda && <CircleMarker dir='out' color='red' label="2" lat={nextAgenda.out.datos.agenda_lat} lng={nextAgenda.out.datos.agenda_lng} /> }
            </MainMap>
      
    </div>
};

export default NextAgenda




