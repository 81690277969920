import React from 'react'
import paletas from '../../consts/paletas'

const RankingProgress = ({progress,ranking}) => {

    let escaling = (cantidad) => {
        return Math.round((cantidad * 10) / ranking[0].finalizadas)
    }
    return <div className='ranking-progress hover:shadow-outline overflow-hidden rounded-full h-6 px-x w-full  flex items-center items-start'>
            <span className={` text-${paletas.escala[escaling(progress)].color}-400 pr-2 w-5 text-right text-xxs font-bold m-2`}>{progress}</span>
            <div style={{ width: progress + 'px' }} className={`bg-${paletas.escala[escaling(progress)].color}-400 h-2 rounded-full`}></div>
        </div>
}

export default RankingProgress