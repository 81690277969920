import React from 'react';

export default ({unit, _onClick}) => {
    return <div 
    onClick={_onClick} 
    className={`hover:text-green-500 hover:border-green-500 border-2 border-black hover:shadow-lg hover:bg-black cursor-pointer w-8 h-8 
     rounded-full bg-gray-900 text-gray-300 flex items-center justify-center ${unit.disponible === 0 && 'text-green-500 border-green-500  '}`}>
    <i className={`fa   fa-${unit.disponible === 0?'play':'pause'} text-xxs`}></i>
</div>
};
