import React, {useContext} from 'react';
import moment from 'moment'
import useFetch from '../../hooks/useFetch';
import Cargando from '../Global/Cargando';
import ConfigContext from '../../contexts/ConfigContext'

const DashboardMensajes = () => {
    const context = useContext(ConfigContext)
    const [mensajes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/mensajes`)

    return  <div className='dashboard-card'>
    <h4 className='pb-2  opacity-50'><i className='fa fa-comment-alt fa-fw text-teal-500 mr-2 '/>Mensajes</h4>
    {mensajes?mensajes
        .map(mensaje => (
            <article key={mensaje.id} className='  mb-3  border-b border-teal-400'>
                <small className='mr-5 text-teal-500'>{moment(mensaje.fecha).format('D/M/Y')} {mensaje.horario && mensaje.horario.slice(0, 5)}hs</small>
                <h5 className='text-xl font-semibold'>{mensaje.titulo}</h5>
                <p className='opacity-75 text-sm'>{mensaje.contenido}</p>
                <div className='flex items-center justify-between text-xs py-3 text-teal-500'>
                    { mensaje.sector && <span className='text-purple-500'>Sector: <strong>{mensaje.sector}</strong></span>}
                    { mensaje.nodo && <span className='text-yellow-500'>Nodo: <strong>{mensaje.nodo.replace('Nodo_','')}</strong></span>}
                </div>
            </article>
        )) : <Cargando />
    }
</div>
}
export default DashboardMensajes