import React, { useContext, useEffect, useState } from 'react'

import AgendasDetails from './AgendasDetails'
import AgendasHeader from './AgendasHeader'
import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext'
import Details from '../Global/Details'
import EntityBadge from '../Badges/EntityBadge'
import IdBadge from '../Badges/IdBadge'
import { Link } from 'react-router-dom'
import Modal from '../../fragments/Modal'
import Padding from './../Layout/Padding'
import axios from 'axios'
import moment from 'moment'
import paletas from '../../consts/paletas'
import useFetch from '../../hooks/useFetch'
import TurnoBadge from '../Badges/TurnoBadge'
import SigaButton from '../Global/SigaButton'

const AgendasList = params => {
	let [agendas, setAgendas] = useState(null)
	let [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'))
	let [turno, setTurno] = useState(1)

	let [fetchOrden, setFetchOrden] = useState(false)
	let [openModal, setOpenModal] = useState()

	let context = useContext(ConfigContext)
	useEffect(() => fetchAgendas(), [context.empresa, fecha, turno])

	const [fetchedTecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos`)
	
	const fetchAgendas = () => {
		axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/agendas/fecha/${fecha}${turno ? '/' + turno : ''}`, {headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
			.then(response => setAgendas(response.data))
			.catch(e => console.log(e))
	}

	const openOrden = (nro) => {
		setOpenModal(true)
		axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/ordenes/nro/${nro}`, {headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
			.then(response => setFetchOrden(response.data))
			.catch(e => console.log(e))
	}
	const closeModal = () => {
		setOpenModal(false)
		setFetchOrden()
	}

	return (
		<Padding>
			{openModal && <Modal title='Orden' closeModal={closeModal} content={fetchOrden && <Details inline data={fetchOrden[0]} />} />}
			{agendas ?
				<>
					<AgendasHeader agendas={agendas} setTurno={setTurno} setFecha={setFecha} turno={turno} fecha={fecha} />
					{/* <AgendaStats agendas={agendas} />  */}
					<table className='text-sm w-full   ' >
						<tbody>
							{Object.entries(agendas).map((agenda, index) => {
								const tecni = fetchedTecnicos && fetchedTecnicos.find(tecnico => tecnico.id == agenda[1][0].agenda_tecnico)
								return (
									<tr key={agenda[0] + '_' + index}>
										<td><Link to={`/show/agendas/${agenda[0]}`}><EntityBadge name={agenda[0]}  type='agenda'/></Link></td>
										{/* <td><SigaButton tipo='agenda' nro={agenda[0]} /></td> */}
										<td>{tecni && <EntityBadge type='tecnico' name={tecni.nombre} size='xs' />}</td>
										<td><EntityBadge type='cliente' size='xs' name={agenda[1][0].cliente_nombre} detail={agenda[1][0].agenda_regnro} /></td>
										<td className={` items-center text-xs text-${paletas.productoTipos[agenda[1][0].producto_tpo] && paletas.productoTipos[agenda[1][0].producto_tpo].color}-500 `} >
											<i className={`fa fa-fw mr-1 fa-${paletas.productoTipos[agenda[1][0].producto_tpo] && paletas.productoTipos[agenda[1][0].producto_tpo].icon}`}></i> {agenda[1][0].orden_contrato_producto && agenda[1][0].orden_contrato_producto.toLowerCase()}
										</td>
										<td className='flex align-top' > <AgendasDetails agenda={agenda[1]} openOrden={openOrden} /> </td>
										<td>
											<TurnoBadge turno={agenda[1][0].agenda_turno}/>
										</td>
									</tr>
								)
							}
							)}
						</tbody>
					</table>
				</>
				: <Cargando full size='xl' />}
		</Padding>
	)
}

export default AgendasList
