import AgendasList from '../components/Agendas/AgendasList'
import AgendasMap from '../components/Agendas/AgendasMap'
import AgendasSearch from '../components/Agendas/AgendasSearch';
import AgendasRedes from '../components/Agendas/AgendasRedes';
import AgendaRedesOrdenes from '../components/Agendas/AgendaRedesOrdenes';
import ClienteMap from '../components/Clientes/ClienteMap'
import ClienteSearch from '../components/Clientes/ClienteSearch';
import ClienteShow from '../components/Clientes/ClienteShow'
//configuracion
import Configuracion from '../components/Configuracion/Configuracion'

import EnlacesConfiguracion from '../components/Configuracion/EnlacesConfiguracion';
import EnlacesIndex from '../components/EnlaceView/EnlacesIndex'
import FechasConfiguracion from '../components/Configuracion/FechasConfiguracion';
import InformesConfiguracion from '../components/Configuracion/InformesConfiguracion';
import InformesIndex from '../components/Informes/Index'
import LogsIndex from '../components/LogView/LogsIndex'
import SesionesIndex from '../components/LogView/SesionesIndex.js'
//Map
import MapScreen from '../components/MapView/MapScreen'
import MapsScreen from '../Pages/MapsScreen'
import MensajesConfiguracion from '../components/Configuracion/MensajesConfiguracion';
import RolesConfiguracion from '../components/Configuracion/RolesConfiguracion';
import LayersConfiguracion from '../components/Configuracion/LayersConfiguracion';
import MonitoreoIndex from '../components/Monitoreo/MonitoreoIndex';
import MovilShow from '../components/Moviles/MovilShow'
//moviles
import MovilesIndex from '../components/Moviles/MovilesIndex'
import NextAgenda from '../components/NextAgenda/NextAgenda'
import NotificacionesScreen from '../components/Notificaciones/NotificacionesScreen';
// FORBIDDEN PAGES
import OopsScreen from '../Pages/OopsScreen'
import OrdenesList from '../components/Ordenes/OrdenesList'
import OrdenesMap from '../components/Ordenes/OrdenesMap'
import OrdenesSearch from '../components/Ordenes/OrdenesSearch';
import PbxScreen from '../screens/pbx/PbxScreen'
import PbxAgentes from '../screens/pbx/PbxAgentes'
import RankingIndex from '../components/Ranking/RankingIndex'
import Show from '../fragments/Show';
import TecnicoShow from '../components/Tecnicos/TecnicoShow'
//tecnicos
import TecnicosIndex from '../components/Tecnicos/TecnicosIndex'
// import MobileAppIndex from './../components/MobileApp/MobileAppIndex';
import UnidadesIndex from '../components/Unidades/UnidadesIndex';
import UsuariosConfiguracion from '../components/Configuracion/UsuariosConfiguracion';
import ViewStats from '../components/Views/Stats'
import AgendamientoIndex from '../screens/Agendamiento/Index'
import AgendamientoMoviles from '../screens/Agendamiento/Moviles'
import MorosidadMap from '../screens/Geojson/MorosidadMap'
import ServiciosMap from '../screens/Geojson/ServiciosMap'
import DashboardScreen from '../Pages/DashboardScreen'

const routes = [
    { path: '/',                                          component: DashboardScreen,                 roles: ['consultas','operador','tecnico','dev','guest'] },
    { path: '/show/:entity/:nro',                         component: Show,                            roles: ['consultas','operador','dev'] },
    { path: '/clientes/map',                              component: ClienteMap,                      roles: ['consultas','operador','dev'] },
    { path: '/clientes/list',                             component: ClienteMap,                      roles: ['consultas','operador','dev'] },
    { path: '/clientes/search',                           component: ClienteSearch,                   roles: ['consultas','operador','dev','guest'] },
    { path: '/clientes/show/:nro',                        component: ClienteShow,                     roles: ['consultas','operador','dev'] },
    { path: '/ordenes/map',                               component: OrdenesMap,                      roles: ['consultas','operador','dev'] },
    { path: '/ordenes/list',                              component: OrdenesList,                     roles: ['consultas','operador','dev'] },
    { path: '/ordenes/search',                            component: OrdenesSearch,                   roles: ['consultas','operador','dev'] },
    { path: '/agendas/map',                               component: AgendasMap,                      roles: ['consultas','operador','dev'] },
    { path: '/agendas/list',                              component: AgendasList,                     roles: ['consultas','operador','dev'] },
    { path: '/agendas/redes',                             component: AgendasRedes,                    roles: ['consultas','operador','dev'] },
    { path: '/agendas/redes/ordenes',                     component: AgendaRedesOrdenes,              roles: ['consultas','operador','dev'] },
    { path: '/agendas/search',                            component: AgendasSearch,                   roles: ['consultas','operador','dev'] },

    { path: '/pbx',                                       component: PbxScreen,                       roles: ['dev']},
    { path: '/pbx/agentes',                               component: PbxAgentes,                      roles: ['dev']},
    { path: '/informes',                                  component: InformesIndex,                   roles: ['consultas','operador','tecnico','dev'] },
    { path: '/stats',                                     component: ViewStats,                       roles: ['operador','dev'] },
    // { path: '/mobile',                                    component: MobileAppIndex,                  roles: ['tecnico'] },
    { path: '/unidades',                                  component: UnidadesIndex,                   roles: ['operador','dev'] },
    { path: '/next',                                      component: NextAgenda,                      roles: ['operador','dev'] },
    { path: '/ranking',                                   component: RankingIndex,                    roles: ['dev'] },
    { path: '/centros',                                   component: RankingIndex,                    roles: ['dev'] },
    { path: '/monitoreo',                                 component: MonitoreoIndex,                  roles: ['consultas','operador','dev'] },
    { path: '/tecnicos',                                  component: TecnicosIndex,                   roles: ['dev'] },
    { path: '/tecnicos/:id',                              component: TecnicoShow,                     roles: ['dev'] },
    { path: '/moviles',                                   component: MovilesIndex,                    roles: ['dev'] },
    { path: '/moviles/:id',                               component: MovilShow,                       roles: ['dev'] },
    
    { path: '/agendamiento',                              component: AgendamientoIndex,               roles: ['dev'] },
    { path: '/agendamiento/moviles',                      component: AgendamientoMoviles,             roles: ['dev'] },
    
    { path: '/map',                                       component: MapScreen,                       roles: ['consultas','dev','guest'] },
    { path: '/maps',                                      component: MapsScreen,                      roles: ['dev'] },
    { path: '/morosidad',                                 component: MorosidadMap,                    roles: ['dev'] },
    { path: '/servicios',                                 component: ServiciosMap,                    roles: ['dev'] },
    
    //configuracion 
    { path: '/configuracion',                             component: Configuracion,                   roles: ['dev']},
    { path: '/configuracion/informes',                    component: InformesConfiguracion,           roles: ['dev']},
    { path: '/configuracion/mensajes',                    component: MensajesConfiguracion,           roles: ['dev']},
    { path: '/configuracion/roles',                       component: RolesConfiguracion,              roles: ['dev']},
    { path: '/configuracion/layers',                      component: LayersConfiguracion,             roles: ['dev']},
    { path: '/configuracion/fechas',                      component: FechasConfiguracion,             roles: ['dev']},
    { path: '/configuracion/enlaces',                     component: EnlacesConfiguracion,            roles: ['dev']},
    { path: '/configuracion/usuarios',                    component: UsuariosConfiguracion,           roles: ['dev']},

    { path: '/notificaciones',                            component: NotificacionesScreen,            roles: ['dev']},

    // logs
    { path: '/logs',                                      component: LogsIndex,                       roles: ['dev']},
    { path: '/sesiones',                                  component: SesionesIndex,                   roles: ['dev']},

    // enlaces
    { path: '/enlaces',                                   component: EnlacesIndex,                    roles: ['dev']},

    // Pagina sin url valida
    { path: '*',                                          component: OopsScreen,                      roles: ['consultas','operador','tecnico','dev']},
]

export default routes

