import {useState,useEffect} from 'react'
import Axios from 'axios'

const useListBarrios = () => {
    const [listBarrios, setListBarrios] = useState()

    const getList = () => {
        Axios.get(`https://www.tvfuego.com.ar/api/barrios`, {headers: {Authorization: 'CIVQCLPjQkwvvBpK9dWYhZPgozEMcuR3B2nFpJ4fdo'}})
            .then(response => {
                setListBarrios(response.data)
            })
    }

    useEffect(() => getList() ,[])

    return {listBarrios,getList}
}

export default useListBarrios
