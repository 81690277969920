import React from 'react';
const EntityBadge = ({ type='user', name, detail, size='sm'}) => {
    const TYPES = {
        'movil' : { icon : 'truck', color: 'teal', shape: 'rounded-l rounded-r-full' },
        'tecnico' : { icon : 'user-cog', color: 'indigo', shape: 'rounded-lg' },
        'cliente' : { icon : 'user-tie', color: 'blue', shape: 'rounded-lg' },
        'user' : { icon : 'user', color: 'yellow', shape: 'rounded-xs' },
        'nodo' : { icon : 'bullseye', color: 'green', shape: 'rounded' },
        'agenda' : { icon : 'clipboard', color: 'purple', shape: 'rounded-sm' },
        'orden' : { icon : 'file', color: 'red', shape: 'rounded' },
        'agente' : { icon : 'headphones', color: 'indigo', shape: '' },
    }
    return <span className={`inline-flex items-center justify-start  text-${size} border-2 border-${TYPES[type].color}-300 text-${TYPES[type].color}-500 ${TYPES[type].shape} px-2 py-1 mx-1 flex items-center justify-center `}>
            <i className={`fa fa-${TYPES[type].icon} mr-2`}/>
            <span className={`flex flex-col flex-1 leading-tight font-medium`}>
                <span className=' '>{name}</span>
                <span className='opacity-75 '>{detail}</span>
            </span>

    </span>
}
export default EntityBadge