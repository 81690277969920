import { useState, useEffect } from 'react';
import Axios from 'axios';

const useIsConnectedToSiga = () => {

    let [status, setStatus] = useState(0)
    let statuses = {
        0 : 'Idle',
        1 : 'Conectando...',
        2 : 'Conectado',
        3 : 'Error',
    }

    useEffect(() => {
        setStatus(1)
        checkIfIsConnected()
        const fetchInterval = setInterval(() => checkIfIsConnected(), process.env.REACT_APP_INTERVAL_CHECK_CONNECTION || 20000)
        return () => clearInterval(fetchInterval)
    }, [])

    const checkIfIsConnected = () => {
        Axios.get('http://maps-api-server.test/api/tvf/prod/checkStatus/siga')
            .then(response => 
                (response.status === 200) ? setStatus(2) : setStatus(3)
            )
            .catch(e => console.log(e))
    }

    return {status,statuses}
};

export default useIsConnectedToSiga