import React, { useContext, useEffect, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding'
import moment from 'moment'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import {useHistory} from 'react-router-dom'

const FechasConfiguracion = (props) => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/fechas`)
    
    const [ deleteItem ] = useDelete('fechas', '/configuracion/fechas')
    const [openForm, setOpenForm] = useState(false)
    const [modeUpdate, setModeUpdate] = useState(false)
    const [updateId, setUpdateId] = useState()
    const [form, setForm] = useState()

    const handleNueva = () => {
        setModeUpdate(false)
        setOpenForm(!openForm)
        setForm({...form,  mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
    }
    const handleChangeEvento = (e) => setForm({ ...form, evento: e.target.value })
    const handleChangeFecha = (e) => setForm({ ...form, fecha: e.target.value })
    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/fechas`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleUpdate = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/fechas/${updateId}`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleClickEditar = (id,evento,fecha) => {
        setOpenForm(!openForm) 
        setModeUpdate(true)
        setUpdateId(id)
        setForm({...form, _method:'put', evento:evento, fecha:fecha, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
    }

    const handleClickVolver = () => {
        history.goBack()
    }
    
    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Fechas</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        {openForm &&
                            <tr>
                                <td className='py-3'> <input onChange={handleChangeEvento} defaultValue={modeUpdate === true ? form.evento : ''} placeholder='evento' /> </td>
                                <td className='py-3'><input onChange={handleChangeFecha} defaultValue={modeUpdate === true ? form.fecha && form.fecha.toString().substr(0,10) : ''} type='date' /></td>
                                
                                <td className='text-right'> <button onClick={modeUpdate === true ? handleUpdate : handleStore}><i className={`fa fa-${modeUpdate === true ? 'save' : 'paper-plane'} mr-2 `} /> {modeUpdate === true ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        
                        {data.map(item => <tr key={item.id}>
                            <td className='text-lg text-gray-600'>{item.evento && item.evento}</td>
                            <td className='text-sm text-blue-600'>{item.fecha && moment(item.fecha).format('DD-MM-YYYY')}</td>
                            <td className='text-right'>
                                <button onClick={() => handleClickEditar(item.id,item.evento,item.fecha)} > <i className='fa fa-edit'/> Editar</button>
                                <button onClick={() => deleteItem(refreshData, item.evento ,item.id)}> <i className='fa fa-trash'/> Eliminar</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default FechasConfiguracion
