import React, { useContext, useEffect, useState } from 'react';

import AgendaMarker from '../Markers/AgendaMarker';
import Axios from 'axios';
import ConfigContext from '../../contexts/ConfigContext';
import FloatingWindow from '../Global/FloatingWindow';
import MainMap from './../MainMap'
import moment from 'moment';
import paletas from '../../consts/paletas';

export default () => {
    let context = useContext(ConfigContext)
    let [agendas, setAgendas] = useState()
    let [turnos, setTurnos] = useState([])

    let [ fecha, setFecha] = useState(moment().format('YYYY-MM-DD'))
    let [ turno , setTurno ] = useState()
    let [ sts , setSts] = useState()


    const fetchAgendas = () => {
        Axios.post(`${process.env.REACT_APP_BRIDGE_API_KEY}/${context.empresa}/${context.env}/cuo/agendas/query`,{ fecha },{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then(response => setAgendas(response.data))
            .catch(e => console.log(e))
    }

    useEffect(() => fetchAgendas() , [,fecha,context.empresa])
    useEffect(() => agendas && setTurnos([...new Set(agendas.map( agenda => agenda.agenda_turnoid) )]), [agendas])
    return <MainMap 
        left={<FloatingWindow  icon='search' title='Fecha'>
                    <input value={fecha} type='date' onChange={e=> setFecha(e.target.value) }/>
                </FloatingWindow>}
        bottom={ <FloatingWindow  icon='chart-bar' title='Capacidad por turno' >
                    { agendas && turnos && turnos.sort().map(turno => 
                            <div className='flex items-center text-xs font-semibold py-1'>
                                <div className={`text-${paletas.turnos[turno].color}-500 w-20 text-right pr-2`}>{paletas.turnos[turno].label}</div> 
                                <div className='flex items-center justify-start'>
                                    {agendas.filter( a => a.agenda_sts === 'E' && a.agenda_turnoid === turno).map( a=> <figure className={`border border-transparent bg-${paletas.turnos[turno].color}-500 rounded-sm h-4 w-1 mx-px`}></figure>)}
                                    {agendas.filter( a => a.agenda_sts === 'C' && a.agenda_turnoid === turno).map( a=> <figure className='border border-green-600  rounded-sm h-4 w-1 mx-px'></figure>)}
                                    {agendas.filter( a => a.agenda_sts === 'T' && a.agenda_turnoid === turno).map( a=> <figure className='border border-gray-400  rounded-sm h-4 w-1 mx-px'></figure>)}
                                    {agendas.filter( a => a.agenda_sts === 'V' && a.agenda_turnoid === turno).map( a=> <figure className='border border-red-500  rounded-sm h-4 w-1 mx-px'></figure>)}
                                    {/* {agendas.filter( a => a.agenda_sts === 'A' && a.agenda_turnoid === turno).map( a=> <figure className='border border-yellow-600  rounded-sm h-4 w-1 mx-px'></figure>)} */}
                                </div>
                            </div>
                        ) }
                        <div className='mt-3  '>
                            {/* Legendas:  */}
                            <ul className=' flex items-center justify-around uppercase text-xxs'>
                                <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-white bg-white rounded-sm h-4 w-1 mx-px`}></figure> Emitida</li>
                                <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-green-600 rounded-sm h-4 w-1 mx-px`}></figure> Comenzada</li>
                                <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-gray-400 rounded-sm h-4 w-1 mx-px`}></figure> Terminada</li>
                                <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-red-500 rounded-sm h-4 w-1 mx-px`}></figure> Visitada</li>
                                {/* <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-yellow-600 rounded-sm h-4 w-1 mx-px`}></figure> Agendada</li> */}
                                {/* <li className='flex items-center truncate px-1 mx-2'><figure className={`mr-1  border border-gray-400 rounded-sm h-4 w-1 mx-px`}></figure> Cancelada</li> */}
                            </ul>
                        </div>
            </FloatingWindow>}
            >
        {agendas && agendas.filter( agenda => agenda.agenda_sts === 'E').map((agenda, index) => agenda.agenda_lat && 
            <AgendaMarker index={index} agenda={agenda} key={index} lat={agenda.agenda_lat} lng={agenda.agenda_lng} /> 
        )}
    </MainMap>
};
