import React from 'react';
const MovilMarker = ({ movil, $hover }) => {
    return (
        <div className="marker-movil relative  -ml-5 -mt-5 "
            style={{
                transition: '1s',
                width: '40px',
                height: '40px'
            }} >
            <div className={`bg-cover absolute inset-0 m-auto `}
                style={{
                    backgroundImage: `url("markers/blanco.png")`,
                    backgroundPosition: 'center center',
                    transform: `rotate(15deg)`,
                    opacity: '1',
                    height: '20px',
                    width: '40px'
                }} ></div>
            <div className={`rounded absolute p-px px-1 shadow truncate text-xxs font-bold text-white bg-gray-800 ${$hover ? ' opacity-100' : ' opacity-0 '} `}
                style={{
                    right: `${$hover ? '-80px' : '-65px'}`,
                    top: '9px',
                    transition: '.2s'
                }}
            >
                <span className="opacity-75 text-xxs">TVFUEGO - Movil 1</span> <br />
                <span className="font-mono tracking-wider xs">AD2133FV</span> <br />
            </div>
        </div>
    )
};

export default MovilMarker
