import React,{useEffect} from 'react';
import FloatingWindow from './FloatingWindow';
import Padding from '../Layout/Padding'
import { distanceTo } from 'geolocation-utils'

const AddressSearch = ({clickPos}) => {

  useEffect(() => {
    if(clickPos) {
      console.log(distanceTo({lat:clickPos.lat, lng: clickPos.lng}, {lat:-53,lng:-64}))   
    }
    return () => {
    };
  }, [clickPos])


  return (<FloatingWindow pos="mt-16">
    <Padding>
      Search <br />
      lat {clickPos ? clickPos.lat : '0'} <br />
      lng {clickPos ? clickPos.lng : '0'}
    </Padding>
  </FloatingWindow>)

}

export default AddressSearch