
import React, { useState } from 'react';
import Padding from '../Layout/Padding';
import MainMap from './../MainMap'
import useFetchMonitoreo from '../../hooks/useFetchMonitoreo';
import NodoMarker from '../Markers/NodoMarker';

export default () => {

    const tipos = {
        0: 'green-500',
        1: 'yellow-500',
        2: 'red-500',
        3: 'gray-500',
        4: 'black',
        5: 'pink-500',
        6: '',
        7: ''
    }
    const icons = {
        0: 'bullseye',
        1: 'exclamation',
        2: 'exclamation-triangle',
        3: 'exclamation-circle',
        4: 'thumbs-down',
        5: 'briefcase',
        6: 'exclamation',
        7: 'exclamation'
    }

    const estados = {
        0: '',
        1: 'ALERT',
        2: 'CRITICAL',
        3: 'UNKOWN',
        4: 'DOWN',
        5: 'MAINTENANCE',
        6: 'STATUS CODE 6',
        7: 'STATUS CODE 7'
    }

    const [ q, setQ ] = useState('')
    const [ t, setT ] = useState('')
    const [ nodoHover, setNodoHover ] = useState(null)

    const estado = useFetchMonitoreo(true)
    return <div className='flex items-stretch'>
        <div className='md:w-1/2 lg:w-1/3 xl:w-1/4 h-screen overflow-y-auto scroll'>

            <Padding>
            <div className='grid grid-cols-2 gap-2 mb-2'>
                <input placeholder='Buscar...' onChange={ e=> setQ(e.target.value)}></input>
                <select onChange={ e=> setT(e.target.value)}>
                <option value=''>Estado</option>
                    { Object.entries( estados ).map(([ k,v ] )=> <option key={k} value={k}>{v == '' ? 'OK' : v}</option>)}
                </select>
            </div>
                {estado ?
                    Object.entries(estado).filter( ([n,d])=> n.includes(q) &&  d.status.toString().includes(t)).map(([nodo, datos]) => 
                        <details key={nodo} className={`text-xs font-mono mb-1`}>
                            <summary onMouseMove={ ()=>setNodoHover(nodo)} onClick={ ()=>setNodoHover(nodo)} className={`flex font-bold  cursor-pointer items-center justify-between ${[2,1,5].includes(datos.status) && 'animate-pulse'}  rounded hover:shadow-outline md:px-1 px-0 text-${ tipos[datos.status]} mb-1`}>
                                <span>
                                    <i className={`hidden lg:inline-block fa fa-${icons[datos.status]} fa-fw mr-1`}/>
                                    {nodo} 
                                </span>
                                { estados[datos.status] != '' && <span className={`flex flex-1 mx-2 h-1 opacity-25 bg-${ tipos[datos.status]}`}></span>}
                                <span className='font-semibold uppercase tracking-wider '>
                                    { estados[datos.status]}
                                </span>
                            </summary>
                                { Object.entries( datos.servicios ).map( ([servicio,info])=>
                                    <details key={servicio} className={`text-xs my-1 ${info.status>0 && `font-bold text-${ tipos[datos.status]}`} ` }>
                                        <summary className='ml-5 cursor-pointer hover:opacity-100 opacity-75' ><i className='fa fa-angle-right opacity-50'/> {servicio}</summary>
                                        { Object.entries( info ).map( ([ k, v])=>
                                              <div key={k} className='text-xxs ml-8 flex my-1'>
                                                <span className={`truncate w-1/4 opacity-75 uppercase text-${ tipos[datos.status]}`} > {k} </span>
                                                <span className='ml-1 w-3/4'>{v}</span>
                                              </div>
                                        )}
                                    </details>
                                 )}

                        </details>
                    )
                :'Cargando nodos...'}
            </Padding>
        </div>
        <div className='flex-1 '>
            <MainMap >

            {estado && Object.values(estado).map((nodo, index) =>
                    <NodoMarker
                        active={ Object.keys(estado)[index] == nodoHover}
                        
                        key={Object.keys(estado)[index]}
                        lat={nodo.coordenadas.split(',')[0] || 0}
                        lng={nodo.coordenadas.split(',')[1] || 0}
                        nodo={nodo}
                        nombre={Object.keys(estado)[index]} />)}
            </MainMap>
        </div>

    </div>
};
