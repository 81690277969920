import React from 'react';
import Padding from '../Layout/Padding'
import paletas from './../../consts/paletas'
import useEnv from '../../hooks/useEnv';

const NextAgendaDistancias = ({nextAgenda,setHoveredAgenda}) => {
    const primaryColor = useEnv('COLOR')
    
    return <div className="items-center flex ">
                                            <i className={`fa fa-arrows-alt-h  text-${primaryColor}-400 mx-3`}></i>
                                            <table className=" text-center ">
                                                <tbody> 
                                                {/* .slice(0,15) */}
                                                    {nextAgenda && <tr>{Object.entries(nextAgenda.distancias).sort((a, b) => a[1] - b[1] ).slice(0,15).map(agenda =>
                                                    <th 
                                                        key={agenda[0]} 
                                                        onMouseOver={()=>setHoveredAgenda(agenda[0])}
                                                        onMouseLeave={()=>setHoveredAgenda(null)}
                                                        className="hover:shadow-outline  transition-all duration-150 p-2"
                                                    >
                                                    <div className={`text-${paletas.turnos[nextAgenda.in.datos.agenda_turnoid].color}-500 font-mono text-xs`}>{agenda[0]}</div>
                                                    <div>{agenda[1]}</div>
                                                    <div className=" text-xxs -mt-1">mts</div>
                                                    </th>)}</tr> }
                                                </tbody>
                                            </table>
                                            <i className="fa fa-ellipsis-h   mx-3"></i>
                                        </div>}

export default NextAgendaDistancias
