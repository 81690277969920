import React from 'react'
import {CSVLink} from 'react-csv'

const ActionButtons = (props) => {
    const {filename, csvData} = props

    const handleClickPrint = () => {
        window.print()
    }
    
    return (
        <span className='flex gap-2'>
            <CSVLink data={csvData} filename={filename}>
                <button className='text-sm'><i className='fa fa-file-csv mr-2'/> Exportar</button>
            </CSVLink>
            <button onClick={handleClickPrint} className='text-sm'><i className='fa fa-print mr-2'/> Imprimir</button>
        </span>
    )
};

export default  ActionButtons
