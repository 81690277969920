import React, { useContext } from 'react';
import Cargando from '../Global/Cargando';
import useFetch from '../../hooks/useFetch';
import moment from 'moment'
import LogListItem from '../LogView/LogListItem';
import ConfigContext from '../../contexts/ConfigContext';

const DashboardTarifas = () => {
    const context = useContext(ConfigContext)
    const [logs] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/logs/${moment().format('YYYY-MM-DD')}`)
    
    return  <div className='dashboard-card'>
                <h4 className='pb-2  opacity-50'><i className='fa fa-history fa-fw text-teal-500 mr-2 '/>Logs</h4>
                <table className='w-full text-xs'>
                    <tbody>
                        {logs ? 
                            logs.filter(log=>log.includes('local.INFO')).reverse().map((log,index) =>
                                <LogListItem key={index} log={log}/>
                            ) : <tr><td><Cargando /></td></tr>
                        }
                    </tbody>
                </table>
            </div>
}
export default DashboardTarifas