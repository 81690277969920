import React from 'react';
import MainMap from './../MainMap'

const MapaSupercanal = () => {
    
    return <MainMap
        mapIn="uv"
        supercanal={true}
    />
        
};

export default MapaSupercanal