import react from 'react';
import React, { useContext, useState } from 'react';
import ConfigContext from '../../contexts/ConfigContext';
const MovilRedesMarker = ({ movil, $hover }) => {
    let context = useContext(ConfigContext)
    const [onCar, setOnCar] = useState(false)
    const [movilObj, setMovilObj] = useState(false)

    const handleOnOver = () => {setOnCar(true)}
    const handleOnLeave = () => {setOnCar(false)}

    React.useEffect(() => {setMovilObj(movil.nombre.replace(/%7C/g, '|'))},[movil])

    return (
        <div onMouseOver={handleOnOver} onMouseLeave={handleOnLeave} className="marker-movil relative   -ml-5 -mt-5 "
            style={{
                transition: '1s',
                width: '30px',
                height: '30px'
            }} >
            <div className={`bg-cover absolute inset-0 m-auto `}
                style={{
                    backgroundImage: `url("../markers/${movil.velocidad === '0' ? 'blanco' : 'amarillo'}.png")`,
                    backgroundPosition: 'center center',
                    transform: `rotate(${movil.sentido}deg)`,
                    opacity: `${movil.velocidad === '0'  ? '.9' : '1'}`,
                    height: '15px',
                    width: '30px'
                }} ></div>

            {onCar && <div className={`tooltip rounded  p-px px-1  text-xxs font-bold flex flex-col ml-8 w-24`}  >
                
                <span className="opacity-75 text-xxs">{movilObj.replace(/%20/g, ' ')}</span>
                <span className="font-mono tracking-wider xs"><i className={` fa fa-${movil.velocidad === '0'  ? 'stop' : 'play'}`} /> {movil.patente}</span>
            </div>}
        </div>
    )
};

export default MovilRedesMarker
