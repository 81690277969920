import React from 'react'
import { Link  } from 'react-router-dom'
import Padding from '../components/Layout/Padding'

export default function MapsScreen() {

    const links = [
        { to: '/map', title: 'Mapa únificado', icon: 'map', roles: ['consultas', 'operador', 'tecnico', 'dev', ,'guest'], group: 1  },
        { to: '/morosidad', title: 'Mapa de morosidad', icon: 'coins', roles: ['dev'], group: 1 },
        { to: '/servicios', title: 'Mapa de servicios', icon: 'server', roles: ['dev'], group: 1 },
        { to: '/ordenes/map', title: 'Mapa de órdenes', icon: 'clipboard', roles: ['consultas', 'operador', 'dev'], group: 2 },
        { to: '/clientes/map', title: 'Mapa de clientes', icon: 'users', roles: ['consultas', 'operador', 'dev'], group: 3 },
        { to: '/competencia', title: 'Mapa migración', icon: 'list-alt', roles: ['consultas', 'operador', 'dev'], group: 3 },
    ]
    return (
        <Padding>
            <h1>Mapas</h1>
            <div className='w-full flex flex-wrap items-center justify-start'>
                { links.map( link=>{
                    return <Link to={link.to} key={link.icon}  className='button flex flex-col text-center  items-center justify-center h-32 w-32 mx-2'  >
                        <i className={`fa fa-${link.icon} mb-5 text-4xl text-teal-500`} />
                        <span className=''>{link.title}</span>
                    </Link>
                })}
            </div>
        </Padding>
    )
}

