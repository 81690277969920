import React, { useEffect, useState } from 'react'
import Padding from '../../components/Layout/Padding'
import dayjs from 'dayjs'
import Cargando from '../../components/Global/Cargando'
import { useHistory } from 'react-router'
import moment from 'moment-timezone'
export default function PbxAgentes(params) {
	const [eventos, setEventos] = useState()
	const history = useHistory()

	useEffect(() => {
		moment&& moment.locale('es')
	}, [moment])

	useEffect(() => {
		fetchEventos()
		const PbxAgentesInterval = setInterval(() => {fetchEventos()}, 20000)
		return () => clearInterval(PbxAgentesInterval)
	}, [])

    const fetchEventos = async () => {
		let today = dayjs().format('YYYY-MM-DD')
		let url = encodeURI(`${process.env.REACT_APP_BRIDGE_API}/pbx/stats/?entity=reports/agent_detail&start=${today}&end=${today}`)
		try {
			const response = await fetch(url, {
				headers: { 'authorization': process.env.REACT_APP_BRIDGE_API_KEY , 'Content-Type':'application/json', 'Accept':'application/json'},
				method:'GET'
			})
			const responseJSON = await response.json()
			setEventos(responseJSON)
		} catch (e) { console.log(e) }
	}
	const STATES = 
		{ 
			'START CALL' : { color : 'red' , icon : 'phone', title:'En llamada'},
			'END CALL COMPLETECALLER' : { color : 'yellow' , icon : 'phone-slash', title:'Cliente Cortó'},
			'END CALL COMPLETEAGENT' : { color : 'yellow' , icon : 'phone-slash', title:'Agente Cortó'},

			'START PAUSE' : { color : 'blue' , icon : 'pause', title:'En Pausa'},
			'END PAUSE' : { color : 'indigo' , icon : 'sync', title:'Salió de Pausa'},

			'START SESSION' : { color : 'green' , icon : 'play', title:'Inició Sesión' },
			'END SESSION' : { color : 'teal' , icon : 'stop',  title:'Finalizó Sesión'},

			'REJECT' : { color : 'purple' , icon : 'phone', title:'Rechazado'},
			'FAILED' : { color : 'gray' , icon : 'timex', title:'Falló'},
		}
    return (
		<div  className='flex justify-between flex-wrap gap-2 mb-2'>
			{eventos  && ([...new Set(eventos.rows.map(evento=>evento.Agente))])
					.filter( agente => !agente.includes('SIP'))
					.map( agente => {
						const evs = eventos.rows.filter(evento=>evento.Agente == agente)
						const ultimoEvento = evs[evs.length-1]
						return <div className={`flex flex-col gap-1 items-center justify-start text-center w-24 px-1 py-2 rounded-lg cursor-pointer border border-transparent hover:border-${STATES[ultimoEvento.Evento].color}-500`}>
									<div className=' relative w-24 h-16  flex items-center justify-center'>
										<i className={`fa  fa-fw text-4xl mr-2 opacity-25 fa-user`}/>
										<i className={`fa absolute -mr-12 -mt-6 fa-fw text-2xl text-${STATES[ultimoEvento.Evento].color}-500 fa-${STATES[ultimoEvento.Evento].icon}`}/>
									</div>
									<div className='text-xxs'>

										<div className='font-semibold '>{agente}</div>
										<p className={` text-${STATES[ultimoEvento.Evento].color}-500`}>
											<strong>{STATES[ultimoEvento.Evento].title}</strong><br />
											<span className='opacity-50'>{ultimoEvento.Data}</span>
										</p>
										<small className='opacity-50 '>
											{moment(ultimoEvento.Fecha, 'DD/MM/YYYY hh:mm:ss').fromNow()}
										</small>
									</div>
								</div> 
						})}
		</div>
		
    )
}
