import React from 'react';
import FloatingWindow from './../Global/FloatingWindow'

const LayerSelector = ({ supercanal, layers, activeLayer, applyLayers, trafico, setTrafico, heatmap, setHeatmap, monitoreoLayer, statsWindow, setStatsWindow, setMonitoreoLayer, movilesLayer, setMovilesLayer, movilesRedesLayer, setMovilesRedesLayer, sizeOptionsMap }) => {
    
    const buttons = [
        { label : 'Móviles .R', icon : 'truck', layer : movilesRedesLayer, onClick : () => setMovilesRedesLayer() },
        { label : 'Móviles .O', icon : 'truck', layer : movilesLayer, onClick : () => setMovilesLayer() },
        { label : 'Tránsito', icon : 'car', layer : trafico, onClick : () => setTrafico() },
        // { label : 'Mapa de Calor', icon : 'bullseye', layer : heatmap, onClick : () => setHeatmap() },
        { label : 'Nodos', icon : 'broadcast-tower', layer : monitoreoLayer, onClick : () => setMonitoreoLayer() },
        // { label : 'Estadisticas', icon : 'chart-bar', layer : statsWindow, onClick : () => setStatsWindow() },
    ]
    return <div className='  m-3 top-0 absolute left-0 z-40'>

    <FloatingWindow >
        <div className="flex flex-row items-center text-xs" >
            <i className={`fa fa-layer-group fa-fw gap-1  text-teal-500 hidden sm:inline`} />
            <div className={` flex items-center  items-stretch  overflow-hidden`}>
                {!supercanal 
                    ? layers && layers.map((layer,index) =>
                        <div key={index}
                            className={`px-2 sm:px-3 opacity-50 flex items-center  cursor-pointer  hover:opacity-100 hover:text-teal-500 capitalize ${activeLayer === Object.keys(layer)[0] && 'opacity-100 font-bold '}`}
                            onClick={() => applyLayers(layer.file_name)}
                            title={layer.label}
                        >
                            <i className={`fa fa-${layer.icon} fa-fw sm:mr-1 `}></i>
                            <span className={`hidden ${sizeOptionsMap ? "2xl:inline-block" : "xl:inline-block"}`}>{layer.label}</span>

                        </div>)
                    : <div
                        className={`px-2 sm:px-3 opacity-50 flex items-center  cursor-pointer  hover:opacity-100 hover:text-teal-500 capitalize }`}
                        onClick={() => applyLayers('uv_supercanal_new_New')}
                        title={'Supercanal'}
                    >
                        <i className={`fa fa-angry fa-fw sm:mr-1 `}></i>
                        <span className={`hidden xl:inline-block`}>Supercanal</span>

                    </div>
                }
            </div>
            
            <div className={`h-6 w-px bg-teal-500 opacity-50`}></div>
            
            { !supercanal ? buttons.map( button=> <div 
            key={button.label}
            className={`opacity-50  flex items-center   cursor-pointer  px-2 sm:px-3   hover:opacity-100 hover:text-teal-500 capitalize ${button.layer && 'opacity-100 font-bold '}`}
                onClick={button.onClick}>
                <i className={`fa fa-${button.icon} fa-fw sm:mr-1`}></i>
                <span className={`hidden ${sizeOptionsMap ? "2xl:inline-block" : "lg:inline-block"}`}>{button.label}</span>
            </div>) : null}


        </div>

    </FloatingWindow>
    </div>
}

export default LayerSelector