import React, { useContext, useState } from 'react';

import Cargando from '../../components/Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import EntityBadge from '../../components/Badges/EntityBadge'
import Padding from '../../components/Layout/Padding'
import useFetch from '../../hooks/useFetch'

const AgendamientoMoviles = ({history}) => {
    const context = useContext(ConfigContext)

    const [moviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles`)
    const [agendamientoMoviles, updateAgendamientoMoviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendamiento/moviles`)
    const [tecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos`)

    const [form, setForm] = useState()
    const handleForm = (e) => setForm({ 
        ...form, 
        [e.target.name]: e.target.value,
    })
    const handleAssignTecnicoToMovil = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/agendamiento/tecnico`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        updateAgendamientoMoviles()
    }
  
    return (
        <Padding>
            <div className='flex items-center justify-start'>
                <button onClick={() => history.goBack()} ><i className='fa fa-arrow-left mr-2' />Volver</button>
                <h2 className='ml-2'>Asignar tecnicos a moviles</h2>
            </div>

            <div className='grid grid-cols-4 gap-4'>
                <select onChange={handleForm} className='overflow-hidden ' name='movil_id'> 
                    {moviles && moviles
                    .filter(movil=>movil.nombre.includes('MOVIL'))
                    .sort((a,b)=> { if (a.nombre < b.nombre) { return -1}; return 1})
                    .map((movil,index) => 
                    <option key={index} value={movil.movil_id}>{movil.nombre}</option>)}
                </select>
                <select onChange={handleForm} name='tecnico1_id'> 
                    {tecnicos && tecnicos
                        .sort((a,b)=> {if(a.nombre < b.nombre) { return -1 } return 1})
                        .map((tecnico, index) => <option key={index} value={tecnico.id}>{tecnico.nombre}</option>)} 
                </select> 
                <select onChange={handleForm} name='tecnico2_id'> 
                <option value={''}></option>
                    {tecnicos && tecnicos
                        .sort((a,b)=> {if(a.nombre < b.nombre) { return -1 } return 1})
                        .map((tecnico, index) => <option key={index} value={tecnico.id}>{tecnico.nombre}</option>)} 
                </select> 
                 <button onClick={handleAssignTecnicoToMovil}><i className='fa fa-paper-plane mr-2 ' /> Asignar</button> 
            </div>

            <table className='w-full my-4'>
            <thead>
                <tr>
                    <th>Movil</th>
                    <th>Tecnico 1</th>
                    <th>Tecnico 2</th>
                </tr>
            </thead>
                <tbody>
                    { agendamientoMoviles && agendamientoMoviles.map( (movil, index)=>{
                        const m = moviles && moviles.find(m=>m.movil_id == movil.movil_id)
                        const t1 = tecnicos && tecnicos.find(tecnico=>tecnico.id == movil.tecnico1_id)
                        const t2 = tecnicos && tecnicos.find(tecnico=>tecnico.id == movil.tecnico2_id)
                        return <tr key={index}>
                            <td>{m && <EntityBadge type='movil' name={ m.nombre } />}</td>
                            <td>{t1 && <EntityBadge type='tecnico' size='xs' name={ t1.nombre} />}</td>
                            <td>{t2 && <EntityBadge type='tecnico' size='xs' name={ t2.nombre} />}</td>
                        </tr>
                    })}
                </tbody>
            </table>
           
         
        </Padding>
    )
}

export default AgendamientoMoviles
