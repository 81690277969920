import React from 'react';
import FloatingWindow from './../Global/FloatingWindow'
import Padding from '../Layout/Padding'
import paletas from './../../consts/paletas'
import Small from './../Typo/Small'
import Label from '../Typo/Label';

const NextAgendaFilter = ({agendas, setFilterTurno}) => {
    let turnos = [...new Set(agendas.map(agenda => agenda.agenda_turnoid))].map(turno => turno)
    return <FloatingWindow pos="top-0 right-0">
                <Padding>
                <Label title="Turnos"/>
                    <select  onChange={e=>setFilterTurno(e.target.value)} className="w-full ">
                        <option key={null} value={''}>Todos</option>                    
                        { turnos.sort((a,b)=>a-b).map (turno=> <option key={turno} value={turno}>{paletas.turnos[turno].label}</option>)}
                    </select>
                </Padding>
            </FloatingWindow>
}

export default NextAgendaFilter;
