import React, {useEffect, useState, useContext} from 'react'
import ConfigContext from '../../contexts/ConfigContext';

export default function SigaButton({full=false, tipo, nro}) {
    const context = useContext(ConfigContext)

    const [url,setUrl] = useState({path:'http://192.168.1.13:8080', empresa: 30})

    useEffect(() => {
        context.empresa === 'uv' 
            ? setUrl({path:'http://siga.ushuaiavision.com.ar:8080', empresa: 20})
            : setUrl({path:'http://192.168.1.13:8080', empresa: 30}) 
    },[context.empresa])

    const SIGA_URL = {
        orden: `${url.path}/SIGA-AR/servlet/entitymanagerordensrvreclamo?DSP,${url.empresa},`,
        contrato: `${url.path}/SIGA-AR/servlet/entitymanagercontrato?DSP,${url.empresa},`,
        cliente: `${url.path}/SIGA-AR/servlet/entitymanagercliente?DSP,${url.empresa},`,
        agenda: `${url.path}/SIGA-AR/servlet/entitymanageragendaregistro?DSP,${url.empresa},20200921,1,1,1,`,
        crm: `${url.path}/SIGA-AR/servlet/entitymanagercrmregistro?DSP,${url.empresa},`,
        movil: `${url.path}/SIGA-AR/servlet/entitymanagercentrostock?DSP,${url.empresa},`,
        movil: `${url.path}/SIGA-AR/servlet/entitymanagercentrostock?DSP,${url.empresa},`,
        tecnico: `${url.path}/SIGA-AR/servlet/entitymanagertecnico?DSP,${url.empresa},`,
    }

    const handleClick = () => {window.open(SIGA_URL[tipo]+nro)}
    
    return <button onClick={handleClick} className={`w-${full?'16':'6'} h-8 flex justify-center items-center `} style={{padding:'1px 3px'}}> 
        <img src={'/isotipo_siga.png'} className='h-5 w-4 object-contain'/> 
        {full && 'SIGA'}
    </button>
};
