import React, { useState, useEffect } from 'react';
import FloatingWindow from './../Global/FloatingWindow'

const OrdenesFilters = (props) => {
    let [tiposSelected, setTiposSelected] = useState(['I', 'D', 'S', 'R', 'U'])
    let [estadosSelected, setEstadosSelected] = useState(['E','R','A','P'])

    let tipos = {
        "I": { title: 'Instalación', color: 'blue', icon: 'play' },
        'D': { title: 'Desconexion', color: 'green', icon: 'cog' },
        'S': { title: 'Servicio', color: 'indigo', icon: 'cog' },
        'R': { title: 'Reclamo', color: 'red', icon: 'cog' },
        'K': { title: 'Corte', color: 'orange', icon: 'cog' },
        'U': { title: 'Reconexión', color: 'purple', icon: 'cog' },
        'E': { title: 'Retiro', color: 'pink', icon: 'cog' },
    }
    let estados = {
        "P": { title: 'Pendiente', color: 'blue', icon: 'user' },
        'A': { title: 'Agendada', color: 'pink', icon: 'user' },
        'R': { title: 'Reagendar', color: 'orange', icon: 'user' },
        'S': { title: 'Suspendida', color: 'gray', icon: 'user' },
        'E': { title: 'Emitida', color: 'green', icon: 'user' },
        'F': { title: 'Finalizada', color: 'purple', icon: 'user' },
        'C': { title: 'Cancelada', color: 'red', icon: 'user' },
        'K': { title: 'Corte de Señal', color: 'teal', icon: 'user' },
        'N': { title: 'Reconexión', color: 'indigo', icon: 'user' },
        'I': { title: 'Incumplida', color: 'yellow', icon: 'user' }
    }

    useEffect(() => {
       if(estados && tipos ){ 
           props.setTipos( tiposSelected)
           props.setEstados( estadosSelected)
       }
   }, [tipos, tiposSelected , estados, estadosSelected])


    const _handleTipos = (tipo) => {
        if(tiposSelected.includes(tipo)) {
            setTiposSelected(tiposSelected => tiposSelected.filter( selected=> selected !==tipo ))
        } else { setTiposSelected([...tiposSelected,tipo]) }
        props.setTipos(tiposSelected)
    }
    const _handleEstados = (estado) => {
        if(estadosSelected.includes(estado)) {
            setEstadosSelected(estadosSelected => estadosSelected.filter( selected=> selected !==estado ))
        } else { setEstadosSelected([...estadosSelected,estado]) }
        props.setEstados(estadosSelected)
    }
    return (
         <FloatingWindow  >
            <div className="text-xxs " >
                <div className="flex items-center  mb-3">
                    <h6 className="w-16 text-right opacity-50 pr-3 uppercase font-normal">Estados</h6>
                    <div className="flex rounded overflow-hidden">
                        { Object.values(estados).map((sts,index) => 
                            <span key={`estado_${sts.title}_${index}`}  onClick={()=>{_handleEstados(Object.keys(estados)[index])}}
                            className={`p-1 cursor-pointer truncate opacity-50 px-2 hover:opacity-100 ${estadosSelected.includes(Object.keys(estados)[index]) && `bg-${sts.color}-300 text-black opacity-100 `}`} 
                            value={sts.title} >
                                <strong className='inline-block lg:hidden'>{sts.title.substr(0,1)}</strong><span className='hidden lg:inline-block'>{sts.title}</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex items-center">
                    <h6 className="w-16 text-right opacity-50 pr-3 uppercase font-normal">Tipos</h6>
                    <div className="flex rounded overflow-hidden">
                        { Object.values(tipos).map((tpo,index) => 
                            <span key={`tipo_${tpo.title}_${index}`} onClick={()=>{_handleTipos(Object.keys(tipos)[index])}}
                            className={`p-1 cursor-pointer  opacity-50 px-2 hover:opacity-100 ${tiposSelected.includes(Object.keys(tipos)[index]) && `bg-${tpo.color}-300 text-black opacity-100 `}`} 
                            value={tpo.title} >
                                <strong className='inline-block md:hidden'>{tpo.title.substr(0,1)}</strong><span className='hidden md:inline-block'>{tpo.title}</span>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </FloatingWindow>
        )
};

export default OrdenesFilters