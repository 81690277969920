import React from 'react';
// import ViewConfiguracion from '../ViewConfiguracion'

const UsuariosConfiguracion = (props) => {

    return (
        <div>usuarios</div>
    )
    // const parametros = [
    //     {id: 1,title: 'Nombre', param: 'name', type: 'text', tags: 'input'},
    //     {id: 2,title: 'Usuario', param: 'username', type: 'text', tags: 'input'},
    //     {id: 3,title: 'Correo', param: 'email', type: 'text', tags: 'input'},
    //     {id: 4,title: 'Rol', param: 'role', type: 'number', tags: 'select'},
    //     {id: 5,title: 'Contraseña', param: 'password', type: 'password', tags: 'input'},
    // ]

    // return (
    //     <div>
    //         <ViewConfiguracion name='usuarios' title='Usuario' path='/configuracion/usuarios' parametros={parametros}/>
    //     </div>
    // )
}

export default UsuariosConfiguracion
