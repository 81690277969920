import React, { useContext, useState } from 'react';

import ClienteDetails from './ClienteDetails'
import ClienteMarker from '../Markers/ClienteMarker'
import ConfigContext from './../../contexts/ConfigContext';
import FloatingWindow from '../Global/FloatingWindow';
import MainMap from './../MainMap'
import axios from 'axios'
import Cargando from '../Global/Cargando';

const ClienteMap = () => {
    let context = useContext(ConfigContext)
    let [cliente, setCliente] = useState(null)
    let [latLng, setLatLng] = useState({lat: null, lng: null})
    let [clienteNro, setClienteNro] = useState('')
    let [searching, setSearching] = useState(false)

    const fetchCliente = () => {
        setSearching(true)
        axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/apicuo/clientes/${clienteNro}`,{headers: {Authorization: process.env.REACT_APP_BRIDGE_API_KEY}})
            .then(response => { setCliente(response.data[0]); setSearching(false) }).catch(e => console.log(e))
    }

 
    return (
        <MainMap
            recenter={cliente && { lat: cliente.lat, lng: cliente.lng }}
            right={cliente && <ClienteDetails cliente={cliente} />}
            left={ <FloatingWindow  icon='search' title='Cliente'> 
                    <input  type="text" defaultValue={clienteNro} 
                        placeholder='Buscar...'
                        onChange={e => setClienteNro(e.target.value)} 
                        onKeyPress={e => { (e.which == 13 || e.keyCode == 13 || e.key === 'Enter')  && fetchCliente()  }} 
                    /> 
                    { searching && <Cargando />}
                </FloatingWindow> } 
            >
            {(cliente || (latLng.lat && latLng.lng)) &&
                <ClienteMarker
                    icon="user"
                    lat={cliente.lat || latLng.lat}
                    lng={cliente.lng || latLng.lng}
                    text={cliente.nombre}
                    size="6" />}
        </MainMap>
    )
};


export default ClienteMap

