import React, { useContext } from 'react';
import DashboardPanels from '../components/Dashboard/Panels';
import ConfigContext from '../contexts/ConfigContext';
import Padding from '../components/Layout/Padding';
import UserGroups from '../components/Badges/UserGroups';

const DashboardScreen = () => {
    const context = useContext(ConfigContext)
    return <Padding>
        <div className='flex items-center justify-between'>
            <h1>Dashboard</h1>
            <div className='text-right flex flex-col items-end'>
                <div className='flex items-center justify-end mb-2'>
                    <UserBadge user={context.user} />
                    <i className='fa fa-user-circle text-teal-500 fa-3x  ml-4'/>
                </div>
                <UserGroups user={context.user}/>
            </div>
        </div>
        <DashboardPanels />
        <p className='fixed bottom-0 p-3 opacity-25 text-xs'>Version {process.env.REACT_APP_VERSION || 0}</p>
    </Padding>
}

export default DashboardScreen

const UserBadge = ({user,size='sm'}) => {
    return <div className={`flex flex-col text-${size}`}>
            <strong>{user.name[0]}  <span className='ml-1 font-medium text-teal-500'>{user.company && user.company[0]}</span></strong>
            <span className='opacity-50'>{user.userprincipalname[0]}</span>
    </div>
}