import React, { useContext, useRef } from 'react'
import ConfigContext from '../../contexts/ConfigContext'

export default function UserGroups({ user }) {
    const names = useRef(user.memberof ? Object.values(user.memberof).filter(group => typeof group !== 'number').map(group => group.split(',')[0].replace('CN=', '')) : ['sin grupo'])
    const context = useContext(ConfigContext)
    return (
        <div className='mt-1 flex flex-wrap items-center justify-end text-xxs'>

            <div className=' text-invert mr-1 flex items-center py-1 text-teal-500'>
                {names.current.filter(group => group.includes('CUO')).map((group, index) =>
                    <span key={index}>{group.replace(/_/g, ' ')}</span>
                )}
                <div className='border border-teal-500 rounded-full px-1 py-px ml-2'>
                    {context.roles.map(role => <span key={role} className='text-invert mx-1'>{role}</span>)}
                </div>
            </div>

        </div>

    )
}
