import { useContext} from 'react'

import ConfigContext from '../contexts/ConfigContext'

const useExportLogs = (props) => {
    const context = useContext(ConfigContext)

    const downloadData = async (date, query,name,title) => {
        try {
            await fetch(`${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/logs/${query ? 'export/'+query.toUpperCase() : 'export'}`, 
            { 
                method: 'POST', 
                body: JSON.stringify({ date:date, name: name, title:title}),
                headers: {
                    'Authorization': process.env.REACT_APP_CUO_API_KEY,
                    'Content-Type': 'application/json',
					'Accept': 'application/json',
                }
            })
            .then(response => response.blob())
            .then(function(myBlob) {
                let objectURL = URL.createObjectURL(myBlob);
                const link = document.createElement('a');
                link.href = objectURL;
                link.setAttribute('download', `${name === 'laravel' ? 'log' : 'sesion'}-${date}.csv`);
                document.body.appendChild(link);
                link.click();
            })
        } catch (e) {
            console.log('No hay archivo')
        }
    }

    return {downloadData}
}

export default useExportLogs