import { useEffect, useState } from 'react'
import axios from 'axios'

const useFetchCyberMapa = (movilesLayer) => {
    
    let [fetchedData , setFetchedData]= useState(null) 

	const fetchData = () => {
		axios.post(`${process.env.REACT_APP_CORS_PROXY}/${process.env.REACT_APP_CYBERMAPA_API}`,
				{ user: process.env.REACT_APP_CYBERMAPA_USER, pwd: process.env.REACT_APP_CYBERMAPA_PWD, action: process.env.REACT_APP_CYBERMAPA_ACTION },
				{ headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Methods': 'POST, OPTIONS', 'Access-Control-Allow-Origin': '*' } }
			).then(response => setFetchedData(response.data))
	}

	useEffect(()=>{
        const fetchInterval = setInterval(() => { fetchData() }, 30000)
        return () => clearInterval(fetchInterval)    
    },[movilesLayer])

	useEffect(() => {
		fetchData()
	}, [])

	return fetchedData
}

export default useFetchCyberMapa
