import React, { useContext, useEffect, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Icons from '../Global/IconList'
import Padding from '../Layout/Padding'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import {useHistory} from 'react-router-dom'

const InformesConfiguracion = (props) => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/informes`)
    
    const [ deleteItem ] = useDelete('informes', '/configuracion/informes')
    const [openForm, setOpenForm] = useState(false)
    const [form, setForm] = useState()
    
    const group = ['Operaciones' , 'Comercial', 'Ventas', 'Tecnica', 'Recursos Humanos', 'CRM', 'IT', 'Gerencia', 'Directorio'];
    const level = [ 
        {id: 1, title: 'nivel 1: Todos'}, 
        {id: 2, title: 'nivel 2: Consultas / Tecnicos / Gerencia'},
        {id: 3, title: 'nivel 3: Consultas / Operadores / Gerencia'},
        {id: 4, title: 'nivel 4: Operadores / Tecnicos / Gerencia'},
        {id: 5, title: 'nivel 5: Operadores / Gerencia'}, 
        {id: 6, title: 'nivel 6: Tecnicos / Gerencia'},
        {id: 7, title: 'nivel 7: Consultas / Gerencia'},
        {id: 8, title: 'nivel 8: Admin / Gerencia'}, 
        {id: 9, title: 'nivel 9: Gerencia'},
        {id: 10, title: 'nivel 10: Directorio'},
    ]

    const handleNueva = () => {
        setOpenForm(!openForm)
        setForm({ ...form, icon:'address-book', group:'Operaciones', level: 1, _method:'post', mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
    }

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

    const handleStore = async () => {
        const url = form && form.id 
            ? `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/informes/${form.id}`
            : `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/informes`

        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }

    const handleClickEditar = (item) => {
        setOpenForm(true) 
        setForm({...form, _method:'put',id:item.id, title:item.title, uri:item.uri, group:item.group, level:item.level, icon:item.icon, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0],})
    }

    const handleClickVolver = () => {
        history.goBack()
    }
    
    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Informes</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
           
            {data ?
                <table className="w-full">
                    <thead>
                        <tr>
                            <th>Icono</th>
                            <th>Nombre del Informe</th>
                            {openForm && <th>Url</th>}
                            <th>Grupo</th>
                            <th>Roles habilitados</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {openForm &&
                            <tr>
                                <td >
                                    <i className={`fa fa-${form.icon} fa-fw mr-1`} />
                                    <select onChange={handleChange} name='icon' value={form.icon} style={{ width: '30px'}}>
                                        {Icons.map((icon,index) => (<option key={index} value={icon}>{icon}</option>))}
                                    </select>
                                </td>
                                <td ><input onChange={handleChange} name='title' value={form.title} placeholder='Titulo' /></td>
                                <td ><input onChange={handleChange} name='uri' value={form.uri} placeholder='Url' /> </td>
                                <td >
                                    <select onChange={handleChange} name='group' value={form.group}>
                                        {group.map((s,index) => (<option key={index} value={s}>{s}</option>))}
                                    </select>
                                </td>
                                <td >
                                    <select onChange={handleChange} name='level' value={form.level}>
                                        {level.map((l,index) => (<option key={index} value={l.id}>{l.title}</option>))}
                                    </select>
                                </td>
                             

                                <td className='text-right'> <button onClick={handleStore}><i className={`fa fa-${form && form.id ? 'save' : 'paper-plane'} mr-2 `} /> {form && form.id ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        
                        {data.map(item => <tr key={item.id}>
                            <td><i className={item.icon && `fa fa-fw fa-${item.icon}`} /></td>
                            <td >{item.title && item.title}</td>
                            <td className='text-right'>{item.group && <span className='badge bg-teal-500 '> {item.group}</span> }</td>
                            <td>{item.level && <span className=' bg-black text-gray-200 px-2 py-1 text-sm rounded'>
                            <i className='fa fa-lock fa-fw mr-1'/>
                            {level.find( l => l.id == item.level).title }
                            </span>}</td>
                            <td className='text-right'>
                                <button onClick={() => handleClickEditar(item)} > <i className='fa fa-edit'/> Editar</button>
                                <button onClick={() => {deleteItem(refreshData, item.title ,item.id); setOpenForm(false)}}> <i className='fa fa-trash'/> Eliminar</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default InformesConfiguracion

