import React from 'react';
import Stat from './../Global/Stat'
import MainFrame from './../Layout/MainFrame'
import Padding from '../Layout/Padding';

export default () => <MainFrame>
    <Padding>

        <Stat />
    </Padding>
</MainFrame>

