import React, { useState } from 'react'
import useFetchSessions from '../../hooks/useFetchSessions'
import LogListItem from './LogListItem'
import Padding from '../Layout/Padding'
import useExportLogs from '../../hooks/useExportLogs'

const TYPES = {
    'Inicio sesion': { color: 'green', icon: 'sign-in-alt' },
    'Cierre sesion': { color: 'red',    icon: 'sign-out-alt' },
}

const LogsIndex = () => {
    const {downloadData,downloadDataQuery} = useExportLogs()

    const [tipo, setTipo] = useState('')
    const { data, fetchData, setDate, date } = useFetchSessions()
    const [query, setQuery] = useState('')

    const handleTipo = e => setTipo(e.target.value)

    const handleChangeQuery = e => {
        setQuery(e.target.value)
    }

    const handleClickExport = () => {
        downloadData(date,query,'session','Sesiones')
    }

    return (
        <Padding>
            <div className='flex items-center justify-between'>
            <h1>Sesiones</h1>
                <input onChange={handleChangeQuery} placeholder='Buscar ...'/>
                {date && <button onClick={handleClickExport}><i className='fa fa-file-csv'/> Exportar</button>}
            </div>
            <div className='grid grid-cols-2 gap-3'>
                <input type='date' value={date} onChange={e => setDate(e.target.value)} />
                {/* <button className='w-1/3 mx-2' disabled={!date} onClick={() => getListLogs()}><i className='fa fa-search' /></button> */}
                <select onChange={handleTipo} >
                    <option value=''>TODOS</option>
                    { Object.keys(TYPES).map( type => <option value={type}>{type}</option> )}
                  
                </select>
            </div>
            <table className='mt-4 w-full text-xs'>
                <tbody>
                    {data && data.filter(log => log.includes(tipo) &&  log.includes(query)).reverse().map((log,index) => <LogListItem key={index} log={log} TYPES={TYPES} />)}
                </tbody>
            </table>
        </Padding>
    )
}


export default LogsIndex