import React, { useContext, useEffect, useState } from 'react'

import ClienteMarker from '../Markers/ClienteMarker'
import ConfigContext from '../../contexts/ConfigContext'
import Details from '../Global/Details'
import SigaButton from '../Global/SigaButton'
import MainMap from './../MainMap'
import useFetch from '../../hooks/useFetch'
import ActionButtons from '../Global/ActionButtons'
import dayjs from 'dayjs'
import Btn from "../../components/Forms/Btn"
import { useHistory } from 'react-router'

export default ({ match }) => {
    const context = useContext(ConfigContext)
    let history = useHistory()

    const ORDENSTS = {
        R: "↪️ REAGENDADA",
        P: "⏸ PENDIENTE",
        A: "✍️ AGENDADO",
        S: "⏹ SUSPENDIDA",
        E: "▶️ EMITIDA",
        C: "❌ CANCELADA",
        F: "✅ FINALIZADA",
        K: "✂️ CORTE DE SEÑAL"
    }

    const ORDENTPO = {
        R: "RECLAMO",
        I: "INSTALACION",
        D: "DESCONEXION",
        E: "RETIRO",
        S: "SERVICIO",
        K: "CORTE",
        U: "RECONEXION"
    }

    const GENERADO = {
        N: "🧰 NORMAL",
        Z: "🚚 MUDANZA",
        M: "💲 MOROSIDAD",
        Q: "🔛 CAMBIO",
        S: "👷‍♂️ SERVICIO",
        C: "📋 CONTRATO",
        T: "S/D"
    }

    const ESTADOTPO = {
        X: "Desconectado",
        C: "Conectado",
        K: "Corte de señal",
        I: "Ingresado",
        P: "Pendiente",
        B: "Baja pendiente",
        M: "Baja moroso",
        E: "Emitido",
        A: "Anulado"
    }

    const FACTURAS = {
        P: 'PAGA',
        I:'IMPAGA',
        L:'PARCIAL',
        X:'ANULADA',
        E:'ENVIADA'
    }

    const [data,fetchData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${match.params.empresa}/prod/cuo/genesys/clientes/nro/${match.params.nro}`)
    const [dataDetails, setDataDetails] = useState()

    const handleCRM = async() =>{

        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${match.params.empresa}/prod/cuo/clientes/${match.params.nro}`,{
            method: 'GET',
            headers : { 
                'Authorization': process.env.REACT_APP_BRIDGE_API_KEY, 
                'Content-type':'application/json' 
            }
        })
        const responseJSON = await response.json()
        setDataDetails(responseJSON)
    }
    
    const [belongs,fetchBelongs] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${match.params.empresa}/prod/cuo/utils/belongs/id/${match.params.nro}`)
    const [csvData, setCsvData] = React.useState([])

    useEffect(() => {
        fetchData()
        fetchBelongs()
        handleCRM()
    }, [])

    useEffect(() => {
       data && belongs && data.map((dat, index)=> setCsvData(csvData =>{
            return [...csvData,{ClienteNro: dat.clientenro, Nombre: dat.nombre, Status: dat.status, Email: dat.emailppl, Telefono: dat.telefono, Direccion: dat.callegeonombre, Zona: belongs.perteneceA.zonas, Nodo: belongs.perteneceA.nodos, ZonaOperativa: belongs.perteneceA.operativas}]
       }))
    }, [data])

    useEffect(() => {
        context.setEmpresa(match.params.empresa)
    },[match])

    const handleOrder=(nro) =>{
        history.push(`/show/ordenes/${nro}`)
    }

    if(belongs && belongs.length <= 0 || data && data.length <= 0) return(<div>No existen datos de este cliente</div>)

    return (
        <div>
            <div className='flex justify-between items-start '>

            <div className='w-1/3 p-5'>
                <div className='flex items-center justify-between mb-5 '>
                    <button onClick={() => history.goBack(-1)} >Volver</button>
                    <SigaButton tipo='cliente' nro={match.params.nro} full/>
                    {data && <ActionButtons csvData={csvData} filename={`Cliente-Nº-${match.params.nro}-${dayjs().format('DD-MM-YYYY')}`}></ActionButtons>}
                </div>
                
                {belongs ? belongs.length > 0 && 
                    <ul className='my-10 w-full grid grid-cols-2 items-end'>
                        <li className='flex items-center flex-col text-center p-4'>
                            <i className='fa fa-fw mb-2 text-teal-400 fa-building' />
                            <label>Barrio</label>
                            <div className='font-bold text-lg'>{belongs.perteneceA.barrios}</div>
                        </li>
                        <li className='flex items-center flex-col text-center p-4'>
                            <i className='fa fa-fw mb-2 text-teal-400 fa-network-wired' />
                            <label>Zona</label>
                            <div className='font-bold text-lg'>{belongs.perteneceA.zonas}</div>
                        </li>
                        <li className='flex items-center flex-col text-center p-4'>
                            <i className='fa fa-fw mb-2 text-teal-400 fa-broadcast-tower' />
                            <label>Nodo</label>
                            <div className='font-bold text-lg'>{belongs.perteneceA.nodos}</div>
                        </li>
                        <li className='flex items-center flex-col text-center p-4'>
                            <i className='fa fa-fw mb-2 text-teal-400 fa-object-ungroup' />
                            <label>Zona Operativa</label>
                            <div className='font-bold text-lg'>{belongs.perteneceA.operativas}</div>
                        </li>
                        { belongs.perteneceA.supercanal && <li className='flex items-center my-1'>
                            <div className='w-1/3 font-semibold text-xs text-teal-500'>Supercanal</div>
                            <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.supercanal }</div>
                        </li> }
                        
                    </ul> : <div className='flex flex-col items-center my-1'><small className='opacity-50'>Detectando pertenencias...</small></div>
                }

                {data && <Details inline data={data[0]} size='xs' />}


                {/* CRM */}
                <details className={`' font-semibold text-xs truncate'`} style={{marginTop: 20, fontSize: 12}}>
                    <summary className={`' font-semibold text-xs text-teal-500   truncate'`} style={{fontSize: 14, marginBottom: 10, cursor: 'pointer'}}>Último CRM</summary>
                    {dataDetails && dataDetails.crms[0] ?
                        <div>
                            <div style={{display: 'flex'}}>
                                <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Fecha de inicio:</div>
                                <div>{dataDetails.crms[0].crmfchini.replace('00:00:00','')}</div>
                            </div>
                            <div style={{ marginBottom: 10}}>
                                <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Observación:</div>
                                <div>{dataDetails.crms[0].crmobs}</div>
                            </div>
                        </div> : <div style={{ marginBottom: 10}}>No existen datos</div>
                    }
                </details>

                {/* DOCUMENTOS */}
                <details className={`' font-semibold text-xs truncate'`} style={{ fontSize: 12}}>
                    <summary className={`' font-semibold text-xs text-teal-500   truncate'`} style={{fontSize: 14, marginBottom: 10, cursor: 'pointer'}}>Facturas Impagas</summary>
                    {dataDetails && dataDetails.documentos[0] ?  
                        dataDetails.documentos.filter(doc => doc.facturasts == 'I').map((doc, index) =>(
                            <div key={index}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Fecha de vencimiento: </div>
                                    <div>{doc.facturavto.replace('00:00:00','')}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Fecha de cobro: </div>
                                    <div>{doc.facturafcob.includes('0001') ? '-' : doc.facturafcob.replace('00:00:00','')}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Status: </div>
                                    <div>{FACTURAS[doc.facturasts]}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Importe:</div>
                                    <div>{`$${doc.facturatotal}`}</div>
                                </div><br/>
                            </div>
                        )) : <div style={{ marginBottom: 10}}>No existen datos</div>
                    }
                </details>

                {/* CONTRATOS */}
                <details className={`' font-semibold text-xs truncate'`} style={{ fontSize: 12}}>
                    <summary className={`' font-semibold text-xs text-teal-500   truncate'`} style={{fontSize: 14, marginBottom: 10, cursor: 'pointer'}}>Contratos</summary>
                    {dataDetails && dataDetails.contratos[0] ?  
                        dataDetails.contratos.filter(con => con.contratosts == 'C').map((con, index) =>(
                            // .filter(con => con.contratosts !== 'A' && con.contratosts !== 'X' && con.contratosts !== 'M')
                            <div key={index}>
                                {con ?
                                    <div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Estado:</div>
                                            <div>{ESTADOTPO[con.contratosts]}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Producto:</div>
                                            <div>{con.producto}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Contrato número:</div>
                                            <div>{con.contratonro}</div>
                                        </div>
                                        <div>
                                            <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Política Comercial:</div>
                                            <div>{con.politicanombre}</div>
                                        </div><br/>
                                    </div>
                                : <div>Usuario desconectado, Anulado o Baja moroso</div>}
                            </div>    
                        )) : <div style={{ marginBottom: 10}}>No existen datos</div>
                    }
                </details>

                {/* ORDENES */}
                <details className={`' font-semibold text-xs truncate'`} style={{ fontSize: 12}}>
                    <summary className={`' font-semibold text-xs text-teal-500   truncate'`} style={{fontSize: 14, marginBottom: 10, cursor: 'pointer'}}>Ordenes Pendientes</summary>
                    {dataDetails && dataDetails.ordensrv[0] ?
                        dataDetails.ordensrv.map((ord, index)=>(
                            <div key={index}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Número de orden:</div>
                                    <div>{ord.ordennro}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Generada por:</div>
                                    <div>{GENERADO[ord.ordengen]}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Tipo de orden:</div>
                                    <div>{ORDENTPO[ord.ordentpo]}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Fecha de ingreso:</div>
                                    <div>{ord.ordenfing.replace('00:00:00','')}</div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Estado de la orden:</div>
                                    <div>{ORDENSTS[ord.ordensts]}</div>
                                </div>
                                <div>
                                    <div style={{marginRight: 8}} className={`' font-semibold text-xs text-teal-500   truncate'`}>Descripción:</div>
                                    <div>{ord.descripcion}</div>
                                </div>
                                <Btn block={true} label="Ver orden" _handleBtnClick={()=>handleOrder(ord.ordennro)}/><br/><br/><br/>
                            </div>
                        )) : <div style={{ marginBottom: 10}}>No existen datos</div>
                    }
                </details>

            </div>
            <div className='flex-1 ml-3'>
                {data && data.length > 0 && 
                    <MainMap
                        heatmap={false}
                        recenter={{ lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lng) }}
                    // details={data && <ClienteDetails cliente={data} />}
                    >
                        <ClienteMarker
                            icon="user"
                            lat={parseFloat(data[0].lat)}
                            lng={parseFloat(data[0].lng)}
                            text={data[0].nombre}
                            size="6" />
                    </MainMap>}
            </div>

        </div>
    </div>

)}