const logger = (msg) => {
    try {
        fetch(`${process.env.REACT_APP_CUO_API}/tvf/prod/logs`,
            {
                method: 'POST',
                body: JSON.stringify({msg: msg}),
                headers: {
                    'Authorization': process.env.REACT_APP_CUO_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
        return true
    } catch (e) {
        console.log('Hubo un error => ', e)
        return false
    }
}

export default logger