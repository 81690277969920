import React, { useContext, useEffect, useState } from 'react';

import AgendaProgress from './AgendaProgress'
import BtnPausar from './BtnPausar'
import ConfigContext from './../../contexts/ConfigContext'
import FormSelect from './../Forms/FormSelect'
import IdBadge from './../Badges/IdBadge'
import LlamandoBadge from './../Badges/LlamandoBadge'
import MainFrame from './../Layout/MainFrame'
import MovilBadge from './../Badges/MovilBadge'
import Padding from '../Layout/Padding';
import TecnicoBadge from './../Badges/TecnicoBadge'
import Ts from './../Global/Ts'
import axios from 'axios';
import useFetch from '../../hooks/useFetch';

export default () => {

	let context = useContext(ConfigContext)

	const [fetchedTecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos`)
	const [fetchedMoviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles`)
	const [fetchedAgendas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod//cuo/app/agendas`)

	let [ forcedMovil , setForcedMovil ] =useState(null)
	let [ forcedAgenda , setForcedAgenda ] =useState(null)

	let [units, setUnits] = useState(null)
	let [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchUnits()
		const fetchInterval = setInterval(() => { fetchUnits() }, process.env.REACT_APP_INTERVAL_UNIDADES || 5000)
        return () => clearInterval(fetchInterval)    
	}, [])

	useEffect(()=>{
		if(forcedAgenda) {

			axios.post(`${process.env.REACT_APP_CUO_API}units/${forcedMovil}/asignar/${forcedAgenda}`,null,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
			.then(response => {
				setLoading(false)
				setUnits(response.data)
			})
		}
	},[forcedAgenda])

	const fetchUnits = () => {
		setLoading(true)
		axios.get(`${process.env.REACT_APP_CUO_API}units`,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
			.then(response => {
				setLoading(false)
				setUnits(response.data)
			})
	}
	const pausar = id => { axios.post(`${process.env.REACT_APP_CUO_API}units/${id}/pausar`,null,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}}).then((response) => setUnits(response.data)) }

	return <MainFrame>
		<Padding >
			<h1 className=" text-lg mb-3">Unidades</h1>
			{units ?
				<table className={`w-full  text-left`}>
					<thead>
						<tr className="text-xs  py-5">
							{['','Movil', 'Tecnicos', 'Aceptada', 'Comenzada', 'Progreso',  'Agenda Actual', 'Orden Actual', ''].map((th,index) => <th key={th+index}>{th}</th>)}
						</tr>
					</thead>
					<tbody>
						{units.map(unit =>
							<tr key={unit.id} className={`border-b border-gray-500 ${unit.disponible === 1 ? 'opacity-100' : 'opacity-50'}`}>
								{/* <td><IdBadge id={unit.id} /></td> */}
								<td className="py-2 px-1">{unit.calling === 1 && <LlamandoBadge />}</td>
								<td className="py-2 px-1">{unit.movil_id &&<MovilBadge fetchedMoviles={fetchedMoviles} movilAsignado={unit.movil_id} />}</td>
								<td className="py-2 px-1">
									{unit.tecnico1_id && <TecnicoBadge fetchedTecnicos={fetchedTecnicos} tecnico_id={unit.tecnico1_id} small/> }<br />
									{unit.tecnico2_id && <TecnicoBadge fetchedTecnicos={fetchedTecnicos} tecnico_id={unit.tecnico2_id} small/> }
								</td>
								<td className="py-2 px-1 text-xs ">{unit.aceptada && <Ts ts={unit.aceptada} color='green' />}</td>
								<td className="py-2 px-1 text-xs ">{unit.comenzada && <Ts ts={unit.comenzada} color='purple' />}</td>
								<td className="py-2 px-2 w-40"> {unit.disponible === 1 && <AgendaProgress step={unit.step} legend /> } </td>
								{/* <td className="py-2 px-1">{unit.cliente_nro}</td> */}
								<td className="py-2 px-1">
									{unit.disponible ? unit.agenda_nro && <IdBadge id={unit.agenda_nro} /> :
										fetchedAgendas && 
											<select className="text-xs  " onChange={(e)=>{setForcedAgenda(e.target.value);setForcedMovil(unit.movil_id)}}>
												{ Object.values(fetchedAgendas).map(agenda=> <option key={agenda[0].agenda_nro} value={agenda[0].agenda_nro}>{agenda[0].agenda_nro} {agenda[0].orden_gen_nombre}</option>) }
											</select>
										}
								</td>
								<td className="py-2 px-1">{unit.orden_nro && <IdBadge id={unit.orden_nro} />}</td>
								<td className="py-2 px-1"><BtnPausar _onClick={() => pausar(unit.movil_id)} unit={unit} /> </td>
							</tr>
						)}
					</tbody>
				</table>
				: 'Cargando...'}
		</Padding>
	</MainFrame>
};
