import React, { useContext } from 'react';
import SidebarLink from './SidebarLink'
import SidebarSwitcher from './SidebarSwitcher';
import Btn from '../Forms/Btn';
import { useHistory, withRouter } from 'react-router';
import logger from '../../utils/logger';
import ConfigContext from '../../contexts/ConfigContext';
const SideBar = ({ options }) => {
    const history = useHistory()
    const {roles} = useContext(ConfigContext)
    const logOut = () => {
        logger(`Cierre sesion ${ JSON.parse(localStorage.getItem('user')).userprincipalname[0] } `)
        localStorage.removeItem('user')
        localStorage.removeItem('logged')
        window.location.href = '/';
    }
    const SidebarSeparator = () => <div className={`w-full my-1 bg-gray-500 h-px items-center flex justify-center text-shadow-lg opacity-50`}> </div>

    const SidebarOptions = () => {
        const filtered = options.filter(opt => opt.label && opt.role.split(',').some(r=> roles.indexOf(r) >= 0))
        let lastGroup = 1
        let add = false
        return filtered
            .sort((a, b) => a.grupo - b.grupo)
            .map((opt, index) => {
                add = (lastGroup != opt.grupo)
                if (add) lastGroup = opt.grupo;
                return <React.Fragment key={index}>
                    {add && <SidebarSeparator />}
                    <SidebarLink key={index} icon={opt.icon} to={opt.path} title={`${opt.label}`} active={history.location.pathname == opt.path} /> 
                </React.Fragment>
        });
        
    }

    return <aside className={`print-hidden  h-full scroll  p-2 md:p-5 flex flex-col  md:items-start space-y-2 w-16 md:w-56 shadow-lg text-sm font-normal fixed z-10  `}>
        <SidebarSwitcher />
        {options && <SidebarOptions />}
        <div className='mt-auto self-center '>
            <Btn block icon='sign-out-alt' label='Cerrar sesión' _handleBtnClick={() => logOut()} />
        </div>
    </aside>
}

export default withRouter(SideBar);