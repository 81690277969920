import React, { useEffect, useRef, useState } from 'react'
import loginGuest from '../utils/loginGuest'
import login from '../utils/login'
import LogoSvg from '../components/Global/LogoSvg'

const LoginForm = ({ setLogged, setUser, setEmpresa, setSucursal }) => {

	let [error, setError] = useState()
	let [logging, setLogging] = useState(false)
	let [username, setUsername] = useState()
	let [password, setPassword] = useState()
	let [loginExterno, setLoginExterno] = useState(false)
	const themeRef = useRef()

	useEffect(() => {
		if (localStorage.getItem('theme')) { themeRef.current = localStorage.getItem('theme') } else {
			themeRef.current = 'dark'
		}
		if (localStorage.getItem('logged')) {
			setLogged(localStorage.getItem('logged'))
		}
		if (localStorage.getItem('user') && localStorage.getItem('logged') !== 'true') {
			localStorage.removeItem('logged')
			localStorage.removeItem('fend')
			localStorage.removeItem('user')
		}
	}, [])

	const notLogged = (msg, e) => {
		e && console.log(e)
		setError(msg)
		setLogging(false)
		setPassword('')
	}

	const handleClickLogin = () => {
		loginExterno
			? loginGuest(username, password, setUser, setLogging, setLogged, notLogged, setEmpresa, setSucursal)
			: login(username, password, setUser, setLogging, setLogged, notLogged, setEmpresa, setSucursal)
	}

	return <div className={`theme-${themeRef.current} w-full h-screen flex flex-col items-center justify-center p-5`}>
		<LogoSvg width={200} height={143} />
		<h3 className={`font-bold uppercase text-xl   my-5  text-teal-500  `} > Centro Único Operativo </h3>
		<div className='  flex flex-col items-stretch w-full sm:w-64 gap-3 text-center' >
			<div className='flex w-full gap-2 mb-5'>
				<button className={`text-xs flex-1  `} style={{ backgroundColor: !loginExterno && "#38b2ac" }}
					onClick={() => setLoginExterno(false)}>
					Acceso Corporativo
					</button>
				<button className={`text-xs flex-1 `} style={{ backgroundColor: loginExterno && "#38b2ac" }}
					onClick={() => setLoginExterno(true)}>
					Acceso Externa
					</button>
			</div>


			<input onChange={e => setUsername(e.target.value)} className='text-center' placeholder={!loginExterno ? 'Su email empresarial' : 'Nombre de Usuario'} value={username} />
			<input onChange={e => setPassword(e.target.value)} className='text-center' placeholder='Contraseña' value={password} type='password' />

			<button onClick={handleClickLogin} disabled={!username || !password} >
				{!logging ? 'Ingresar' : 'Cargando...'}
				<i />
			</button>
			<p className={`text-sm text-center    text-red-500  `} >{error}</p>
		</div>
	</div>

}

export default LoginForm