import { useContext, useEffect, useState } from 'react'

import ConfigContext from '../contexts/ConfigContext'
import moment from 'moment'

const useFetchLogs = (props) => {
    const context = useContext(ConfigContext)
    const [listLogs, setListLogs] = useState()
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const getListLogs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/logs/${date}`, { method: 'GET', headers: {'Authorization': process.env.REACT_APP_BRIDGE_API_KEY}})
            const responseJSON = await response.json()
            setListLogs(responseJSON)
            console.log(responseJSON)
        } catch (e) {
            console.log('No hay registros')
            setListLogs([])
        }
    }

    useEffect(() => {
        date && getListLogs()
    }, [date])

    return { listLogs, getListLogs, setDate, date }
}

export default useFetchLogs
