import React from 'react';
import useEnv from '../../hooks/useEnv';
import {useHistory} from 'react-router-dom'
import LogoSvg from '../Global/LogoSvg';

const SidebarLogo = () => {

    const history = useHistory()
    const primaryColor = useEnv('COLOR')
    const appIcon = useEnv('ICON')

    return ( <LogoSvg width={50} height={20} onClick={() => history.push('/')}/> )
}


export default SidebarLogo