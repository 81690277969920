import React, {useState, useEffect, useContext} from 'react';
import FloatingWindow from "../Global/FloatingWindow";
import Cargando from "../Global/Cargando";
import ConfigContext from '../../contexts/ConfigContext';

const MainMapSearchBox = ({ googleMaps, gmap}) => {

    const [address,setAddress] = useState('')
    const [marker,setMarker] = useState(false)
    const [belongs,setBelongs] = useState(null)
    const context = useContext(ConfigContext)
    const initAutocomplete = () => {
        var defaultBounds = new googleMaps.LatLngBounds(
            new googleMaps.LatLng(-53.4426, -68.7806),
            new googleMaps.LatLng(-55.0472, -67.1217));

        var input = document.getElementById('search-box-input');
        var searchBox = new googleMaps.places.SearchBox(input,{ bounds: defaultBounds });

        gmap.addListener('bounds_changed', () => { searchBox.setBounds(gmap.getBounds()); });

        var markers = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', function() {
          var places = searchBox.getPlaces();
          if (places.length == 0) { return; }
            
          
          setBelongs(null)
          setMarker(true)
          // Clear out the old markers.
          markers.forEach(function(marker) { marker.setMap(null); });
          markers = [];

          // For each place, get the icon, name and location.
          var bounds = new googleMaps.LatLngBounds();
          places.forEach(function(place) {
            if (!place.geometry) {
              console.log("Returned place contains no geometry");
              return;
            }
            var icon = { url: place.icon, size: new googleMaps.Size(32, 32), scaledSize: new googleMaps.Size(32, 32) };
            markers.push(new googleMaps.Marker({ map: gmap, icon: icon, title: place.name, position: place.geometry.location }));

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          gmap.fitBounds(bounds);
        });
    
    }

    useEffect(()=> { if(googleMaps && gmap){ initAutocomplete() } },[gmap, googleMaps])

    const fetchBelongs = async () => {
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/utils/belongs/address/${address}`,{headers: {'Authorization': process.env.REACT_APP_BRIDGE_API_KEY}})
        // const response = await fetch(`http://192.168.1.240:9009/api/${context.empresa}/prod/utils/belongs/address/${address}`)
        // const response = await fetch(`http://127.0.0.1:3900/api/${context.empresa}/prod/utils/belongs/address/${address}`)
        const responseJSON = await response.json()
        setBelongs(responseJSON)
    }
    const handleEnter = e => {
      if (e.key ==='Enter'){ fetchBelongs() }
    }
    useEffect(()=>{
        marker && 
        fetchBelongs()
    },[marker])

    return  <FloatingWindow  icon='road' title='Direccion'> 
                <input  id='search-box-input' type='text' placeholder='Ingrese una dirección' onChange={e=>setAddress(e.target.value)} onKeyPress={handleEnter}/> 
                {belongs ? 
                    <ul className='mt-3'>
                        <li className='flex items-center my-1'><div className='w-1/3 font-semibold text-xs text-teal-500'>Barrio</div> <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.barrios }</div></li>
                        <li className='flex items-center my-1'><div className='w-1/3 font-semibold text-xs text-teal-500'>Zona</div> <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.zonas }</div></li>
                        <li className='flex items-center my-1'><div className='w-1/3 font-semibold text-xs text-teal-500'>Nodo</div> <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.nodos }</div></li>
                        <li className='flex items-center my-1'><div className='w-1/3 font-semibold text-xs text-teal-500'>Zona Operativa</div> <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.operativas }</div></li>
                        { belongs.perteneceA.supercanal && <li className='flex items-center my-1'><div className='w-1/3 font-semibold text-xs text-teal-500'>Supercanal</div> <div className='w-2/3 font-sm font-semibold'>{ belongs.perteneceA.supercanal }</div></li> }

                    </ul>  : marker && <div className='flex flex-col items-center my-1'><Cargando /><small className='opacity-50'>Detectando pertenencias...</small></div>
            } 
            </FloatingWindow>
}

export default MainMapSearchBox;