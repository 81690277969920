import React, { useContext, useRef, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding'
import useFetch from '../../hooks/useFetch'
import useDelete from '../../hooks/configuracion/useDelete'
import {useHistory} from 'react-router-dom'

const RolesConfiguracion = (props) => {
    const context = useContext(ConfigContext)
    const grupoRef = useRef(null)
    const rolRef = useRef(null)

    const history = useHistory()
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/roles`)

    const [ deleteItem ] = useDelete('roles', '/configuracion/roles')
    const [form, setForm] = useState()

    const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value })

    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/roles`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST',  body: JSON.stringify(form), headers })
        setForm()
        grupoRef.current.value = ''
        rolRef.current.value = ''
        refreshData()
    }
    
    const handleClickVolver = () => {
        history.push('/configuracion')
    }

    const rolesWindow = ['G_CUO_ADM', 'G_CUO_CON', 'G_CUO_OPE', 'G_CUO_INF', 'G_CUO_GER', 'G_CUO_GUEST', 'G_CUO_DIR']
    const roles = ['consultas','operador','tecnico','dev','gerencia','directorio','guest']

    const handleClickDelete = (rol) => {deleteItem(refreshData, rol.rol ,rol.id)}

    let rolesFilter = []
    let [filtrado,setFiltrado] = useState()

    React.useEffect(() => {
        form && data && data.filter(item => item.grupo === form.grupo).map(item => {rolesFilter.push(item.rol)})
    },[data,form])

    React.useEffect(() => {
        form && rolesFilter.length >= 1 && setFiltrado({arrayRoles: rolesFilter, grupo: form.grupo})
    },[rolesFilter])

    const filterRoles = () => {
        return roles.filter(rol => form && filtrado && form.grupo === filtrado.grupo ? !filtrado.arrayRoles.some(r=> rol.indexOf(r) >= 0) : form && form.grupo && rol)
    }

    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Roles</h1>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        <tr>
                            <td className='py-3'>
                                <div className='flex flex-col'>
                                    <label>Roles de Windows</label>
                                    <select ref={grupoRef} onChange={handleChange} name='grupo'>
                                        <option value=''>Seleccione un rol</option>
                                        {rolesWindow
                                            .map((rwindow,index) => (
                                                <option key={index} value={rwindow}>{rwindow}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td className='py-3'>
                                <div className='flex flex-col'>
                                    <label>Roles de CUO</label>
                                    <select ref={rolRef} onChange={handleChange} name='rol'>
                                        <option value=''>Seleccione un rol</option>
                                        {filterRoles()
                                            .map((rol,index) => (<option key={index} value={rol}>{rol}</option>))
                                        }
                                    </select>
                                </div>
                            </td>
                            
                            <td className='text-right'> 
                                <button 
                                    onClick={handleStore}
                                    className={`${!form ? 'pointer-events-none' : !form.grupo || !form.rol && 'pointer-events-none'}`}
                                >
                                    <i className={`fa fa-paper-plane mr-2 `} /> 
                                    Asignar
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td><label className='text-lg text-gray-600'>Roles de Windows</label></td>
                            <td><label className='text-lg text-gray-600'>Roles de CUO</label></td>
                        </tr>
                        
                        
                        {data && [...new Set(Object.values(data).map(item => item.grupo))].map((item,index) => <tr key={index}>
                            <td>{item}</td>
                            <td className='flex gap-2'>
                                {data
                                    .filter(rol => rol.grupo === item)
                                    .map((rol,indexRol) => (
                                        <p className='text-xs text-gray-600' key={indexRol}>
                                            {rol.rol}
                                            <i 
                                                className='fa fa-times ml-1 text-red-500 cursor-pointer'
                                                onClick={() => handleClickDelete(rol)}
                                            />
                                        </p>
                                ))}
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default RolesConfiguracion
