import React, { useContext } from 'react'
import ConfigContext from '../../contexts/ConfigContext'

const MapCursor = () => {
    let context = useContext(ConfigContext)
    return <div  className="relative z-40">
            <div style={{ width: '32px', height: '2px', marginLeft: '-15px' }} className={`${context.theme === 'dark' ? 'bg-white' : 'bg-black'} absolute opacity-50 `}> </div>
            <div style={{ width: '2px', height: '32px', marginTop: '-15px' }} className={`${context.theme === 'dark' ? 'bg-white' : 'bg-black'} absolute opacity-50 `}> </div>
            <div style={{ width: '20px', height: '20px', marginTop: '-9px', marginLeft: '-9px' }} className={`${context.theme === 'dark' ? 'border-white' : 'border-black'} border rounded-full absolute `}> </div>
        </div>
}

export default MapCursor