import GoogleMapReact from 'google-map-react'
import React, { useContext, useEffect, useState } from 'react'
import ConfigContext from '../../contexts/ConfigContext'
import useFetch from '../../hooks/useFetch'
import CONSTS from '../../consts/consts'
import MAPSTYLES from '../../styles/mapstyles'
import LayerSelector from '../../components/Global/LayerSelector'

export default function CompetenciaMap() {

    const context = useContext(ConfigContext)
    const [coords] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/coords/extras/sion`)
    let [googleMaps, setGoogleMaps] = useState(null)
    let [gmap, setGMap] = useState(null)
    let [center, setCenter] = useState({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng })
    // let [zoom, setZoom] = useState(CONSTS[context.empresa].zoom)
    const [layers] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/mapLayers`)
    let [activeLayer, setActiveLayer] = useState(null)
    let [kml, setKml] = useState(null)
    
    const BOUNDS_RG = {
        east: -67.49234,
        north: -53.698455,
        south: -53.90792,
        west: -67.88608
    }
    const BOUNDS_TOL = {
        east: -67.1571056,
        north: -54.4707852,
        south: -54.5577852,
        west: -67.2671056
    }
    const BOUNDS_USHU = {
        east: -68.1398226,
        north: -54.7268581,
        south: -54.8868581,
        west: -68.4978226
    }

    const applyLayers = (lay) => {
        if (lay === activeLayer) {
            setActiveLayer(null)
            kml.setMap(null)
        } else {
            setActiveLayer(lay)
            kml && kml.setMap(null)
            let url = `https://bridge.tvfuego.com.ar/kmz/${lay}.kmz`
            setKml(new googleMaps.KmlLayer({ url, gmap }))
        }
    }
    useEffect(() => { kml && kml.setMap(gmap) }, [kml, gmap])
    
    // useEffect(() => {
    //     googleMaps && setInfowindow(new googleMaps.InfoWindow());
    // }, [googleMaps])
    useEffect(() => setCenter({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng }), [context.empresa])

    

    const mapOptions = {
        styles: MAPSTYLES[context.theme],
        backgroundColor: 'none',
        zoomControl: true,
        fullscreenControl: true,
        streetViewControl: true,
        restriction: {
            latLngBounds: context.empresa === 'tvf' ? BOUNDS_RG : context.empresa === 'uv' ? BOUNDS_USHU : BOUNDS_TOL,
            strictBounds: false
        },
    };
 
    const initMap = (map, maps) => { 
        setGoogleMaps(maps) 
        setGMap(map); 
    }
    useEffect(() => {
        if( gmap ){
            console.log(gmap)
            if (coords) {
                console.log(coords)
                let geojson = {
                    "name":"NewFeatureType",
                    "type":"FeatureCollection",
                    "features":[]
                };

                geojson.features = coords.map( coord => {
                    return {
                        "type":"Feature",
                        "geometry":{
                            "type":"Point",
                            "coordinates":[ Number(coord.lat),Number(coord.lng)]
                        }
                    }
                })
                console.log('geojson' ,geojson)

                gmap.data.addGeoJson(geojson);
                gmap.data.setStyle({
                    icon: {
                        url: `http://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png`
                    },
                });
            }
        }
    }, [gmap, coords])
    const handleOnGoogleApiLoaded = ({ map, maps }) => initMap(map, maps)


    return (
        <main style={{ height: '100vh' }} className="overflow-hidden w-full relative h-full md:min-h-screen">
            <LayerSelector layers={layers} activeLayer={activeLayer} applyLayers={applyLayers} />

        <GoogleMapReact
                options={mapOptions}
                center={center}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleOnGoogleApiLoaded}
                bootstrapURLKeys={{ key: context.gmapApiKey }}
                defaultZoom={13} >

            </GoogleMapReact>
        </main>

    )
}
