import React, { useContext, useEffect, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding'
import moment from 'moment'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import useFetchMonitoreo from '../../hooks/useFetchMonitoreo'
import {useHistory} from 'react-router-dom'

const MensajesConfiguracion = (props) => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/mensajes`)
    
    const monitoreo = useFetchMonitoreo(true)
    const [ deleteItem ] = useDelete('mensajes', '/configuracion/mensajes')

    const [openForm, setOpenForm] = useState(false)
    const [modeUpdate, setModeUpdate] = useState(false)
    const [updateId, setUpdateId] = useState()
    const [form, setForm] = useState()

    const handleNueva = () => {
        setModeUpdate(false)
        setOpenForm(!openForm)
        setForm({ ...form, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0], _method:'post'})
    }
    const handleChangeTitulo = (e) => setForm({ ...form, titulo: e.target.value })
    const handleChangeHorario = (e) => setForm({ ...form, horario: e.target.value })
    const handleChangeNodo = (e) => setForm({ ...form, nodo: e.target.value })
    const handleChangeFecha = (e) => setForm({ ...form, fecha: e.target.value })
    const handleChangeContenido = (e) => setForm({ ...form, contenido: e.target.value })
    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/mensajes`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleUpdate = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/mensajes/${updateId}`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    const handleClickEditar = (id,titulo,contenido,nodo,fecha,horario) => {
        setOpenForm(!openForm) 
        setModeUpdate(true)
        setUpdateId(id)
        setForm({...form, _method:'put', titulo:titulo, contenido:contenido, nodo:nodo, fecha:fecha, horario:horario, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0],})
    }
    
    const handleClickVolver = () => {
        history.goBack()
    }
    
    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Mensajes</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        {openForm &&
                            <tr>
                                <td className='py-3'><input onChange={handleChangeTitulo} defaultValue={modeUpdate === true ? form.titulo : ''} placeholder='Titulo' /></td>
                                <td className='py-3'><select onChange={handleChangeNodo} defaultValue={modeUpdate === true ? form.node : ''}>{monitoreo && Object.entries(monitoreo).map(([nodo,datos],index) => ( <option key={index} value={nodo}>{nodo}</option>))}</select></td>
                                <td className='py-3'> <input onChange={handleChangeFecha} defaultValue={modeUpdate === true ? form.fecha : ''} type='date' /> </td>
                                <td className='py-3'><input onChange={handleChangeHorario} defaultValue={modeUpdate === true ? form.horario : ''} placeholder='Horario: 23:00' /></td>
                                <td className='py-3'> <input onChange={handleChangeContenido} defaultValue={modeUpdate === true ? form.contenido : ''} placeholder='Contenido' /> </td>
                                
                                <td className='text-right'> <button onClick={modeUpdate === true ? handleUpdate : handleStore}><i className={`fa fa-${modeUpdate === true ? 'save' : 'paper-plane'} mr-2 `} /> {modeUpdate === true ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        
                        {data.map(item => <tr key={item.id}>
                            <td className='text-lg text-gray-600'>{item.titulo && item.titulo}</td>
                            <td className='text-xs text-gray-600'>{item.contenido && item.contenido}</td>
                            <td className='text-right text-xs text-gray-600'>{item.nodo && `nodo: ${item.nodo}`}</td>
                            <td className='text-sm text-blue-600'>{item.fecha && moment(item.fecha).format('DD-MM-YYYY')}</td>
                            <td className='text-sm text-blue-600'>{item.horario && item.horario}</td>
                            <td className='text-right'>
                                <button onClick={() => handleClickEditar(item.id,item.titulo,item.contenido,item.nodo,item.fecha,item.horario)} > <i className='fa fa-edit'/> Editar</button>
                                <button onClick={() => deleteItem(refreshData, item.titulo ,item.id)}> <i className='fa fa-trash'/> Eliminar</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default MensajesConfiguracion
