import React, {useContext} from 'react'
import { useHistory } from 'react-router'
import Cargando from '../Global/Cargando'
import useFetch from '../../hooks/useFetch'
import Padding from '../Layout/Padding'
import EntityBadge from '../Badges/EntityBadge'
import ActionButtons from '../Global/ActionButtons'
import dayjs from 'dayjs'
import SigaButton from '../Global/SigaButton'
import ConfigContext from '../../contexts/ConfigContext'

const TecnicosIndex = () => {
    const history = useHistory()
    const context = useContext(ConfigContext)
    const [tecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos`)

    const handleGoTo = (id) => history.push(`/tecnicos/${id}`)

    const [csvData,setCSVDATA] = React.useState([])
    
    React.useEffect(() => {
         tecnicos && tecnicos
            .map((tecnico,index) => setCSVDATA(csvData => {
                return [...csvData,{ID: tecnico.id, NOMBRE: tecnico.nombre, CELULAR: tecnico.cel, EMAIL: tecnico.email }]
            }))
     },[tecnicos])

    return <Padding>
        <div className='flex items-center justify-between'>
            <h1>Técnicos</h1>
            { csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`tecnicos-${dayjs().format('DD-MM-YYYY')}`}/>}
         </div>
        {tecnicos ? <table className="w-full">
            <tbody>
                {tecnicos.map(tecnico =>
                    <tr>
						<td className='w-10'><SigaButton tipo='tecnico' nro={tecnico.id} /></td>
                        <td className='capitalize'><EntityBadge type='tecnico' name={tecnico.nombre.toLowerCase()} detail={tecnico.usr} /></td>
                        
                        <td className='text-sm'>{tecnico.cel}</td>
                        <td className='text-sm lowercase'>{tecnico.email}</td>
                        <td className='text-right text-xs'>
                            <button onClick={() => handleGoTo(tecnico.id)}>Ver<i className='fa fa-angle-right ml-2' /></button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table> : <Cargando />}
    </Padding>
}


export default TecnicosIndex