import React, { useState, useEffect, useContext } from 'react';
import MainMap from '../MainMap'
import FloatingWindow from '../Global/FloatingWindow';
import BasicMarker from '../Markers/BasicMarker';
import useFetch from '../../hooks/useFetch';
import ConfigContext from '../../contexts/ConfigContext';
import OrdenesMarker from '../Markers/OrdenMarker';

const OrdenesSearch = () => {
    let [nro, setNro] = useState()
    const context = useContext(ConfigContext)
    
    const [data,fetchData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/ordenes/nro/${nro}`)

    useEffect(() => {
        if (data && data[0]) {
            if (data[0].cliente_lat) {
                data[0].lat = data[0].cliente_lat
                data[0].lng = data[0].cliente_lng
            }
        }
    }, [data])

    return <MainMap forms={SearchForm(nro, setNro, fetchData)} >
        <div lat={'-67.7024166'} lng={'-53.7876047'} label='test' key='123' className='bg-black p-4 rounded'>test</div>
        {data && data[0] && <OrdenesMarker orden={data[0]} lat={parseFloat(data[0].lng)} lng={parseFloat(data[0].lat)} />}
    </MainMap>


}
const SearchForm = (nro, setNro, fetchData) => {
    const handleKeyPress = e => {
        if (e.key === 'Enter') { fetchData() }
    }
    return <FloatingWindow padding icon='search' title='Orden Nro' pos="mt-16">
        <input
            className='input-lg'
            type="text"
            placeholder='Buscar...'
            value={nro}
            onChange={e => setNro(e.target.value)}
            onKeyPress={e => handleKeyPress(e)}
        />
        <label className='text-xs mt-2'>Ingrese el numero de Orden y presione <strong>ENTER</strong></label>
    </FloatingWindow>
}

export default OrdenesSearch