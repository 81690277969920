import React from 'react';

export default ({ title, content, closeModal }) => {
    return <div className=' inset-0 flex items-center justify-center absolute w-full min-h-screen z-30' onClick={() => closeModal()} style={{ background: `rgba(0,0,0,.6)` }} >
        <div className=' rounded-lg shadow p-5  sm:w-1/2 animated fadeInDown slow h-1/2'>
            <div className='flex justify-between items-center '>
                {title}
                <button onClick={() => closeModal()}><i className='fa fa-times'/></button>
            </div>
            {content}
        </div>
    </div>
};
