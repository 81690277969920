export default {
   escala : {
      0: { color: 'pink' },
      1: { color: 'purple' },
      2: { color: 'indigo' },
      3: { color: 'blue' },
      4: { color: 'teal' },
      5: { color: 'green' },
      6: { color: '' },
      7: { color: 'orange' },
      8: { color: 'red' },
      9: { color: 'red' },
      10: { color: 'red' },
      11: { color: 'red' }
  },
  turnos : {
    '0': {  
      label:'Madrugada',
      color: 'red',
      color_hex: '#fc8181',
      icon: 'coffee',
      size: '10'
    },
    '1': {  
      label:'Mañana',
      color: 'yellow',
      color_hex: '#ECC94B',
      icon: 'coffee',
      size: '10'
    },
    '2': {  
      label:'Tarde',
      color: 'teal',
      color_hex: '#38B2AC',
      icon: 'sun',
      size: '10'
    },
    '3': {  
      label:'Vespertino',
      color: 'blue',
      color_hex: '#4299E1',
      icon: 'moon',
      size: '10'
    },
    '4': {  
      label:'Medianoche',
      color: 'indigo',
      color_hex: '#667eea',
      icon: 'moon',
      size: '10'
    },
    '5': {  
      label:'Noche',
      color: 'purple',
      color_hex: '#9f7aea',
      icon: 'moon',
      size: '10'
    },
    '6': {  
      label:'Alba',
      color: 'pink',
      color_hex: '#ed64a6',
      icon: 'moon',
      size: '10'
    },
  },
  tipos: {
    I: { title: "Instalación", color: "blue", icon: "plug" },
    D: { title: "Desconexion", color: "green", icon: "power-off" },
    S: { title: "Servicio", color: "indigo", icon: "briefcase" },
    R: { title: "Reclamo", color: "red", icon: "wrench" },
    K: { title: "Corte", color: "orange", icon: "cut" },
    U: { title: "Reconexión", color: "purple", icon: "redo-alt" },
    E: { title: "Retiro", color: "pink", icon: "car-side" }
  },
  estados: {
    P: { title: "Pendiente", color: "blue", icon: "clock" },
    A: { title: "Agendada", color: "pink", icon: "clipboard" },
    R: { title: "Reagendar", color: "orange", icon: "sync" },
    S: { title: "Suspendida", color: "gray", icon: "trash" },
    E: { title: "Emitida", color: "green", icon: "check" },
    F: { title: "Finalizada", color: "purple", icon: "check" },
    C: { title: "Cancelada", color: "red", icon: "times" },
    K: { title: "Corte de Señal", color: "teal", icon: "scissors" },
    N: { title: "Reconexión", color: "indigo", icon: "plug" },
    I: { title: "Incumplida", color: "blue", icon: "clock" },
    V: { title: "Vencida", color: "orange", icon: "calendar" },
    T: { title: "Terminada", color: "purple", icon: "check" }
  },
  tiposColores: {
    I: "blue",
    D: "green",
    S: "indigo",
    R: "red",
    K: "orange",
    U: "purple",
    E: "pink"
  },
  estadosColores: {
    P: "blue",
    A: "pink",
    R: "orange",
    S: "gray",
    E: "green",
    F: "purple",
    C: "red",
    K: "teal",
    N: "indigo",
    I: "yellow"
  },
  generado: {
    N: "NORMAL",
    Z: "MUDANZA",
    M: "MOROSIDAD",
    Q: "CAMBIO",
    S: "SERVICIO",
    C: "CONTRATO",
    T: "NOSE..."
  },
  ordentpo: {
    R: "RECLAMO",
    I: "INSTALACION",
    D: "DESCONEXION",
    E: "RETIRO",
    S: "SERVICIO",
    K: "CORTE",
    U: "RECONEXION"
  },
  ordensts: {
    R: "REAGENDADA",
    P: "PENDIENTE",
    A: "AGENDADO",
    S: "SUSPENDIDA",
    E: "EMITIDA",
    C: "CANCELADA",
    F: "FINALIZADA",
    K: "CORTE DE SEÑAL"
  },
  productotpo: {
    B: "BASICO",
    D: "DIGITAL",
    G: "GENERAL",
    I: "INTERNET",
    Z: "BASICO GPON",
    "S/C": "nose"
  },
  estadotpo: {
    X: "Desconectado",
    C: "Conectado",
    K: "Corte de señal",
    I: "Ingresado",
    P: "Pendiente",
    B: "Baja pendiente",
    M: "Baja moroso",
    E: "Emitido",
    A: "Anulado"
  },
  facturas: {
    P: 'PAGA',
    I:'IMPAGA',
    L:'PARCIAL',
    X:'ANULADA',
    E:'ENVIADA'
  },
  productoTipos: {
    B: {
      color: 'red',
      icon:'tv'
    },
    D: {
      color: 'green',
      icon:'digital-tachograph'
    },
    G: {
      color: 'yellow',
      icon:'user'
    },
    I: {
      color: 'blue',
      icon:'wifi'
    },
    C: {
      color: 'teal',
      icon:'wifi'
    },
    Z: {
      color: 'pink',
      icon:'server'
    },
    F: {
      color: 'orange',
      icon:'phone'
    }
  },
};
