import { useEffect, useState } from 'react';

import axios from 'axios'

const useGetActualStep = ( movilId ) => {
    let [actualStep, setActualStep] = useState(0)

    useEffect(() => {
        if (movilId) {
            _fetchMovilStep();
            const fetchInterval = setInterval(() => {_fetchMovilStep()}, process.env.REACT_APP_INTERVAL_PAUSED || 15000)
            return () => clearInterval(fetchInterval)
        }
    }, [movilId])

    const _fetchMovilStep = () => {
        axios
            .get(`${process.env.REACT_APP_CUO_API}units/${movilId}/step`,{headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
            .then(response => setActualStep(response.data))
            .catch(e => console.log('step ', e))
    }

    return actualStep
};

export default useGetActualStep