import React, { useContext, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import EntityBadge from './../Badges/EntityBadge'
import Padding from './../Layout/Padding'
import moment from 'moment'
import useFetch from '../../hooks/useFetch'

const Notificaciones = () => {
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/notificaciones`)

    const [moviles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/moviles`)
    const [agendas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendas/fecha/${moment().format('YYYY-MM-DD')}/1`)

    const ESTADOS = {
        0: { icon: 'check', color: 'gray' },
        1: { icon: 'check-double', color: 'gray' },
        2: { icon: 'check-double', color: 'green' },
    }

    const [openForm, setOpenForm] = useState(false)
    const [form, setForm] = useState()
    const handleNueva = () => setOpenForm(!openForm)
    const handleChangeTo = (e) => setForm({ ...form, to: e.target.value })
    const handleChangeMensaje = (e) => setForm({ ...form, mensaje: e.target.value })
    const handleChangeAgenda = (e) => setForm({ ...form, agenda: e.target.value })
    const handleStore = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/notificaciones`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }
    return (
        <Padding>
            <div className='flex items-center justify-between'>
                <h1>Notificaciones</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        {openForm && <tr>
                            <td className='py-3 pl-12'><select onChange={handleChangeTo}> <option value=''>Seleccione un movil</option> {moviles && moviles.map(({ nombre },index) => <option key={index} value={nombre}>{nombre}</option>)}</select></td>
                            <td className='py-3'><input onChange={handleChangeMensaje} placeholder='mensaje' /></td>
                            <td className='py-3'> <select onChange={handleChangeAgenda}> <option value=''>Seleccione una agenda</option> {agendas && Object.entries(agendas).filter(agenda => agenda[1][0].agenda_status == 'E').map((agenda, index) => <option key={index} value={agenda[0]}>{agenda[0]}</option>)} </select> </td>
                            <td className='text-right'> <button onClick={handleStore}><i className='fa fa-paper-plane mr-2 ' /> Enviar</button> </td>
                        </tr>}
                        {data.map(item => <tr key={item.id}>
                            <td><EntityBadge name={item.to.split('@')[0]} type='movil' detail={item.to} /></td>
                            <td className='text-xs opacity-75'>{item.mensaje}</td>
                            <td><EntityBadge size='sm' name={item.agenda} type='orden' detail={item.orden} /></td>
                            <td className='text-right'><i className={`fa fa-${ESTADOS[item.estado].icon} text-${ESTADOS[item.estado].color}-500`} /></td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default Notificaciones
