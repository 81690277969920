import {useEffect, useState, useContext} from 'react';
import useFetch from './useFetch';
import ConfigContext from '../contexts/ConfigContext'

export default (step) => {
    const context = useContext(ConfigContext)
    const [agendas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/agendas`)

    const [activeAgenda, setActiveAgenda] = useState(null);

	useEffect(() => {
        // console.log('agendas' , typeof agendas)
        if(step === 0 || step === 1) {

            if (agendas && Object.values(agendas).length > 0) {
                let rnd = Math.floor(Math.random() *Object.keys(agendas).length);
                let rndagenda = Object.keys(agendas)[rnd]; console.log('random id agenda' ,  rnd)
                setActiveAgenda(agendas[rndagenda])
                console.log('agendas[rndagenda]' , agendas[rndagenda])
            }
        }
    }, [agendas, step])
    
    return activeAgenda
};
