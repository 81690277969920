import React from 'react'

const RankingPuntaje =  ({puntaje}) => {
    let a = new Array(puntaje*10).fill(0)
    let colors = ['red','red','orange','orange','yellow','blue','purple','indigo','blue','teal','green']
    return <div className='ranking-progress w-16 flex px-px items-center rounded shadow  h-3'>
        {a.map( ()=> <div className={`h-2 w-1 mx-px bg-${colors[puntaje*10]}-600 rounded`}></div> ) }
    </div>
}

export default RankingPuntaje