import React, { useContext } from 'react';
import ConfigContext from '../../contexts/ConfigContext';

const SidebarThemeSelector = () => {
    let { theme, setTheme } = useContext(ConfigContext)

    const handleSwitch = e => {
        theme ==='light'? setTheme('dark') : setTheme('light')
    }

    React.useEffect(() => {
        theme && localStorage.setItem('theme',theme)
    }, [theme])

    return <i onClick={handleSwitch} className={`fa fa-${theme==='light' ?'moon':'sun'} ml-auto p-1 hover:bg-smoke cursor-pointer hover:opacity-75`}/>
};

export default SidebarThemeSelector