import React, { useContext } from 'react'
import Cargando from './../components/Global/Cargando'
import Details from './../components/Global/Details'
import Padding from './../components/Layout/Padding'
import useFetch from './../hooks/useFetch'
import ConfigContext from './../contexts/ConfigContext'
import { useHistory } from 'react-router'
import SigaButton from './../components/Global/SigaButton'

export default ({ match }) => {
    const context = useContext(ConfigContext)

    const [data,fetchData,error] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/${match.params.entity}/nro/${match.params.nro}`)
    
    let history = useHistory()
    const handleBack = () => history.goBack(-1)
    const handlePrint = () => window.print()

    return <Padding>
        <div className='flex items-center justify-between  '>
            <button onClick={handleBack}><i className='fa fa-angle-left mr-1' /> Volver</button>
            <h1>{match.params.entity} {match.params.nro}</h1>
            <button onClick={handlePrint}><i className='fa fa-print mr-1' />  Imprimir</button>
        </div>
        <SigaButton tipo={match.params.entity} nro={match.params.nro}/>
        {data ? <Details inline data={data[0]} /> : <Cargando />}
        {error && error}
    </Padding>


}
