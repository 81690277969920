import { useEffect, useState } from 'react'

const useFetch = (url, headers) => {
    const [data, setData] = useState()
    const [error, setError] = useState()

    const fetchData = async () => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': process.env.REACT_APP_BRIDGE_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ...headers
                }
            })
            const responseJSON = await response.json()
            setData(responseJSON)
        } catch (e) {
            console.log(`error: ${url} => `, e)
            setData()
            setError(e)
        }
    }

    useEffect(() => {
        url && fetchData()
    }, [url])

    return [data, fetchData, error]
}

export default useFetch
