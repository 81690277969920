import React from 'react';

const Details = ({data, inline, size}) => {
    return <>
        {data && Object.keys(data).map((k, index) =>
            <div className={` text-${size} ${inline && 'flex items-center justify-between mt-1 mb-3'}`}>
                <div className={`' font-semibold text-xs text-teal-500   truncate'`}>{Object.keys(data)[index] === "titular" ? Object.keys(data)[index] = "titular debito automatico" : Object.keys(data)[index]}</div>
                <div className={`'w-1/3 font-semibold text-right  '`}>{data[k]}</div>
            </div>
        )}
    </>
};


export default Details