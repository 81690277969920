import React from 'react'
import { useHistory } from 'react-router-dom'
import Padding from '../Layout/Padding';
const Configuracion = (props) => {

    const history = useHistory()

    const options = [
        { icon: 'copy', label: 'Informes', link: 'informes' },
        { icon: 'envelope', label: 'Mensajes', link: 'mensajes' },
        { icon: 'clock', label: 'Fechas', link: 'fechas' },
        { icon: 'link', label: 'Enlaces', link: 'enlaces' },
        { icon: 'map', label: 'Map Layers', link: 'layers' },
        { icon: 'users', label: 'Roles', link: 'roles' },
        { icon: 'bezier-curve', label: 'Rutas', link: 'rutas' },
    ]

    return (
        <Padding>
            <h1>Configuración</h1>
            <div className='w-full flex flex-wrap items-center justify-start'>
                {options.map(option => <BtnOption key={option.icon} icon={option.icon} label={option.label} onClick={() => history.push(`/configuracion/${option.link}`)} />)}
            </div>
        </Padding>
    )
};

const BtnOption = ({ label, icon, onClick }) => {
    return <button className='flex flex-col items-center justify-center h-32 w-32 mx-2' onClick={onClick}>
        <i className={`fa fa-${icon} mb-5 text-4xl text-teal-500`} />
        <span className=''>{label}</span>
    </button>
}
export default Configuracion
