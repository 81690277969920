import React, { useEffect, useState } from 'react'
import Padding from '../../components/Layout/Padding'
import dayjs from 'dayjs'
import Cargando from '../../components/Global/Cargando'
import { useHistory } from 'react-router'
export default function PbxCola(params) {
	const [eventos, setEventos] = useState()
	const history = useHistory()
	useEffect(() => {
		fetchEventos()
		const PbxInterval = setInterval(() => {fetchEventos()}, 15000)
		return () => clearInterval(PbxInterval)
	}, [])

    const fetchEventos = async () => {
		let today = dayjs().format('YYYY-MM-DD')
		try {
			const response = await fetch(encodeURIComponent(`${process.env.REACT_APP_BRIDGE_API}/pbx/stats/?entity=reports/agent_detail&start=${today}&end=${today}`), {
				headers: { 'authorization': process.env.REACT_APP_BRIDGE_API_KEY }
			})
			const responseJSON = await response.json()
			setEventos(responseJSON)
		} catch (e) { console.log(e) }
	}
	const colaId = params.match.params.id
    return (
        <Padding>
		<div className='flex items-center justify-between'>
            <button onClick={() => history.goBack()} ><i className='fa fa-arrow-left mr-2' />Volver</button>
            <h1 className='mx-auto'>{colaId}</h1>
		</div>
			{eventos ?  ([...new Set(eventos.rows
							.filter(evento=> evento.Cola.toLowerCase().includes(colaId.toLowerCase()))
							.sort(evento=> evento.Agente - evento.Agente)
							.map(evento=>evento.Agente))])
							.map( agente => {
								return <details>
									<summary className='cursor-pointer hover:shadow-outline py-2 px-1 rounded flex items-center justify-start'><i className='fa fa-angle-down mr-2'/> {agente}</summary>
									<table className='w-full'>
										<thead>
										<tr>
											<th>Cola</th>
											<th>Evento</th>
											<th>Duración</th>
											<th>Fecha</th>
										</tr>
										</thead>
										<tbody>
											{ eventos.rows
												.filter(evento=> evento.Cola.toLowerCase().includes(colaId.toLowerCase()))
												.filter(evento=> evento.Agente.includes(agente) )
												.map(ev=>
												<tr>
												{/* <td>{JSON.stringify(ev)} </td> */}
													<td>{ev.Cola}</td>
													<td>{ev.Evento}</td>
													<td>{ev['Duración']}</td>
													<td>{ev.Fecha}</td>
												</tr>
											)}	
										</tbody>
									</table>
								</details> }) : <Cargando />
						}	
        </Padding>
    )
}
