import React from 'react'
import FloatingWindow from '../Global/FloatingWindow'
import Label from '../Typo/Label'


const OrdenesDetails = ({ orden, cercanas }) => {
    let fields = ['ordennro', 'clientenro', 'clientenombre', 'motivo', 'ordenfing', 'callegeonombre', 'ordenobs', 'ordentpo', 'ordensts']

    return (
        <FloatingWindow >
            {fields.map(field =>
                <div key={field} className="mb-3">
                    <Label value={field} />
                    <div className=" capitalize ">{orden[field]}</div>
                </div>
            )}
        </FloatingWindow>
    )
}

export default OrdenesDetails