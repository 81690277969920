import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../contexts/ConfigContext';
import EntityBadge from '../Badges/EntityBadge';
import IdBadge from '../Badges/IdBadge';
import { Link } from 'react-router-dom';
import Padding from '../Layout/Padding';
import SigaButton from '../Global/SigaButton'

const ClienteSearch = () => {
    const context = useContext(ConfigContext);
    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])
    const handleQuery = e => setQuery(e.target.value)

    useEffect(() => {
        if (query.length > 3) {
            searchClientes()
        } else { setResults([]) }
    }, [query])

    const searchClientes = async () => {
        const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/clientes/search`,
            {
                method: 'POST',
                body: JSON.stringify({ query: query }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': process.env.REACT_APP_BRIDGE_API_KEY
                }
            })
        const responseJSON = await response.json()
        setResults(responseJSON)
        console.log(responseJSON)
    }

    return <Padding>
        <h1>Búsqueda de clientes</h1>
        <div className='flex flex-col'>
            <label>Ingrese nombre, documento, direccion o numero de abonado para buscar rapidamente</label>
            <input className='my-2 text-xl' onChange={handleQuery} placeholder='Buscar...' />
        </div>
        <table className='w-full text-sm'>
            <tbody>
                {results && results.map(result => {
                    return <tr>
                        <td className='p-1'><IdBadge id={result.nro} /></td>
                        <td className='p-1'><SigaButton tipo='cliente' nro={result.nro} /></td>
                        <td className='p-1 capitalize'><EntityBadge type='cliente' name={result.nom.toLowerCase()} detail={`${result.doc}`} /></td>
                        <td className='p-1 opacity-50'>{result.sts}</td>
                        <td className='text-right'>
                            <Link to={`/clientes/show/${context.empresa}/${result.nro}`}>
                                <button><i className={`fa fa-fw fa-angle-right text-teal-500`} /></button>
                            </Link>
                        </td>
                    </tr>


                })}
            </tbody>
        </table>
    </Padding>
}


export default ClienteSearch