import React from 'react'
import paletas from '../../consts/paletas'

export default function StatusBadge({sts}) {
    return (
        <span className={`text-xxs inline-flex items-center mr-1 badge rounded-full bg-${paletas.estados[sts].color}-400`}>
            <i className={`fa fa-fw fa-${paletas.estados[sts].icon} mr-1`} />
            {paletas.estados[sts].title}
        </span>
    )
}
