import React from 'react';
import moment from 'moment'

const LogListItem = ({ log, TYPES }) => {
    return <tr>
        <td><i className={`fa fa-list-alt fa-fw mx-1 opacity-50`} /></td>
        <td>{log}</td>
        <td className='flex flex-col w-32  text-right ' >
            <span><i className='fa fa-clock opacity-25 fa-fw mr-1 '/> {moment(log.slice(0,21).replace('[','').replace(']','')).format('HH:mm')}</span>
            <small className='opacity-50'>{moment(log.slice(0,21).replace('[','').replace(']','')).fromNow()}</small>
        </td>
    </tr>
}
export default LogListItem