import React, {useContext} from 'react';
import Cargando from '../Global/Cargando';
import useFetch from '../../hooks/useFetch';
import moment from 'moment'
import ConfigContext from '../../contexts/ConfigContext';

const DashboardTarifas = () => {
    const context = useContext(ConfigContext)
    const [fechas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/fechas`)

    
    return  <div className='dashboard-card'>
    <h4 className='pb-2  opacity-50'><i className='fa fa-calendar fa-fw text-teal-500 mr-2 '/>Fechas</h4>
    {fechas ? 
        fechas.map((fecha,index) => {
            return <div key={index} className=' pb-3'>

                    <div key={fecha.id} className='flex items-center justify-between'>
                        <span className='capitalize text-teal-500'><i className='fa fa-fw fa-angle-right'/>{fecha.evento}</span>
                        <strong>{moment(fecha.fecha).format('D/M/Y')}</strong>
                    </div>
                    <div className='opacity-50 w-full text-right text-xs'>{moment(fecha.fecha).fromNow()}</div>
                </div>
        }) : <Cargando />
    }
</div>
}
export default DashboardTarifas