
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import ConfigContext from '../../contexts/ConfigContext'
import nivelRoles from '../../consts/nivelRoles'

export default function Index(params) {
    const context = useContext(ConfigContext)
    const history = useHistory()

    const [informes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/informes`)
    const [selectedInforme, setSelectedInforme] = useState()

    const handleClickVolver = () => {
        history.push('/informes')
    }

    React.useEffect(() => {
        context.setEmpresa(params.match.params.empresa)
        const info = informes && informes.find((info) => {
            return info.empresa === params.match.params.empresa && Number(info.id) === Number(params.match.params.nro) && nivelRoles[info.level].some(r => context.roles.indexOf(r) >= 0)
        })
        info ? setSelectedInforme(info.uri) : setSelectedInforme(false)
    },[params,informes])

    return <div className='min-h-full relative' height='100%' style={{ height:'100vh'}}>
                <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
                {selectedInforme ? 
                    (selectedInforme.substring(0,5) === 'https' || selectedInforme.substring(0,4) === 'http') ? 
                        <iframe width="100%" height="100%" src={selectedInforme} frameBorder="0" allowFullScreen={true}></iframe>
                    :
                        selectedInforme && <h1 className='text-center font-bold text-3xl pt-64'>Error en la url, ingresela con <strong className='text-green-500'>http://</strong> o <strong className='text-green-500'>https://</strong></h1>
                : <h1 className='text-center font-bold text-xl pt-64'>El informe no existe o usted no tiene acceso al mismo </h1>
                }
        </div>
};
