import React, { useContext } from 'react'
import useFetch from '../../hooks/useFetch'
import Cargando from './../Global/Cargando'
import { Link, useHistory } from "react-router-dom";
import EntityBadge from './../../components/Badges/EntityBadge'
import IdBadge from '../Badges/IdBadge'
import Padding from './../Layout/Padding'
import paletas from '../../consts/paletas'
import SigaButton from './../Global/SigaButton'
import ConfigContext from '../../contexts/ConfigContext';


const AgendaRedesOrdenes = (props) => {
    const {agendanro} = props.location.state
    const history = useHistory()
    const context = useContext(ConfigContext)

    const [ordenes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendas/nro/${agendanro}/ordenes`)


    return(
        <Padding>
            <button onClick={() => history.goBack(-1)} >Volver</button>
            <h1>Ordenes de Agenda {agendanro}</h1>
            {ordenes ?
                <table className='text-sm w-full' >
                    <tbody>
                        {ordenes
                            .map((orden, index) =>
                                <tr key={orden + '_' + index}>
                                    <td>
                                        <Link to={`/show/ordenes/${orden.ordennro}`}><IdBadge id={orden.ordennro} /></Link>
                                    </td>
                                    <td><SigaButton tipo='orden' nro={orden.ordennro} /></td>
                                    <td>
                                        <span className={` text-${paletas.tipos[orden.ordentpo].color}-400 flex flex-col items-center justify-center`}>
                                            <i className={` fa fa-fw fa-${paletas.tipos[orden.ordentpo].icon} mb-1`} />
                                            <span className='text-xxs'>{paletas.tipos[orden.ordentpo].title} </span>
                                        </span>
                                    </td>
                                    <td>
                                        <Link to={`/clientes/show/${orden.clientenro}`}>
                                            <EntityBadge type='cliente' name={orden.clientenombre} detail={orden.clientenro} />
                                        </Link>
                                    </td>
                                    <td className='w-1/3 opacity-75 text-xs'>{orden.ordenobs}</td>
                                    <td className='py-1  items-center'>
                                        <span className={`text-xxs inline-flex items-center mr-1 badge rounded-full bg-${paletas.estados[orden.ordensts].color}-400`}>
                                            <i className={`fa fa-fw fa-${paletas.estados[orden.ordensts].icon} mr-1`} />
                                            {paletas.estados[orden.ordensts].title}
                                        </span>
                                    </td>
                                    <td className='text-right'>
                                        <Link to={`/show/ordenes/${orden.ordennro}`}>
                                            <button><i className={`fa fa-fw fa-angle-right`} /></button>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            : <Cargando />}
        </Padding>
    )
};

export default AgendaRedesOrdenes
