import React, { useContext, useState } from 'react';

import Icons from '../Global/IconList'
import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import {useHistory} from 'react-router-dom'

const RutasConfiguracion = () => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/routes`)
    const [routeRoles, refreshRouteRoles] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/routes/roles`)
    
    const [ deleteItem ] = useDelete('routes', '/configuracion/rutas')
    const [ deleteRouteRol ] = useDelete('routes/roles', '/configuracion/rutas')
    const [openForm, setOpenForm] = useState(false)
    const [form, setForm] = useState()
    const [roleRutas, setRoleRutas] = useState()

    const handleNueva = () => {
        setOpenForm(!openForm)
        if (openForm){
            setForm({ ...form, role: 'consultas' ,mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0], _method:'post'})
        }else{
            setForm({})
        }
    }
    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

    const handleStore = async () => {
        const url = form && form.id 
            ? `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/routes/${form.id}`
            : `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/routes`

        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
        refreshRouteRoles()
    }

    const handleClickEditar = (item) => {
        setOpenForm(true) 
        setForm({...form, _method:'put', id:item.id, label:item.label, path:item.path, component:item.component, icon:item.icon, grupo:item.grupo, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
        data && setRoleRutas(data.find(ruta => Number(ruta.id) === Number(item.id)).role.split(','))
    }

    const handleClickVolver = () => {
        history.goBack()
    }

    const roles = ['consultas','operador','tecnico','dev','gerencia','directorio','guest']

    const filterRoles = () => {
        return roles.filter(rol => roleRutas ? !roleRutas.includes(rol) : rol)
    }

    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Rutas</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full text-xs">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Label</th>
                            <th>Path</th>
                            {/* <th>Component</th> */}
                            <th>Roles</th>
                            <th>Grupo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {openForm &&
                            <tr>
                                <td><input onChange={handleChange} name='label' value={form.label} placeholder='Label' /></td>
                                <td> <input onChange={handleChange} name='path' value={form.path} placeholder='Path' /> </td>
                                <td> <input onChange={handleChange} name='component' value={form.component} placeholder='Component' /> </td>
                                <td>
                                    <select onChange={handleChange} onFocus={handleChange} name='role'>
                                        {filterRoles()
                                            .map((rol,index) => (<option key={index} value={rol}>{rol}</option>))
                                        }
                                    </select>
                                </td>
                                <td> <input onChange={handleChange} type='number' name='grupo' value={form.grupo} /> </td>
                                <td className='flex items-center'>
                                    <select onChange={handleChange} name='icon' value={form.icon}>
                                        {Icons.map((icon,index) => (<option key={index} value={icon}>{icon}</option>))}
                                    </select>
                                    <i className={`fa fa-${form.icon} fa-fw ml-2`} />
                                </td>
                                
                                <td> <button onClick={handleStore}><i className={`fa fa-${form && form.id ? 'save' : 'paper-plane'} mr-2 `} /> {form && form.id ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        
                        {data
                            // // .sort((a, b) => a.grupo - b.grupo)
                            // .reverse()
                            .map(item => <tr key={item.id}>
                                <td style={{ width: '24px'}}>{item.icon && <i className={`fa fa-${item.icon} fa-fw mx-2`} />}</td>
                                <td><span className='badge' style={{ color :'black'}}>{item.grupo}</span> <strong>{item.label}</strong></td>
                                <td className='font-mono text-xxs'>{item.path}</td>
                                {/* <td>{item.component}</td> */}
                                <td>
                                    {routeRoles && routeRoles
                                        .filter(rol => Number(rol.route_id) === Number(item.id))
                                        .map((rol,index) => (
                                            <span key={index}> 
                                                {rol.role}
                                                <span key={index} className='text-red-500 hover:opacity-50 cursor-pointer mr-3 ml-1' onClick={() => deleteRouteRol(refreshRouteRoles, rol.role ,rol.id)}>
                                                    <i className='fa fa-backspace' />
                                                </span> 
                                            </span>))
                                    }
                                </td>
                                <td className='text-right'>
                                    <button onClick={() => handleClickEditar(item)} > <i className='fa fa-edit fa-fw'/></button>
                                    <button style={{ marginLeft : '5px'}} onClick={() => deleteItem(refreshData, item.path ,item.id)}> <i className='fa fa-trash fa-fw'/></button>
                                </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default RutasConfiguracion
