import React  from 'react';
const FloatingWindow = ({ children, icon, title }) => {
    return <section className={`floating-window p-2 flex flex-col overflow-hidden rounded-lg shadow  z-10  `} >
        {(icon || title) && <div className='flex items-center mb-3 text-xs'>
            {icon && <i className={`fa fa-${icon} fa-fw mr-2  text-teal-500`}></i>}
            {title && <h6 className=" text-right opacity-75  uppercase font-normal">{title}</h6>}
        </div>}
        {children}
    </section>
}

export default FloatingWindow