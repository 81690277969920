import DashboardClimas from './DashboardClimas';
import DashboardFechas from './DashboardFechas';
import DashboardLogs from './DashboardLogs';
import DashboardMensajes from './DashboardMensajes';
import DashboardTarifas from './DashboardTarifas';
import Dashboardmonitoreo from './DashboardMonitoreo';
import React from 'react';

const DashboardPanels = () => {
   
    return <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-10'>
                <div>
                    <Dashboardmonitoreo />
                    <DashboardMensajes />
                </div>
                <DashboardTarifas />
                <div>
                    <DashboardFechas />
                    <DashboardClimas />
                    <DashboardLogs />
                </div>
        </section>
}
export default DashboardPanels