import React from 'react';
import Label from '../Typo/Label'
import FloatingWindow from './../Global/FloatingWindow'
import Btn from "../Forms/Btn"
import { useHistory } from 'react-router-dom'

const ClienteDetails = ({ cliente }) => {
    const history = useHistory() 
    console.log(cliente);
    return <FloatingWindow title={cliente.nombre} icon='user' >
                <section className="flex flex-col gap-1">
                    {Object.keys(cliente).map((tipo, i) =>
                        <div key={i} className="flex flex-row items-center justify-between">
                            <Label value={tipo === 'titular' ? 'Titular Debito Automatico' : tipo } />
                            <div className=" capitalize text-right text-sm">{cliente[tipo] && cliente[tipo].toLowerCase()}</div>
                        </div>
                    )}
                    <Btn label="Ver mas" block={true} _handleBtnClick={()=>history.push(`/clientes/show/${cliente.clientenro}`)} />
                </section>
            </FloatingWindow>
};


export default ClienteDetails