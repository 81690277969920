import React from 'react'

const CircleMarker = ({color,label,size, dir=''}) => 
            <div 
                style={{ position:'absolute', width: `${size?size:'20'}px`,height: `${size?size:'20'}px`, top:`-${size?size/2:'10'}px`, left:`-${size?size/2:'10'}px`}} 
                className={`border border-${color}-700 transition-all duration-150 
                bg-${color}-500  
                ${dir == '' && 'rounded-full' } 
                ${dir == 'in' && 'rounded-full' } 
                ${dir == 'out' && 'rounded-full' } 
                text-sm text-${color}-100 flex shadow-md font-bold items-center justify-center`}
            >
               <span className="absolute text-left " style={{ width: '50px', height:'30px', right:'-55px' , top:'3px'}}>{label} </span>
               { dir==='in' && <span className="absolute rounded-full z-0 border-2 border-green-400 bg-green-600 opacity-25" style={{ width: '100px', height:'100px', right:'-41px' , top:'-40px'}}>{label} </span>}
               { dir==='in' && <span className="absolute rounded-full z-0 border-2 border-green-500 bg-green-900 opacity-25" style={{ width: '200px', height:'200px', right:'-91px' , top:'-91'}}>{label} </span>}
            </div>

export default CircleMarker