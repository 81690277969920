import React, { useEffect, useState } from 'react';

const NodoMarker = ({ nombre, nodo, $hover, active=false }) => {
    const [status, setStatus] = useState(null)

    const tipos = {
        0: 'green-500',
        1: 'yellow-500',
        2: 'red-500',
        3: 'gray-500',
        4: 'black',
        5: 'pink-500',
        6: '',
        7: ''
    }

    const estados = {
        0: 'OK',
        1: 'ALERT',
        2: 'CRITICAL',
        3: 'UNKOWN',
        4: 'DOWN',
        5: 'MAINTENANCE',
        6: '',
        7: ''
    }

    // const [isWarning, setIsWarning] = useState(false)
    useEffect(() => setStatus(nodo.status), [])

    return (
        <div className={`nodo`}>
            <div className={` ${active ? 'w-4 h-4 ' : `w-2 h-2` }  bg-${tipos[status]}  nodo    rounded-full`}></div>
            <div className={`tooltip ${$hover ? '' : ' opacity-0 '} rounded absolute p-px px-1  truncate text-xxs  z-60 `} 
            style={{ top: `${$hover ? '-30px' : '-20px'}`, transition: '.2s' }} >
                <span>{nombre}  </span> <br />
                status : {estados[status]}
                {/* {datos.map(dato => <div key={dato}>{dato}: {nodo[dato].status}</div>)} */}
            </div>
        </div>
    )

}
export default NodoMarker