import {useEffect} from 'react'
import dayjs from 'dayjs';
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

const useLoginExpired = () => {
    dayjs.extend(isSameOrAfter)

    const endSession =  () => {
        
         localStorage.removeItem('user')
         localStorage.removeItem('logged')
         localStorage.removeItem('fend')


        window.alert('Expiro tiempo de sesion')
        window.location='/'
    }

    const checkLoginStatus = () => {
        setInterval(() => {

            (localStorage.getItem('fend') && dayjs().isSameOrAfter(dayjs(localStorage.getItem('fend')))) && endSession() 
            !localStorage.getItem('fend') && endSession()
        }, 5*60*100)
    }

    useEffect(() => {checkLoginStatus()} ,[])

    return true
}

export default useLoginExpired