export default {
    options: [
        { to: '/map', title: 'Mapa', icon: 'map', roles: ['guest','consultas'], group: 1 },
        { to: '/maps', title: 'Mapas', icon: 'map', roles: ['gerencia','informes','operador','dev'], group: 1 },
        { to: '/pbx', title: 'Central Telefónica', icon: 'headphones', roles: ['gerencia','informes','operador','dev'], group: 1  },
        { to: '/monitoreo', title: 'Monitoreo de Nodos', icon: 'bullseye', roles: ['consultas','gerencia','informes','operador','dev'], group: 1 },
        // { to: '/agendamiento', title: 'Agendamiento', icon: 'clipboard', roles: ['dev'], group: 1 },
        
        
        { to: '/ordenes/list', title: 'Listado de Ordenes', icon: 'list', roles: ['consultas','gerencia','informes','operador','dev'], group: 2 },
        { to: '/clientes/search', title: 'Búsqueda de Clientes', icon: 'search', roles: ['consultas', 'operador','gerencia', 'dev','guest'], group: 2 },
        { to: '/agendas/list', title: 'Agenda de Operaciones', icon: 'list-alt', roles: ['consultas', 'operador','gerencia', 'dev'], group: 2 },
        { to: '/agendas/redes', title: 'Agenda de Redes', icon: 'list-alt', roles: ['consultas', 'operador','gerencia', 'dev'], group: 2, new:false },
        { to: '/next', title: 'Siguiente Agenda', icon: 'arrow-right', roles: ['operador', 'dev'], group: 2 },
        
        
        { to: '/moviles', title: 'Moviles / Centro Stock', icon: 'truck-moving', roles: ['operador','informes', 'gerencia','dev'], group: 3 },
        { to: '/tecnicos', title: 'Tecnicos', icon: 'id-badge', roles: ['operador','informes', 'gerencia','dev'], group: 3 },
        
        { to: '/ranking', title: 'Ranking', icon: 'chart-line', roles: ['operador','informes', 'gerencia','dev'], group: 3 },
        
        { to: '/enlaces', title: 'Enlaces', icon: 'link', roles: ['consultas','operador','tecnico','gerencia','informes','dev','guest'], group: 3 },

        { to: '/informes', title: 'Informes', icon: 'copy', roles: ['informes', 'gerencia', 'dev'], group: 4 },
        { to: '/logs', title: 'Registros', icon: 'book', roles: ['informes','dev'], group: 4 },
        { to: '/sesiones', title: 'Sesiones', icon: 'users', roles: ['informes','dev'], group: 4 },

        { to: '/configuracion', title: 'Configuracion', icon: 'cogs', roles: ['operador','dev'], group: 4 },

    ]
}

