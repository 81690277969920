
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import FloatingWindow from '../Global/FloatingWindow';
import Padding from '../Layout/Padding';
import FormSelect from '../Forms/FormSelect';
import ConfigContext from '../../contexts/ConfigContext'

{/* <iframe width="933" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiOTJkMTI4MGQtNjZmMi00YTBkLWI5NDItYjE4MWEyMWJkNWViIiwidCI6Ijk4NmRkMzAxLWQxNzUtNDI0OC1iZjc0LTg5ZTJkOTExY2Y2NyIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe> */}

export default function Index(params) {
    const context = useContext(ConfigContext)
    const history = useHistory()

    const [informes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/informes`)
    const [selectedInforme, setSelectedInforme] = useState()

    const handleOnChange = (e) => {
        const info = informes.find((info) => {return Number(info.id) === Number(e.target.value)})
        setSelectedInforme(info.uri)
        history.push(`/informes/${context.empresa}/${info.id}`)
    }

    return <div className='min-h-full relative' height='100%' style={{ height:'100vh'}}>
                <FloatingWindow pos='left-0' transparent><Padding>
                <details>
                    <summary className='cursor-pointer'><i className='fa fa-angle-down mr-1 '></i> Informes</summary>

                    {informes &&
                        <FormSelect roles={context.roles} item_key='id' item_value='uri' item_label='title' items={informes} includeBlank _onChange={handleOnChange}/>
                    }
                </details>
                </Padding></FloatingWindow>
                {selectedInforme && (selectedInforme.substring(0,5) === 'https' || selectedInforme.substring(0,4) === 'http') ? 
                    <iframe width="100%" height="100%" src={selectedInforme} frameBorder="0" allowFullScreen={true}></iframe>
                :
                    selectedInforme && <h1 className='text-center font-bold text-3xl pt-64'>Error en la url, ingresela con <strong className='text-green-500'>http://</strong> o <strong className='text-green-500'>https://</strong></h1>
                }
        </div>
};
