import React, { useContext, useEffect, useState } from 'react';

import BasicMarker from './../components/Markers/BasicMarker'
import Btn from './../components/Forms/Btn'
import Cargando from './../components/Global/Cargando'
import ConfigContext from './../contexts/ConfigContext';
import Details from './../components/Global/Details'
import MainMap from './../components/MainMap'
import Padding from '../components/Layout/Padding';
import useFetch from './../hooks/useFetch';

const Search = ({ entity }) => {
    let context = useContext(ConfigContext)
    let [nro, setNro] = useState()

    const [data,fetchData,error] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/${entity}/nro/${nro}`)

    useEffect(()=>{
        if (data && data[0]) {
            if (data[0].cliente_lat) {
                data[0].lat = data[0].cliente_lat
                data[0].lng = data[0].cliente_lng
            }
        }
    },[data])
    return <div className='flex flex-col md:flex-row  '>
        <div className=' w-full md:w-1/3 text-sm'>
            <Padding>
                <h2> Busqueda de <strong>{entity}</strong> </h2>
                <div className='grid grid-cols-2 gap-3 my-3'>
                    <input icon='search' placeholder='Buscar...' onChange={(e) => setNro(e.target.value)} value={nro} />
                    <Btn icon='search' _handleBtnClick={fetchData} label='Buscar' />
                </div>

                {data ? data[0] && <Details inline data={data[0]} /> : <Cargando color='gray' full />}
                {error && error}
            </Padding>
        </div>
        <div className='ml-5 w-full md:w-2/3'>
            <MainMap zoom={data && 16}>
                {data && data[0] && <BasicMarker 
                label={nro} size='lg' lat={data[0].lat} lng={data[0].lng} color='red' />}
            </MainMap>
        </div>
    </div>

}

export default Search