import React, {useState, useContext} from 'react'
import Padding from '../Layout/Padding'
import {useHistory} from 'react-router-dom'
import Cargando from '../Global/Cargando'
import ActionButtons from '../Global/ActionButtons'
import dayjs from 'dayjs'
import ConfigContext from '../../contexts/ConfigContext'

//use hooks
import useFetch from '../../hooks/useFetch'

const TecnicoShow = (props) => {
    const context = useContext(ConfigContext)

    const { id } = props.match.params

    const [tecnico] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos/${id}`)
    const [tecMovil] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos/${id}/movil`)
    
    const history = useHistory()
    
    const handleClickGoMovil = (id,nombre) => {
        history.push(`/moviles/${id}`, {state: {nombre: nombre ,tecnico: tecnico[0].tecniconombre, tecnicoid: tecnico[0].tecnicoid}})
    }

    const [csvData,setCSVDATA] = useState([])
    
    React.useEffect(() => {
         tecnico && tecnico
            .map((item,index) => setCSVDATA(csvData => {
                return [...csvData,{'ID EMPRESA': item.empresaid, CELULAR: item.tecnicocel, 'TIPO DE DOCUMENTO': item.tecnicocitpo, DOCUMENTO: item.tecnicoci, EMAIL: item.tecnicoemail, 'NUMERO DE EMPLEADO': item.tecnicoempleadonro, 'ID TECNICO': item.tecnicoid, NOMBRE: item.tecniconombre, PADRE: item.tecnicopadre, RECEPTOR: item.tecnicoreceptor, SMSHAB: item.tecnicosmshab, STS: item.tecnicosts, TPO: item.tecnicotpo, USUARIO: item.tecnicousuario  }]
            }))
     },[tecnico])

    const [search, setSearch] = useState('')

    const H3TecnicoInfo = [
        {label: 'ID Empresa #', value: 'empresaid'},
        {label: 'Celular', value: 'tecnicocel'},
        {label: 'Tipo de documento', value: 'tecnicocitpo'},
        {label: 'Documento', value: 'tecnicoci'},
        {label: 'Correo Electronico', value: 'tecnicoemail'},
        {label: 'Numero de empleado', value: 'tecnicoempleadonro'},
        {label: 'ID Tecnico #', value: 'tecnicoid'},
        {label: 'Nombre', value: 'tecniconombre'},
        {label: 'Padre', value: 'tecnicopadre'},
        {label: 'Receptor', value: 'tecnicoreceptor'},
        {label: 'Smshab', value: 'tecnicosmshab'},
        {label: 'Sts', value: 'tecnicosts'},
        {label: 'Tpo', value: 'tecnicotpo'},
        {label: 'Usuario', value: 'tecnicousuario'},
    ]

    const H3TecMovilInfo = [
        {label: 'Id #', value: 'centrostkid'},
        {label: 'Nombre', value: 'centrostknombre'},
        {label: 'Tpo', value: 'centrostktpo'},
        {label: 'Empresa Id #', value: 'empresaid'},
    ]

    return (
        <Padding>
        <div className='flex items-center justify-between'>


                <button onClick={() => history.goBack()} ><i className='fa fa-arrow-left mr-2' />Volver</button>
                <h1>Técnico {id}</h1>
            { csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`tecnico-show-${dayjs().format('DD-MM-YYYY')}`}/>}
        </div>
            <div>
                <div>
                    <h2>Datos</h2>
                    {tecnico ? tecnico.map((item,index) => (
                        <div key={index}>
                            {H3TecnicoInfo.map((info,index) => <H3Tecnico key={index} label={info.label} value={item[info.value]} /> )}
                        </div>
                    ))
                    :
                    <Cargando/>
                    }
                    <h2>Movil asignado al dia</h2>
                    {tecMovil ? tecMovil.map((item,index) => (
                        <div key={index}>
                            {H3TecMovilInfo.map((info,index) => <H3Tecnico key={index} label={info.label} value={item[info.value]} /> )}
                            <div className='justify-between flex hover:bg-gray-400 hover:text-black '>
                                <h3 className='font-bold'>Ver Movil</h3>
                                <button onClick={() => handleClickGoMovil(item.centrostkid,item.centrostknombre)}>Movil <i className='fa fa-truck'/></button>
                            </div>
                        </div>
                    ))
                    :
                    <Cargando/>
                    }
                    {tecMovil && tecMovil.length == 0 && <span>No tiene movil asignado</span>}
                </div>
                <div>
                </div>
            </div>
        </Padding>
    )
}

const H3Tecnico = ({label,value}) => {
    return (
        <div className='justify-between flex hover:bg-gray-400 hover:text-black'>
            <h3 className='font-bold'>{label}</h3>
            <h3>{value}</h3>
        </div>
    )
}

export default TecnicoShow
