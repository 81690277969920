import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr'

import Cargando from '../Global/Cargando';
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding';
import RankingProgress from './RankingProgress'
import RankingPuntaje from './RankingPuntaje'
import moment from 'moment';
import paletas from '../../consts/paletas';
import ordengen from '../../consts/ordengen'
import ActionButtons from '../Global/ActionButtons'

const fetchWithToken = (url, token) => fetch(url, { headers: { 'Authorization': `${token}` } }).then(res => res.json())

const RankingDiario = () => {

    let context = useContext(ConfigContext)

    let [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'))
    let [ranking, setRanking] = useState(null)
    let [csvData,setCSVDATA] = useState([])

    const { data:tecnicos } = useSWR(() => [`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/app/ranking/tecnicos`,process.env.REACT_APP_BRIDGE_API_KEY], fetchWithToken)
    const { data:ordenes } = useSWR(() => [`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/app/ranking/ordenes/mes/${filterDate && filterDate}`,process.env.REACT_APP_BRIDGE_API_KEY], fetchWithToken)

    useEffect(() => {
        if (ordenes && tecnicos) {
            let tmp = tecnicos.map(tecnico => {
                tecnico.finalizadas = tecnico.tecnicoid && ordenes.filter(orden => (orden.tecnicoid == tecnico.tecnicoid)).length
                return tecnico
            })
            setRanking(tmp)
        }
    }, [ordenes, tecnicos])

    useEffect(() => {
        ranking && ranking.sort((r, r2) => r2.finalizadas - r.finalizadas).map(tecnico => {
            return ordenes && ordenes.filter(orden => orden.tecnicoid == tecnico.tecnicoid).map(orden => setCSVDATA(csvData => {
                return [...csvData,{'TECNICO ID': tecnico.tecnicoid, 'TECNICO NOMBRE': tecnico.tecniconombre, FINALIZADAS: tecnico.finalizadas, 'ORDEN ID': orden.tecnicoid, 'ORDEN TIPO': orden.orden_tipo, 'ORDEN MOTIVO': orden.orden_motivo, 'ORDEN MOTIVO NOMBRE': orden.orden_motivo_nombre, 'ORDEN GET': orden.orden_gen, 'ORDEN TIPO': orden.orden_tipo}]
            }))
        })

    },[ordenes,ranking])

    return <Padding>
        { csvData && csvData.length > 0  && <ActionButtons csvData={csvData} filename={`ranking-diario-${moment().format('DD-MM-YYYY')}`}/>}
        <div className='flex items-center justify-between '>
            <h1>Ranking Mensual</h1>
            <input value={filterDate} class='text-sm rounded p-2 '
                type='month' onChange={(e) => setFilterDate(e.target.value)} />
        </div>
        {ranking ?
            <table className="w-full   table-fixed">
                <thead>
                    <tr className='uppercase tracking-wider opacity-50 text-xs'>
                        <td className='py-3 text-xs w-10'>ID</td>
                        <td className='py-3 text-xs w-48'>Tecnico 1</td>
                        <td className='py-3 text-xs '>Ordenes Finalizadas</td>
                    </tr>
                </thead>
                <tbody>
                    {ranking.sort((r, r2) => r2.finalizadas - r.finalizadas).map(tecnico =>
                        <tr className={`${tecnico.finalizadas == 0 && 'opacity-50 text-xxs font-normal'}`}>
                            <td className='align-top '> <span className='w-20 inline-flex text-xxs font-mono font-semibold opacity-50'># {tecnico.tecnicoid}</span> </td>
                            <td className="align-top py-1 capitalize text-sm font-medium">
                                {tecnico.tecniconombre && tecnico.tecniconombre.toLowerCase()}
                                {/* <small className='text-xs'>{tecnico.tecnicoemail && tecnico.tecnicoemail.toLowerCase() }</small> */}
                            </td>
                            <td className='align-top py-2 w-2/3 overflow-hidden'>


                                <details>
                                    <summary className='w-full focus:outline-none cursor-pointer'>

                                        {tecnico.finalizadas > 0 && <RankingProgress ranking={ranking} progress={tecnico.finalizadas} />}
                                    </summary>
                                    <table className='w-full text-xs'>
                                        <thead>
                                            <tr className='text-left'>
                                                <th>tarea ID</th>
                                                <th>tarea TIPO ID</th>
                                                <th>agenda nro</th>
                                                <th>orden nro</th>
                                                <th>Tipo</th>
                                                <th>Descripción</th>
                                                <th>Generacion Orden</th>
                                                <th>Puntaje</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordenes && ordenes.filter(orden => orden.tecnicoid == tecnico.tecnicoid).map(orden =>
                                                <tr>
                                                    <td>{orden.tarea_id}</td>
                                                    <td>{orden.tarea_tipoid}</td>
                                                    <td>{orden.agendanro}</td>
                                                    <td>{orden.ordennro}</td>
                                                    <td className='w-1/6 font-bold'>
                                                        {paletas.tipos[orden.orden_tipo] && paletas.tipos[orden.orden_tipo].title}
                                                    </td>
                                                    {/* <td className='w-1/6'>{orden.orden_motivo}</td> */}
                                                    <td className='text-left capitalize  w-1/2'>{orden.producto_nombre && orden.producto_nombre.toLowerCase()}</td>
                                                    <td >{ordengen[orden.orden_gen]}</td>
                                                    <td className=''>
                                                        <span className=' font-bold '>{Number(orden.tarea_puntos_conjunto) === 0 ? Number(orden.tarea_puntos_solo) : Number(orden.tarea_puntos_conjunto) }</span>
                                                        <RankingPuntaje puntaje={Number(orden.tarea_puntos_conjunto) === 0 ? Number(orden.tarea_puntos_solo) : Number(orden.tarea_puntos_conjunto) } />
                                                        
                                                    </td>
                                                    <td>solo: {Number(orden.tarea_puntos_solo)}</td>
                                                    <td>conjunto: {Number(orden.tarea_puntos_conjunto)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </details>

                            </td>

                        </tr>
                    )}

                </tbody>
            </table>
            : <Cargando  />}
    </Padding>
};

export default RankingDiario

