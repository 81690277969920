import React from 'react'
import paletas from '../../consts/paletas'
import { Link } from 'react-router-dom'
import IdBadge from '../Badges/IdBadge'
import SigaButton from '../Global/SigaButton'

export default ({agenda, openOrden}) => {
    return <details className='w-full '>
    <summary className='agenda-details p-px rounded rounded-lg focus:outline-none cursor-pointer hover:shadow-outline  text-xs  flex-wrap flex  overflow-hidden  items-center'>
        <i className='fa fa-angle-down   mx-2' />
        <span>{agenda.map((orden, index) => paletas.tipos[orden.orden_tpo] &&  `${orden.orden_nro} ` )}</span>
    </summary>
    <table className='w-full text-left table-fixed'>
        <tbody>
            {agenda.map((orden, index) =>
                <tr key={'tr_' + orden.orden_nro + '_' + index}>
                    <td width='110px'>
                        <Link to={`/show/ordenes/${orden.orden_nro}`}>
                            <IdBadge id={orden.orden_nro} size='xs' />
                        </Link>
                    </td>
                    {paletas.tipos[orden.orden_tpo] && paletas.tipos[orden.orden_tpo].title === 'Reclamo' && 
                        <td><SigaButton tipo='orden' nro={orden.orden_nro} /></td>
                    }
                    <td width='110px'>{paletas.estados[orden.agenda_status] && paletas.estados[orden.agenda_status].title }</td>
                    <td width='110px'>{paletas.tipos[orden.orden_tpo] && paletas.tipos[orden.orden_tpo].title }</td>
                    <td width='30%' className=' text-xs '>{orden.orden_obs}</td>
                </tr>
            )}
        </tbody>
    </table>
</details>
}
