import React, { useContext, useState } from 'react';

import Cargando from './Cargando';
import ConfigContext from '../../contexts/ConfigContext'
import axios from 'axios';

const Stats = () => {
    let context = useContext(ConfigContext)
    const [stats, setStats] = useState(null)
    const datas = ['conLatLng', 'conNodo', 'conBarrio', 'conZona', 'conOperativa'] // , 'conDigital'
    const colors = ['blue', 'red', 'green', 'orange', 'pink', 'indigo', 'yellow']
    
    const fetchStats = () => {
        axios.get(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/stats/local`)
            .then(response => { setStats(response.data[0]) })
            .catch(e => console.log(e))
    }
    fetchStats()
    return (
        <div className="text-xs w-full">
            {stats ?
                <div className="w-full">
                    <h4 className={`   mt-5 mb-2 flex items-center justify-start text-lg`}>
                        <strong className={`p-1 rounded  mr-2 tracking-wider`}>{context.empresa.toUpperCase()}</strong> {stats.totalClientes} Direcciones
                        </h4>
                    <div className={`flex justify-around items-center   text-center`}>
                        {datas.map((data, index) =>
                            <StatProgress key={index} color={colors[index]} name={data} data={stats[data]} total={stats.totalClientes} conLatLng={stats.conLatLng} />
                        )}
                    </div>
                </div>
                : <Cargando />
            }
        </div>
    );
}

const StatProgress = (props) => {

    let total = (props.name === 'conLatLng') ? props.total : props.conLatLng
    return <div className="flex-1 mx-1 truncate text-xs">
        <div className={`p-2 my-2 font-medium rounded text-${props.color}-200 border border-${props.color}-300 relative`}>
            <div className={`top-0 left-0 bg-${props.color}-500 opacity-25 absolute w-1/2 h-full z-0 `} style={{ width: `${(props.data * 100) / total}%` }}></div>
            {props.data}
        </div>
        con {props.name.split('con')} <span className={`text-${props.color}-500 font-medium`}>{Math.floor((props.data * 100) / total)}%</span>
    </div>
}

export default Stats
