import React, { useState, useEffect } from 'react';

export default (value) => {
    const [env, setEnv] = useState()

    useEffect(()=> {
        setEnv(process.env[ 'REACT_APP_' + value ])
    },[value])

    return env

};
