import React, { useState, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import ConfigContext from "../../contexts/ConfigContext";
import { Link } from "react-router-dom";
import IdBadge from './../../components/Badges/IdBadge'
import EntityBadge from './../../components/Badges/EntityBadge'
import Ts from './../Global/Ts'
import Cargando from './../Global/Cargando'
import Padding from './../Layout/Padding'
import paletas from './../../consts/paletas'
import StatusBadge from "../Badges/StatusBadge";
import TurnoBadge from "../Badges/TurnoBadge";
import SigaButton from './../Global/SigaButton'


export default function AgendasRedes() {
  let [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"));
  let context = useContext(ConfigContext);
  
  const [fetchedTecnicos] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/app/tecnicos`)
  const [agendas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/agendas/redes/${fecha}`);
  const handleChangeDate = (e) => setFecha(e.target.value);
  return (
    <Padding>
      <div className='flex items-center justify-between '>
            <h1>Agenda de Redes</h1>
            <input type="date" onChange={handleChangeDate} defaultValue={fecha}/>
        </div>
      { agendas ?
        agendas.length ===0 ? 'No hay resultados' :
        <table className='text-sm w-full   ' >
        <thead>
            <tr>
                <th>Agenda Nro</th>
                {/* <th>Fecha</th> */}
                <th></th>
                <th>Agenda Status</th>
                <th>Orden Status</th>
                <th className='text-left'>Tecnico</th>
                <th>Fecha ingreso</th>
                <th className='text-left'>Usuario ingreso</th>
                <th>Turno</th>
                <th>Ordenes</th>
            </tr>
        </thead>
            <tbody>
                {agendas.map((agenda, index) => {
                    const tec = fetchedTecnicos && fetchedTecnicos.find( tecnico => tecnico.id == agenda.tecnicoid)

                    return (
                        <tr key={index}>
                            <td><Link to={`/show/agendas/${agenda.agendanro}`}><EntityBadge name={agenda.agendanro} type='agenda'/></Link></td>
                            {/* <td><Ts ts={agenda.agendafecha}/></td> */}
                            {/* <td><SigaButton tipo='agenda' nro={agenda.agendanro} /></td> */}
                            <td className='text-center'><StatusBadge sts={agenda.agendaregstatus} /> </td>
                            <td className='text-center'><StatusBadge sts={agenda.ordensts} /> </td>
                            <td><EntityBadge type='tecnico' name={ tec? tec.nombre :'Tecnico' } size='xs' /></td>
                            <td><Ts ts={agenda.agendaregfching}/></td>
                            <td><EntityBadge type='agente' name={ agenda.agendaregusring } size='xs' /></td>
                            <td><TurnoBadge turno={agenda.turnoid}/></td>
                            <td className='text-center'><Link to={{pathname: `/agendas/redes/ordenes`,state: {agendanro: agenda.agendanro}}}>
                                <button>Ver Ordenes <i className='fa fa-clipboard ml-3'/></button>
                            </Link></td>
                        </tr>
                    )
                })}
            </tbody>
        </table> 
       : <Cargando />}
    </Padding>
  );
}
