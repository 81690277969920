import React from 'react';
import paletas from './../../consts/paletas'
const OrdenesMarker = ({ orden, tipo='R', status='C', $hover, activa, size }) => {

    return <div
        className={`marker-orden ${activa && ' shadow-outline'} ${ $hover && 'shadow-outline' } shadow-sm  cursor-pointer overflow-hidden relative block font-bold  rounded flex justify-center items-center`}
        style={{ width: `24px`, marginLeft: '-12px', marginTop: '-6px' }}
        title={orden.clientenro || orden.cliente_nombre}>
        <div className={`px-1 py-px w-1/2  bg-${paletas.estados[status].color}-500  `}>{status}</div>
        <div className={`px-1 py-px w-1/2  bg-${paletas.tipos[tipo].color}-400  `}>{tipo}</div>
        <div className={`tooltip  ${$hover ? ' opacity-100' : ' opacity-0 '} rounded absolute p-px px-1 shadow truncate  font-bold z-50`} style={{ right: `${$hover ? '-60px' : '-40px'}`, transition: '.2s' }} >
            <span className={`opacity-75 text-${size ? size : 'xxs'}`}>{orden.ordennro || orden.orden_nro}</span>
        </div>
    </div>
};
export default OrdenesMarker