import React from 'react';
const LogoSvg = ({width=200, height=143, color='#53D0C9', strokeWidth='46'}) => {
    return <svg width={width} height={height} viewBox="0 0 517 204" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
        <circle className='animated fadeIn ' style={{ opacity: .1 }} cx="415.543" cy="101.543" r="78.5" transform="rotate(-45 415.543 101.543)" stroke="#F3F3F3" strokeWidth={strokeWidth} />
        <circle className='animated fadeIn delay-1s' style={{ mixBlendMode: 'multiply' }} cx="415.543" cy="102" r="78.5" transform="rotate(-45 415.543 102)" stroke={color} strokeWidth={strokeWidth} />
        <circle className='animated fadeIn delay-2s' style={{ opacity: .1 }} cx="101.5" cy="101.5" r="78.5" stroke="#F3F3F3" strokeWidth={strokeWidth} />
        <path className='animated fadeIn delay-3s' style={{ mixBlendMode: 'multiply' }} d="M101 180C57.9218 180 23 144.854 23 101.5C23 58.1456 57.9218 22.9999 101 22.9999" stroke={color} strokeWidth={strokeWidth} />
        <circle className='animated fadeIn delay-1s' style={{ opacity: .1 }} cx="258.236" cy="102.085" r="78.5" transform="rotate(-90 258.236 102.085)" stroke="#F3F3F3" strokeWidth={strokeWidth} />
        <path className='animated fadeIn delay-1s' style={{ mixBlendMode: 'multiply' }} d="M336.736 103C336.736 146.078 301.59 181 258.236 181C214.882 181 179.736 146.078 179.736 103" stroke={color} strokeWidth={strokeWidth} />
    </g>
</svg>
}
export default LogoSvg